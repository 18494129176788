import React from 'react';
import { Grid, Tooltip, Button } from "@mui/material";
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import LocationSearchingIcon from '@mui/icons-material/LocationSearching';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import EditIcon from '@mui/icons-material/Edit';
import { Geometry } from 'geojson';
import { FeatureFormState } from './FeatureInfoGrid';

type FeatureControlsProps = {
    formState: string;
    selectedFeature: any;
    isLoading: boolean;
    geometry: Geometry;
    onDelete: () => void;
    onAdd: () => void;
    onMove: () => void;
    onEditCancel: () => void;
    onEditSave: () => void;
    onAddCancel: () => void;
    onAddingSave: () => void;
    focusFeatureByGeometry: (geometry: Geometry, zoom?: number) => void;
}

const FeatureControls = ({
    formState,
    selectedFeature,
    isLoading,
    geometry,
    onDelete,
    onAdd,
    onMove,
    onEditCancel,
    onEditSave,
    onAddCancel,
    onAddingSave,
    focusFeatureByGeometry
}: FeatureControlsProps) => {

    const getButtonConfig = (currentFormState: string) => {
        const configs = {
            [FeatureFormState.VIEWING]: [
                { title: "Delete", onClick: onDelete, icon: <CancelIcon />, disabled: !selectedFeature },
                { title: "Add", onClick: onAdd, icon: <ControlPointIcon />, disabled: false },
                { title: "Edit", onClick: onMove, icon: <EditIcon />, disabled: !selectedFeature },
                { title: "Focus", onClick: () => focusFeatureByGeometry(geometry, 18), icon: <LocationSearchingIcon />, disabled: !geometry }
            ],
            [FeatureFormState.EDITING]: [
                { title: "Cancel", onClick: onEditCancel, icon: <CancelIcon />, disabled: isLoading },
                { title: "Save", onClick: onEditSave, icon: <SaveIcon />, disabled: isLoading || !geometry },
                { title: "Focus", onClick: () => focusFeatureByGeometry(geometry, 18), icon: <LocationSearchingIcon />, disabled: false || !geometry },
            ],
            [FeatureFormState.ADDING]: [
                { title: "Cancel", onClick: onAddCancel, icon: <CancelIcon />, disabled: isLoading },
                { title: "Save", onClick: onAddingSave, icon: <SaveIcon />, disabled: isLoading || !geometry },
                { title: "Focus", onClick: () => focusFeatureByGeometry(geometry, 18), icon: <LocationSearchingIcon />, disabled: false || !geometry },
            ],
        };

        return configs[currentFormState] || [];
    };

    const buttons = getButtonConfig(formState);

    return (
        <Grid container direction="row" alignItems="normal">
            {buttons.map((action, index) => (
                <Grid item xs={12 / buttons.length} key={index}>
                    <Tooltip title={action.title}>
                        <Button onClick={action.onClick} disabled={action.disabled}>
                            {action.icon}
                        </Button>
                    </Tooltip>
                </Grid>
            ))}
        </Grid>
    );
};

export default FeatureControls;
