import { BlockBlobClient } from '@azure/storage-blob'
import { Button, CircularProgress, Grid } from '@mui/material'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import axios from 'axios'
import { MuiFileInput } from 'mui-file-input'
import React, { useState } from 'react'

const convertStringToArrayBuffer = (str: string) => {
    const textEncoder = new TextEncoder()
    return textEncoder.encode(str).buffer
}

function convertFileToArrayBuffer(file: File): Promise<ArrayBuffer | null> {
    return new Promise((resolve, reject) => {
        if (!file || !file.name) {
            reject(new Error('Invalid or missing file.'))
        }
        console.log('file', file)

        const reader = new FileReader()

        reader.onload = () => {
            const arrayBuffer: ArrayBuffer | null | string = reader.result

            if (arrayBuffer === null) {
                resolve(null)
                return
            }
            if (typeof arrayBuffer === 'string') {
                resolve(convertStringToArrayBuffer(arrayBuffer) as any)
                return
            }
            if (!arrayBuffer) {
                reject(new Error('Failed to read file into ArrayBuffer.'))
                return
            }

            resolve(arrayBuffer)
        }

        reader.onerror = () => {
            reject(new Error('Error reading file.'))
        }

        reader.readAsArrayBuffer(file)
    })
}

type TUploadFileGDBProps = {
    accessToken: string
    organizationId: string
    onUploadComplete: () => void
}
const UploadFileGDB: React.FC<TUploadFileGDBProps> = ({ accessToken, organizationId, onUploadComplete }) => {
    const [fileInputValue, setFileInputValue] = useState<any>(null)
    const [isUploading, setIsUploading] = useState(false)

    const fileInputOnChange = (value: unknown) => {
        console.log('handleChange', value)
        setFileInputValue(value)
    }

    const onSubmitFileUpload = async (e: any) => {
        e.preventDefault()
        try {
            setIsUploading(true)
            console.log('onSubmitFileUpload', fileInputValue)

            let resp = await axios.post<any>(
                `${process.env.REACT_APP_API_SERVER_URL}/v1/files/upload?organizationId=${encodeURIComponent(organizationId)}`,
                {
                    FileNames: [fileInputValue.name],
                },
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                },
            )
            console.log(JSON.stringify(resp.data))
            const { uploadPairs } = resp.data
            if (!Array.isArray(uploadPairs) || uploadPairs.length === 0) {
                throw new Error('UploadPairs is not an array or is empty')
            }

            // upload to storage
            for (let { fileName, uploadUrl } of uploadPairs) {
                console.log('about to uplaod ' + fileName + ' to ' + uploadUrl)
                const blockBlobClient = new BlockBlobClient(uploadUrl)
                console.log('blockBlobClient', blockBlobClient)
                const fileArrayBuffer = await convertFileToArrayBuffer(fileInputValue)
                if (!fileArrayBuffer) {
                    throw new Error('fileArrayBuffer is null')
                }
                console.log('fileArrayBuffer', fileArrayBuffer)
                await blockBlobClient.uploadData(fileArrayBuffer)
            }

            onUploadComplete()
            setFileInputValue(null)
        } catch (error) {
            console.error('onSubmitFileUpload error', error)
        } finally {
            setIsUploading(false)
        }
    }

    return (
        <div>
            <form onSubmit={onSubmitFileUpload}>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <MuiFileInput
                            value={fileInputValue}
                            onChange={fileInputOnChange}
                            style={{
                                width: '100%',
                            }}
                            label="Attach Compressed File GDB"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Button
                            component="button"
                            variant="contained"
                            disabled={!fileInputValue || isUploading}
                            startIcon={isUploading ? <CircularProgress /> : <CloudUploadIcon />}
                            size="large"
                            style={{
                                height: '100%',
                                width: '100%',
                            }}
                            type="submit"
                        >
                            Upload
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </div>
    )
}

export default UploadFileGDB
