import mapboxgl from "mapbox-gl";
import ReactDOMServer from 'react-dom/server';

export type ControlButtonProps = {
  title: string;
  icon: React.ReactElement;
  onClick: () => void;
};

const createControlButton = ({ title, icon, onClick }: ControlButtonProps): HTMLButtonElement => {
  const button = document.createElement("button");
  button.innerHTML = ReactDOMServer.renderToStaticMarkup(icon);
  button.title = title;
  button.onclick = onClick;
  return button;
};

class CustomControlButtons {
  draw: MapboxDraw;
  container: HTMLDivElement;
  map: mapboxgl.Map;
  buttons: HTMLButtonElement[];

  constructor(draw: MapboxDraw, map: mapboxgl.Map, buttonTypes: ControlButtonProps[]) {
    this.draw = draw;
    this.map = map;
    this.container = document.createElement("div");
    this.container.className = "mapboxgl-ctrl mapboxgl-ctrl-group";
    this.buttons = buttonTypes.map((buttonType) => createControlButton(buttonType));
  }

  onAdd(map: mapboxgl.Map) {
    for (const button of this.buttons) {
      this.container.appendChild(button);
    }

    return this.container;
  }

  onRemove() {
    this.container?.parentNode?.removeChild(this.container);
  }

}


export default CustomControlButtons;