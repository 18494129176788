// src/hooks/useIncludePointToRoadCenterLineFishbone.ts
import { useMutation } from 'react-query';
import { includePointToRoadCenterLineFishbone } from '../../api/editController';
import { useMapCursor } from "./useMapCursor";

const useIncludePointToRoadCenterLineFishbone = (
    map: mapboxgl.Map | undefined,
    generateAndSetProposalInfo: (featureProposal: any, featureId: string, geometry: any) => void,
    onFeatureProposal: (responseBody: any) => void = () => { console.debug("useIncludePointToRoadCenterLineFishbone.onFeatureProposal not utilized") }
) => {
    return useMutation('include-fishbone-feature', includePointToRoadCenterLineFishbone, {
        onSuccess: ({ data: responseBody }) => {
            const { featureProposal } = responseBody;
            generateAndSetProposalInfo(featureProposal, featureProposal.featureId, featureProposal.geometry);
            onFeatureProposal(responseBody);
        },
        onError: (error, vars, ctx) => {
            console.error('Including proposal failed', error);
        },
        onMutate: useMapCursor(map).cursorWait,
        onSettled: useMapCursor(map).cursorNone
    });
};

export default useIncludePointToRoadCenterLineFishbone;
