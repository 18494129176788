
export const useMapCursor = (map: mapboxgl.Map | undefined) => {
    const cursorWait = () => {
        if (map) {
            const mapCanvas = map.getCanvas();
            if (mapCanvas) {
                mapCanvas.style.cursor = 'wait';
            }
        }
    };

    const cursorNone = () => {
        if (map) {
            const mapCanvas = map?.getCanvas();
            if (mapCanvas) {
                mapCanvas.style.cursor = '';
                mapCanvas.style.pointerEvents = "auto";

            }
        }
    };

    const cursorDisabled = () => {
        if (map) {
            const mapCanvas = map?.getCanvas();
            if (mapCanvas) {
                // these two dont work together..? but not being allowed to click feels better
                mapCanvas.style.cursor = 'not-allowed';
                mapCanvas.style.pointerEvents = "none";
            }
        }
    };

    return { cursorWait, cursorNone, cursorDisabled };
};
