import ReactMap, { Layer, MapboxMap, MapRef, Source, ViewState } from 'react-map-gl'
import Shell from '../layout/Shell'
import {
    Alert,
    Card,
    IconButton,
    MenuItem,
    Select,
    Snackbar
} from '@mui/material'
import mapboxgl from 'mapbox-gl'
import { useParams } from 'react-router-dom'
import { useAppContext } from '../App'
import { ReactNode, useCallback, useEffect, useRef, useState } from 'react'
import { getOrganizations, TExtent, TOrganizationResult, TRoadConfig } from '../hooks/useOrganization'
import { useMutation, useQuery } from 'react-query'
import { DrawControl } from '../components/map/DrawControl'
import { fishboneFeature, TFishboneInfo, TFishboneProposalInfo, TFishboneProposeResponse, TGenerateClusterResponse, } from '../api/editController'
import useQueryParams from "../hooks/useQueryParams";
import DashboardIcon from '@mui/icons-material/Dashboard';
import LayersIcon from '@mui/icons-material/Layers';
import { getFeatureById } from '../api/featureController'
import bbox from '@turf/bbox'
import { getTemplates } from '../api/modelsController'
import SidePanelTabs, { DEFAULT_SIDEPANEL_WIDTH, TabItem } from '../components/map/SidePanelTabs'
import SearchIcon from '@mui/icons-material/Search';
import DeleteIcon from '@mui/icons-material/Delete';
import BrushIcon from '@mui/icons-material/Brush';
import PolylineIcon from '@mui/icons-material/Polyline';
import SettingsIcon from '@mui/icons-material/Settings';
import { SearchTab } from '../components/map/tabs/SearchFeatureTab'
import { SelectLayerTab, TLayerSelection } from '../components/map/tabs/SelectLayerTab'
import { Feature, LineString, GeoJsonProperties, Geometry, Point, FeatureCollection, Polygon } from 'geojson';
import distance from '@turf/distance'
import { booleanIntersects, booleanPointOnLine, pointToLineDistance } from '@turf/turf'
import { v4 as uuid } from 'uuid';
import FeatureManager from '../components/FeatureManager'
import { Layers } from "../components/map/Layers";
import { DEFAULT_MAP_STYLE } from '../common'
import { SettingsTab } from '../components/map/tabs/SettingsTab'
import MapboxDraw from '@mapbox/mapbox-gl-draw'
import CustomControlButtons, { ControlButtonProps } from '../components/map/CustomControl'
import { CheckCircleOutline } from '@mui/icons-material'
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';

import PaintMode from 'mapbox-gl-draw-paint-mode';

import { SnapPolygonMode, SnapLineMode } from "mapbox-gl-draw-snap-mode";

import { setRoadConfig } from '../api/organizationController'
import { useLayerUrls } from '../hooks/useLayerUrl'
import { useTemplateStringMap } from '../hooks/useTemplateStringMap'
export type TClickedFeature = {
    sourceLayer?: string
    type: string
    geometry?: any
    properties?: { [key: string]: string }
}

export type TSystemNameDictionary = {
    [key: string]: {
        layerName: string;
        fields: {
            [key: string]: string;
        };
    };
}

export type TProposalInfo = {
    featureId: string
    layerName: string
    geometry: Geometry
    properties: { [key: string]: any }[]
    changedProperties?: { [key: string]: any }
    propertyToFocus?: string
}

export type THoverAdditions = {
    prefix: string
}

const SEARCH_TAB_ID = 0;
const SELECT_TAB_ID = 1;
const FEATURE_MANAGER_TAB = 2;
const SETTINGS_TAB_ID = 3; // NOTE: This should always be the last tab

const possibleMapStyles = [
    {
        displayName: "Standard",
        mapStyle: DEFAULT_MAP_STYLE,
    },
    {
        displayName: "Satellite",
        mapStyle: "mapbox://styles/mapbox/satellite-v9"
    }
    // TODO: Enable this one once we're upgraded properly to mapbox-gl v3
    // {
    //     displayName: "Standard",
    //     mapStyle: "mapbox://styles/mapbox/standard"
    // }
]


const MapPage: React.FC = () => {
    const mapRef = useRef<MapRef>(null)
    const editorMapRef = useRef<MapRef>(null)

    const { organizationId: currentOrganizationId, fileUploadId } = useParams<{ organizationId: string, fileUploadId: string }>()
    const qp = useQueryParams();
    const layerName = qp.get("layerName")
    const featureId = qp.get("featureId")
    const { accessToken } = useAppContext()
    const [mapStyle, setMapStyle] = useState<string>(DEFAULT_MAP_STYLE)
    const [selectedFeature, setSelectedFeature] = useState<{ layerName: string, feature: Feature }>()
    const [firstSymbolLayerId, setFirstSymbolLayerId] = useState<string | undefined>()
    const [layerSelection, setLayerSelection] = useState<TLayerSelection[]>([])
    const [mapRefLoaded, setMapRefLoaded] = useState(false)
    const [proposalInfo, setProposalInfo] = useState<TProposalInfo | undefined>()
    const [viewState, setViewState] = useState<ViewState>({
        // The Woodlands in Gladstone, MO
        latitude: 39.205867,
        longitude: -94.544264,
        zoom: 3.5,
        bearing: 0,
        pitch: 0,
        padding: { bottom: 0, top: 0, right: 0, left: 0 }
    })
    const [viewStateSet, setViewStateSet] = useState(false)
    const [activeSidePanelTab, setActiveSidePanelTab] = useState(SEARCH_TAB_ID)
    const handleActiveSidePanelTabChange = useCallback((tabId: number) => {
        setActiveSidePanelTab(tabId)
    }, [])

    const [featureSetOnIntialLoad, setFeatureSetOnIntialLoad] = useState(false)
    const [fishboneFeatureCollection, setFishboneFeatureCollection] = useState<FeatureCollection<Geometry, GeoJsonProperties>>();
    const [fishboneFeatureCollectionHouseLine, setFishboneFeatureCollectionHouseLine] = useState<FeatureCollection<Geometry, GeoJsonProperties>>();
    const [proposeFishboneFeatureCollection, setProposedFishboneFeatureCollection] = useState<FeatureCollection<Geometry, GeoJsonProperties>>();

    const [inMemoryRoadConfig, setInMemoryRoadConfig] = useState<TRoadConfig | undefined>();
    const handleInMemoryRoadConfigChange = useCallback((rc: any) => {
        setInMemoryRoadConfig(rc);
    }, []);

    //used when editing a point and you click a road and we need to switch the data to use the new road
    const [RCLSwitchFeature, setRCLSwitchFeature] = useState<{ layerName: string, feature: Feature } | null>(null);

    const { mutate: setRoadConfigMutate, isLoading: isMutatingRoadConfig } = useMutation('set-road-config', setRoadConfig, {
        onSuccess: (resp) => {
            console.log('set-road-config onSuccess', resp)
            console.log('refetching org result to get latest config')
            refetchOrgResult()

            // reload the rcl source so the roads will repaint upon zoom change
            const sourceId = sourceDataRCLLayerName;
            if (!sourceId) {
                console.error('Unable to reload source without sourceId for RCL layer.');
                return;
            }
            const source = mapRef.current?.getSource(sourceId) as mapboxgl.VectorSourceImpl;
            if (!source) {
                console.error(`Source ${sourceId} not found under mapRef.current`);
                return;
            }
            source.reload()
        },
        onError: (error) => {
            console.error('set-road-config onError', error)
        }
    })

    const onSaveRoadConfig = useCallback((rc: any) => {
        if (!accessToken || !currentOrganizationId) {
            console.warn('Missing accessToken or currentOrganizationId, unable to save road config')
            return
        }

        setRoadConfigMutate({
            accessToken,
            organizationId: currentOrganizationId,
            fileUploadId: fileUploadId,
            roadConfig: rc
        })
    }, [accessToken, currentOrganizationId, fileUploadId, setRoadConfigMutate])

    const [loadedMapSources, setLoadedMapSources] = useState<string[]>([])
    const selectedFeaturesToClear = useRef<{ layerName: string, feature: Feature }[]>([])

    const [hoverAdditions, setHoverAdditions] = useState<THoverAdditions>()
    const [drawRefFeature, setDrawRefFeature] = useState<{ geometry: Geometry, featureId: string } | null>(null)

    useEffect(() => {
        selectedFeaturesToClear.current?.forEach(({ layerName, feature }) => {
            if (!loadedMapSources.includes(layerName)) return
            mapRef.current?.setFeatureState({
                source: layerName,
                sourceLayer: layerName,
                id: feature.id,
            }, {
                click: false,
            })
        })
        const selectedFeatures = selectedFeature ? [selectedFeature] : []
        if (!(mapRefLoaded && selectedFeature)) return
        selectedFeatures.forEach(({ layerName, feature }) => {
            if (!loadedMapSources.includes(layerName)) return
            mapRef.current?.setFeatureState({
                source: layerName,
                sourceLayer: layerName,
                id: feature.id,
            }, {
                click: true,
            })
        })
        console.log(`selectedFeatue:`, selectedFeatures)
        selectedFeaturesToClear.current = selectedFeatures
    }, [loadedMapSources, mapRefLoaded, selectedFeature])
    const onSourceData = (e: mapboxgl.MapSourceDataEvent) => {
        e.isSourceLoaded && setLoadedMapSources([...loadedMapSources, e.sourceId])
    }
    const geoJSONPoint: (point: mapboxgl.LngLat) => { type: 'Point', coordinates: number[] } = (point) => ({ type: 'Point', coordinates: [point.lng, point.lat] })
    const getDistance = (point: Point, geometry: Geometry) => {
        if (geometry.type === 'Point') {
            return distance(point, geometry)
        }
        if (geometry.type === 'MultiPoint') {
            return Math.min(...geometry.coordinates.map((coord) => distance(point, { type: 'Point', coordinates: coord })))
        }
        if (geometry.type === 'LineString') {
            return pointToLineDistance(point, geometry)
        }
        if (geometry.type === 'MultiLineString') {
            const nearestGeometryCoordinates = geometry.coordinates.reduce(
                (previousValue, currentValue) => {
                    const previousDistance = pointToLineDistance(point, { type: 'LineString', coordinates: previousValue })
                    const currentDistance = pointToLineDistance(point, { type: 'LineString', coordinates: currentValue })
                    return previousDistance < currentDistance ? previousValue : currentValue
                }
            )
            return pointToLineDistance(point, { type: 'LineString', coordinates: nearestGeometryCoordinates })
        }
        if (geometry.type === 'Polygon' || geometry.type === 'MultiPolygon') {
            return booleanIntersects(point, geometry) ? 0 : Infinity // user must click within polygon, still need to handle overlapping polygons, first one will be selected, eventually we just need ability to select more than one thing
        }
        return Infinity
    }
    const bufferPoint = (point: mapboxgl.Point, pixelBuffer = 16) => [[point.x - pixelBuffer, point.y - pixelBuffer], [point.x + pixelBuffer, point.y + pixelBuffer]] as [mapboxgl.PointLike, mapboxgl.PointLike]

    const makeFeatureMoveable = useCallback((featureIds: string[]) => {
        if (drawControlRef.current) {
            const features = mapRef?.current?.queryRenderedFeatures(undefined, {
                layers: layerSelection.filter(ls => ls.checked).map(ls => `${ls.layerInfo.name}-1`),
            });

            if (features == null || features.length < 1) {
                return;
            }
            const matchingFeatures = features?.filter(f => (f.id && featureIds.includes(f.id as string)))
            if (matchingFeatures == null || matchingFeatures.length < 1) {
                return
            }
            let matchedFeatureIds: string[] = []
            matchingFeatures.forEach(f => {
                drawControlRef.current?.add(f);
                if (f.id) {
                    matchedFeatureIds.push(f.id as string)
                }
            })

            drawControlRef.current?.changeMode('simple_select', {
                featureIds: matchedFeatureIds
            })
        }
    }, [layerSelection])

    const [clusterApFeatureIds, setClusterApFeatureIds] = useState<string[]>([])

    const onClick = useCallback((e: mapboxgl.MapLayerMouseEvent) => {
        let allFeatures = e.target.queryRenderedFeatures(bufferPoint(e.point))
        if (allFeatures && allFeatures.length > 0 && drawRefFeature && allFeatures.some(feature => feature.layer.id.includes('fishbone'))) return;

        const features = e.target.queryRenderedFeatures(bufferPoint(e.point), { layers: layerSelection.filter(ls => ls.checked).map(ls => `${ls.layerInfo.name}-1`) })
        if (features.length === 0) return

        const nearestFeature = features.reduce((previousValue, currentValue) => {
            const previousValueDistance = getDistance(geoJSONPoint(e.lngLat), previousValue.geometry)
            const currentValueDistance = getDistance(geoJSONPoint(e.lngLat), currentValue.geometry)
            if ((previousValue.geometry.type === 'Point' || previousValue.geometry.type === 'MultiPoint' || previousValue.geometry.type === 'LineString' || previousValue.geometry.type === 'MultiLineString') && (currentValue.geometry.type === 'Polygon' || currentValue.geometry.type === 'MultiPolygon')) {
                return previousValue
            }
            return previousValueDistance < currentValueDistance ? previousValue : currentValue
        })


        if (drawRefFeature?.geometry.type === 'Point' && nearestFeature.geometry.type === 'LineString') {
            setRCLSwitchFeature({ layerName: nearestFeature.source, feature: nearestFeature })
            return;
        } else if (drawRefFeature) {
            return
        }

        if (!clusterApFeatureIds.includes(nearestFeature.id as string)) {
            setClusterApFeatureIds([])
            setClusterCollection(undefined)
        }
        setSelectedFeature({ layerName: nearestFeature.source, feature: nearestFeature })
    }, [drawRefFeature, layerSelection, clusterApFeatureIds])
    const { data: organizationResult, refetch: refetchOrgResult } = useQuery<TOrganizationResult | Error>('get-organizations', () =>
        getOrganizations(accessToken!)
    )


    // gets the data models so we can map the systemLayerNames to the source layer names
    const [sourceDataAPLayerName, setSourceDataAPLayerName] = useState('');
    const [sourceDataRCLLayerName, setSourceDataRCLLayerName] = useState('');
    const [systemNameDictionary, setSystemNameDictionary] = useState<TSystemNameDictionary>();

    const { data: getTemplatesRespBody } = useQuery('get-templates', () => {
        if (!accessToken) {
            return
        }
        return getTemplates(accessToken)
    });

    // snackbar stuff
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const resetSnackbar = () => {
        setOpenSnackbar(false);
        setTimeout(() => {
            setSnackbarAlertSeverity('info')
            setSnackbarAlertContent('')
        }, 0)
    }

    const openSuccessSnackbar = (msg: string) => {
        setSnackbarAlertSeverity('success')
        setSnackbarAlertContent(msg)
        setOpenSnackbar(true)
    }

    const handleCloseSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        resetSnackbar()
    };

    type TAlertSeverity = 'success' | 'info' | 'warning' | 'error';
    const [snackbarAlertSeverity, setSnackbarAlertSeverity] = useState<TAlertSeverity>('info');
    const [snackbarAlertContent, setSnackbarAlertContent] = useState<ReactNode>('');

    // this is the contextual organization the user belongs to or undefined
    const { templateStringMap } = useTemplateStringMap(organizationResult, currentOrganizationId, fileUploadId)

    // 18ish is a good zoom for a feature, 10ish is a good zoom for a layer
    const focusFeatureByGeometry = useCallback((geometry: Geometry, zoom: number = 10) => {
        let bboxResult = bbox(geometry)
        let xMin = bboxResult[0]
        let xMax = bboxResult[2]
        let yMin = bboxResult[1]
        let yMax = bboxResult[3]
        focusFeatureByXYCoordinates(xMin, xMax, yMin, yMax, { zoom, duration: 2000 })
    }, [])

    const focusFeatureByXYCoordinates = (xMin: number, xMax: number, yMin: number, yMax: number, options: { ignoreOpts?: boolean } & mapboxgl.FitBoundsOptions = {
        ignoreOpts: false,
        zoom: 10,
        duration: 2000
    }) => {
        const bounds = new mapboxgl.LngLatBounds(
            new mapboxgl.LngLat(xMin, yMin),
            new mapboxgl.LngLat(xMax, yMax),
        )

        try {
            if (!options.ignoreOpts) {
                mapRef?.current?.fitBounds(bounds, options)
            } else {
                mapRef?.current?.fitBounds(bounds, options)
            }
        } catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        if ((performance.getEntriesByType("navigation")[0] as any).type === "reload" && !featureId) {
            let string = localStorage.getItem('browser-view-state');
            if (string) {
                let newViewState = JSON.parse(string);
                setViewState(newViewState)
                setViewStateSet(true)
            }
        }
    }, [featureId])

    useEffect(() => {
        const handleBeforeUnload = (event: BeforeUnloadEvent) => {
            localStorage.setItem('browser-view-state', JSON.stringify(viewState));
        };

        window.addEventListener("beforeunload", handleBeforeUnload);
        return () => window.removeEventListener("beforeunload", handleBeforeUnload);

    }, [viewState])


    const [intialLayersSet, setIntialLayersSet] = useState(false)
    useEffect(() => {
        if (typeof accessToken === 'undefined') {
            return
        }

        if (!organizationResult) {
            return
        }

        if (organizationResult instanceof Error || !organizationResult.belongs) {
            return
        }

        const currentOrganization = organizationResult.organizations.find((org) => org.id === currentOrganizationId)

        if (!currentOrganization) {
            return
        }

        if (mapRefLoaded && !intialLayersSet) {
            setIntialLayersSet(true)
            const fuId = currentOrganization.fileUploads.find((f: any) => f.uploadId === fileUploadId)?.uploadId

            const orgLayers = currentOrganization.fileUploads.find((f: any) => f.uploadId === fuId)?.layers ?? []

            let layerOrder = 1
            setLayerSelection(orgLayers.filter(ol => ol.type !== 'None').map(ol => {
                const isAddressPoint = ol.name === sourceDataAPLayerName
                const isRoadCenterLineLayer = ol.name === sourceDataRCLLayerName;

                return new TLayerSelection(
                    ol,
                    isAddressPoint || isRoadCenterLineLayer, //harcode for addresspoints to be selected
                    isAddressPoint ? { r: 250, g: 0, b: 0 } : { r: 0, g: 0, b: 255 }, //harcode for addresspoints to be selected,
                    isRoadCenterLineLayer ? 0 : isAddressPoint ? 1 : ++layerOrder // roads should be first, then address points, then the rest
                )
            }))
        }
    }, [accessToken, currentOrganizationId, fileUploadId, intialLayersSet, mapRefLoaded, organizationResult, sourceDataAPLayerName, sourceDataRCLLayerName])

    // map view state effect
    useEffect(() => {
        if (typeof accessToken === 'undefined') {
            return
        }

        if (!organizationResult) {
            return
        }

        if (organizationResult instanceof Error || !organizationResult.belongs) {
            return
        }

        const currentOrganization = organizationResult.organizations.find((org) => org.id === currentOrganizationId)

        async function fetchFeatureById(accessToken: string, currentOrganizationId: string, fileUploadId: string, featureId: string) {
            let response = await getFeatureById(accessToken, currentOrganizationId!, fileUploadId, featureId);

            const feature = { ...response.data?.feature, id: response.data?.id }
            setSelectedFeature({ layerName: response.data?.layerName, feature })
            focusFeatureByGeometry(response.data?.feature?.geometry, 18)
        }

        if (!currentOrganization) {
            return
        }

        if (mapRefLoaded && !viewStateSet) {
            setViewStateSet(true)
            const fuId = currentOrganization.fileUploads.find((f: any) => f.uploadId === fileUploadId)?.uploadId

            const orgLayers = currentOrganization.fileUploads.find((f: any) => f.uploadId === fuId)?.layers ?? []

            if (featureId && mapRef.current) {
                fetchFeatureById(accessToken, currentOrganizationId!, fuId!, featureId)
            } else {
                const layerWithMostFeatures = orgLayers.reduce((prev: any, curr: any) => prev?.expectedFeatureCount > curr?.expectedFeatureCount ? prev : curr)
                if (layerWithMostFeatures?.extent?.xMin && layerWithMostFeatures?.extent?.yMin && layerWithMostFeatures?.extent?.xMax && layerWithMostFeatures?.extent?.yMax && mapRef.current) {

                    const extent = layerWithMostFeatures?.extent;
                    if (!extent) {
                        console.warn('No extent found for layer with most features')
                        return;
                    }

                    const calculateFirstLoadExtent = (extent: TExtent) => {
                        // grab the layer extent calculated during upload
                        const xMin = extent.xMin ?? 0;
                        const xMax = extent.xMax ?? 0;
                        const yMin = extent.yMin ?? 0;
                        const yMax = extent.yMax ?? 0;

                        // calculate the leftside expansion of the extent based on the viewport width and the default side panel width
                        const viewPortWidthPixels = window.outerWidth;
                        const defaultSidePanelWidth = DEFAULT_SIDEPANEL_WIDTH;
                        // shift and pad the calculated default side panel width %
                        const shiftExtentPercentage = (defaultSidePanelWidth / viewPortWidthPixels) + 0.15;
                        console.log(`EXTENT shiftExtentPercentage: ${shiftExtentPercentage}`)

                        // expand the left side of the extent by the shiftExtentPercentage
                        const extentWidth = xMax - xMin;
                        console.log(`EXTENT WIDTH: ${extentWidth}`)
                        const shiftedXMin = xMin - (extentWidth * shiftExtentPercentage);

                        // pad the whole thing to make it nicely fit in the viewport
                        const paddingPercentage = 0.05;
                        const xPadding = (xMax - shiftedXMin) * paddingPercentage;
                        const yPadding = (yMax - yMin) * paddingPercentage;
                        const paddedXMin = shiftedXMin - xPadding;
                        const paddedXMax = xMax + xPadding;
                        const paddedYMin = yMin - yPadding;
                        const paddedYMax = yMax + yPadding;

                        console.log(`EXTENT BEFORE: xMin: ${xMin}, xMax: ${xMax}, yMin: ${yMin}, yMax: ${yMax}`)
                        console.log(`EXTENT AFTER: xMin: ${paddedXMin}, xMax: ${paddedXMax}, yMin: ${paddedYMin}, yMax: ${paddedYMax}`)
                        // return the padded and shifted extent
                        return {
                            xMin: paddedXMin,
                            xMax: paddedXMax,
                            yMin: paddedYMin,
                            yMax: paddedYMax
                        }
                    }

                    const paddedAndShiftedExtent = calculateFirstLoadExtent(extent);
                    const { xMin, xMax, yMin, yMax } = paddedAndShiftedExtent;

                    focusFeatureByXYCoordinates(xMin, xMax, yMin, yMax, {
                        ignoreOpts: true // lets mapbox do its thing with the extent
                    })
                }
                setViewState({ ...viewState, ...currentOrganization.initialViewport })
            }
        }
    }, [accessToken, organizationResult, currentOrganizationId, mapRefLoaded, viewStateSet, viewState, fileUploadId, featureId, sourceDataAPLayerName, sourceDataRCLLayerName, focusFeatureByGeometry])

    // layer selection effect
    useEffect(() => {

        if (typeof accessToken === 'undefined' || typeof organizationResult === 'undefined') {
            return
        }

        if (!organizationResult) {
            return
        }

        if (organizationResult instanceof Error || !organizationResult.belongs) {
            return
        }

        const currentOrganization = organizationResult.organizations.find((org) => org.id === currentOrganizationId)

        if (!currentOrganization) {
            return
        }
        if (!featureSetOnIntialLoad) {
            const fuId = currentOrganization.fileUploads.find(f => f.uploadId === fileUploadId)?.uploadId
            if (!fuId) {
                setFeatureSetOnIntialLoad(true)
                return
            }
            if (layerName && layerSelection.length > 0) {
                setLayerSelection(layerSelection.map(ls => {
                    return layerName === ls.layerInfo.name ? new TLayerSelection(ls.layerInfo, true, ls.rgbColor, ls.selectionOrder) : ls
                }))
                setFeatureSetOnIntialLoad(true)
            }

        }
    }, [accessToken, organizationResult, currentOrganizationId, fileUploadId, layerName, layerSelection, featureSetOnIntialLoad])

    // takes care of setting the source AP and RCL layer names for usage on the map
    // it does this by correlating the fuModelLayers with the templates and then using
    // the systemLayerName to set the source layer name
    useEffect(() => {
        let newSystemNameDictionary: TSystemNameDictionary = {};
        const allTemplates = getTemplatesRespBody?.data?.templates ?? [];
        const _organization = organizationResult && !(organizationResult instanceof Error) && organizationResult.belongs
            ? organizationResult.organizations.find((org) => org.id === currentOrganizationId) : undefined
        const fuModelLayers = _organization?.fuModels?.find((fm) => fm.source === fileUploadId)?.fuModelLayers ?? [];

        if (!_organization || !fileUploadId || !fuModelLayers || !allTemplates || !Array.isArray(fuModelLayers) || !Array.isArray(allTemplates)) {
            return
        }
        try {
            const currentOrgAndUploadModel = _organization.fuModels?.find((fm) => fm.source === fileUploadId)
            for (const fuModelLayer of fuModelLayers) {
                const { source, destination } = fuModelLayer;
                const matchingTemplate = allTemplates.find((t) => t.objectId === currentOrgAndUploadModel?.destination);
                const matchingLayer = matchingTemplate?.layers?.find((l: any) => l?.name === destination);
                if (matchingLayer) {
                    const systemLayerName = matchingLayer?.systemLayerName;
                    if (typeof source === 'string' && typeof systemLayerName === 'string') {
                        if (systemLayerName === 'AddressPoint') {
                            setSourceDataAPLayerName(source);
                        } else if (systemLayerName === 'RoadCenterline') {
                            setSourceDataRCLLayerName(source);
                        }
                    }

                    if (source && destination) {

                        let newFieldDictionary: any = {}
                        for (const field of fuModelLayer.fuModelLayerFields) {
                            newFieldDictionary[field.source] = field.destination;
                        }

                        newSystemNameDictionary[source] = {
                            layerName: systemLayerName,
                            fields: newFieldDictionary
                        }
                    }
                }
            }
        } catch (e) {
            console.warn('Error setting source layer names:', e)
        }

        setSystemNameDictionary(newSystemNameDictionary)
    }, [organizationResult, currentOrganizationId, fileUploadId, getTemplatesRespBody])

    // layerSelection useEffect, reorders the layers on the map
    useEffect(() => {
        if (mapRef && mapRef.current) {
            const getLayersBySource = (sourceId: string) => {
                const allLayers = mapRef.current?.getStyle().layers;
                return allLayers?.filter((layer: any) => layer.source === sourceId) || [];
            };

            const moveLayersOnTop = (topLayers: any[], bottomLayers: any[]) => {
                if (topLayers.length > 0) {
                    const lastTopLayerId = topLayers[0].id;
                    bottomLayers.forEach(layer => {
                        mapRef.current?.moveLayer(layer.id, lastTopLayerId);  // Move the top layers above the last road layer
                    });
                }
            }

            // map for the order the layers need to be rendered
            const orderMap: Record<string, number> = {};
            layerSelection.forEach((ls) => orderMap[ls.layerInfo.name] = ls.selectionOrder)

            const sorterSelectedLayers = [...layerSelection].filter(ls => ls.checked).sort((a, b) => {
                const orderA = orderMap[a.layerInfo.name]
                const orderB = orderMap[b.layerInfo.name]
                return orderA - orderB
            })

            // This the bottom layer and moves it under the next layer, then moves the second layer under the third, and so on.
            // Each layer is positioned above the one below it, making sure the layers stack up correctly.
            sorterSelectedLayers.forEach((sourceLayer, index) => {
                if (index === 0) {
                    return;
                } else {
                    const bottomLayers = getLayersBySource(sourceLayer.layerInfo.name)
                    const topLayers = getLayersBySource(sorterSelectedLayers[index - 1].layerInfo.name)

                    moveLayersOnTop(topLayers, bottomLayers)
                }
            });

            // Then reverse the order (starting from the top layer) and do a similar process moving each layer below the next one.
            // This makes that the order is reinforced, and fixes any mistakes where a layer might be above the wrong one.
            sorterSelectedLayers.reverse().forEach((sourceLayer, index) => {
                if (index === 0) {
                    return;
                } else {
                    const topLayers = getLayersBySource(sourceLayer.layerInfo.name)
                    const bottomLayers = getLayersBySource(sorterSelectedLayers[index - 1].layerInfo.name)

                    moveLayersOnTop(topLayers, bottomLayers)
                }
            });

            const allLayers = mapRef.current?.getStyle().layers;
            allLayers?.filter((l: any) => sorterSelectedLayers.filter(sl => sl.checked).includes(l.source)).forEach((l: any, index: number) => {
                console.log(`DEBUG ${index}. layerName: ${l.name} layerSource: ${l.source}`)
            }

            )
        }
    }, [layerSelection])

    const {
        mutate: fishboneFeatureMutate
    } = useMutation('fishbone-feature', fishboneFeature, {
        onSuccess: ({ data: responseBody }) => {
            console.log('fishbone-feature onSuccess')
            generateFishboneCollection(responseBody, true)

        },
        onError: (error, vars, ctx) => {
            console.error('OOPS\n', error, vars, ctx)
            setFishboneFeatureCollection(undefined);
            setFishboneFeatureCollectionHouseLine(undefined);
        },
        onMutate: () => {
            const mapCanvas = mapRef.current?.getCanvas();
            if (!mapCanvas) {
                return;
            }
            mapCanvas.style.cursor = 'wait'
        },
        onSettled: () => {
            const mapCanvas = mapRef.current?.getCanvas();
            if (!mapCanvas) {
                return;
            }
            mapCanvas.style.cursor = ''
        }
    })

    const getFeatureFishbone = useCallback((
        accessToken: string | null | undefined,
        organizationId: string | null,
        uploadId: string | null,
        featureToFishbone: { layerName: string; feature: Feature; } | null) => {
        if (!organizationId || !accessToken || !uploadId || !featureToFishbone) {
            console.error('Unable to fetch fishbone data.  Missing required pre-reqs.');
            return;
        }

        // fetch fishbone and let react-query handle the rest via onSuccess and onError
        fishboneFeatureMutate({
            accessToken: accessToken,
            organizationId: organizationId,
            uploadId: uploadId,
            featureId: featureToFishbone?.feature?.id || featureToFishbone?.feature?.properties?._id,
        });

    }, [fishboneFeatureMutate])


    const generateFishboneCollection = (fishboneBoneData: TFishboneInfo, paintFocusedLine = false) => {
        const { fishboneFeature, leftPoints, leftLines, rightPoints, rightLines } = fishboneBoneData
        const fishboneFeatureIsPoint = fishboneFeature.feature.geometry.type === 'Point'

        const fishbonePoints = [
            ...leftPoints,
            ...rightPoints
        ].map((x) => {
            const point: Feature<Point, GeoJsonProperties> = {
                id: uuid(),
                type: "Feature",
                properties: {
                    hno: x.houseNumber,
                    side: x.side,
                },
                geometry: x.point
            }
            return point
        })
        const fishboneLines = [
            ...leftLines,
            ...rightLines
        ].map((x) => {
            const line: Feature<LineString, GeoJsonProperties> = {
                id: uuid(),
                type: "Feature",
                properties: {},
                geometry: x
            }
            return line
        })

        let correlatedLineIdx = -1;
        let sourceHouseCollection;
        if (paintFocusedLine && fishboneFeatureIsPoint) {
            correlatedLineIdx = fishboneLines.findIndex((line) => {
                const isOnLine = booleanPointOnLine(fishboneFeature.feature.geometry, line.geometry);
                return isOnLine;
            });
            if (correlatedLineIdx > -1) {
                const featureCollection: FeatureCollection<Geometry, GeoJsonProperties> = {
                    type: 'FeatureCollection',
                    features: [fishboneLines[correlatedLineIdx]]
                }
                sourceHouseCollection = featureCollection
            }
        } else {
            sourceHouseCollection = undefined
        }

        const featureCollection: FeatureCollection<Geometry, GeoJsonProperties> = {
            type: 'FeatureCollection',
            features: [
                ...fishbonePoints,
                ...(correlatedLineIdx > -1 ? fishboneLines.filter((v, idx) => idx !== correlatedLineIdx) : fishboneLines)
            ]
        }
        setFishboneFeatureCollection(featureCollection)
        setFishboneFeatureCollectionHouseLine(sourceHouseCollection)

    }

    const generateProposedFishboneCollection = (proposedFishboneInfo: TFishboneProposalInfo, proposedGeometry: Point) => {
        const fishbonePointsProposed = [
            ...proposedFishboneInfo.proposalPoints
        ].map((x) => {

            const point: Feature<Point, GeoJsonProperties> = {
                type: "Feature",
                id: uuid(),
                properties: {
                    hno: x.houseNumber,
                    side: x.side,
                },
                geometry: x.point
            }
            return point;
        });

        const fishboneLinesPropsed = [
            ...proposedFishboneInfo.proposalLines
        ].map((x) => {
            const line: Feature<LineString, GeoJsonProperties> = {
                id: uuid(),
                type: "Feature",
                properties: {},
                geometry: x
            }
            return line;
        });

        const proposeFeatureCollection: FeatureCollection<Geometry, GeoJsonProperties> = {
            type: 'FeatureCollection',
            features: [...fishbonePointsProposed, ...fishboneLinesPropsed]
        };
        setProposedFishboneFeatureCollection(proposeFeatureCollection)
    }

    // Do we want this handled on the server?
    const fishbonePointClick = useCallback(
        (e: mapboxgl.MapLayerMouseEvent) => {

            let features = e?.features?.filter((f) => f.layer.type !== 'heatmap');
            if (!Array.isArray(features) || features.length === 0) {
                return;
            }

            const clickedFishboneFeature = features[0];
            if (!clickedFishboneFeature) {
                return;
            }

            const clickedFeatureGeometry = clickedFishboneFeature.geometry as Point

            const newFishbonePointsProposed: Feature<Point, GeoJsonProperties> = {
                type: "Feature",
                properties: clickedFishboneFeature.properties,
                geometry: clickedFeatureGeometry
            }

            const newFishboneLineProposed: Feature<LineString, GeoJsonProperties> = {
                type: "Feature",
                properties: {},
                geometry: {
                    type: "LineString",
                    coordinates: [clickedFeatureGeometry.coordinates, (drawRefFeature?.geometry as Point).coordinates],
                },
            };

            const updatedProposeFeatureCollection: FeatureCollection<Geometry, GeoJsonProperties> = {
                type: 'FeatureCollection',
                features: [newFishbonePointsProposed, newFishboneLineProposed]
            };

            setProposedFishboneFeatureCollection(updatedProposeFeatureCollection);
        },
        [drawRefFeature?.geometry]
    );
    const [clusterCollection, setClusterCollection] = useState<FeatureCollection<Geometry, GeoJsonProperties>>()

    const onClusterGeneration = useCallback((response: TGenerateClusterResponse) => {
        const sourceId = sourceDataAPLayerName || 'AddressPoints_NG911';
        setDrawRefFeature(null);
        (mapRef.current?.getSource(sourceId) as any).reload();
        const polygon: Feature<Polygon, GeoJsonProperties> =
        {
            id: uuid(),
            type: "Feature",
            properties: {
            },
            geometry: response.clusterPolygon
        }

        const collection: FeatureCollection<Geometry, GeoJsonProperties> = {
            type: 'FeatureCollection',
            features: [
                polygon
            ]
        }
        var t: string[] = response.clusterFeatures.map(af => af.id)
        setClusterApFeatureIds(t)
        // setSelectedFeature({ layerName: feature.layerName, feature: { ...feature.feature, id: feature.id } })
        setSelectedFeature({ layerName: response.clusterFeatures[0].layerName, feature: { ...response.clusterFeatures[0].feature, id: response.clusterFeatures[0].id, type: 'Feature' } })
        setClusterCollection(collection)
    }, [mapRef, sourceDataAPLayerName])

    useEffect(() => {

        const mapInstance = mapRef.current;
        if (mapInstance && proposeFishboneFeatureCollection && systemNameDictionary) {
            mapInstance.on('click', 'fishbone-points-layer', fishbonePointClick);

            mapInstance.on('mouseenter', 'fishbone-points-layer', (e) => {
                if (mapInstance) {

                    //filter out address points
                    if (e.features && e.features?.length > 0 && e.features.some(feature => !(feature.layer.id.includes('fishbone')))) return;

                    mapInstance.getCanvas().style.cursor = 'pointer';
                    let features = e?.features?.filter((f) => f.layer.type !== 'heatmap');
                    if (!Array.isArray(features) || features.length === 0) return;

                    const hoveredFishboneFeature = features[0];
                    if (!hoveredFishboneFeature || !hoveredFishboneFeature.properties) {
                        return
                    }

                    const houseNumber = hoveredFishboneFeature.properties['hno'];

                    if (houseNumber) {
                        setHoverAdditions({ prefix: houseNumber })
                    }

                }
            });

            mapInstance.on('mouseleave', 'fishbone-points-layer', () => {
                if (mapInstance) {
                    mapInstance.getCanvas().style.cursor = '';
                    setHoverAdditions(undefined)
                }
            });
        }

        return () => {
            if (mapInstance) {
                mapInstance.off('click', 'fishbone-points-layer', fishbonePointClick);
            }

        };
    }, [fishbonePointClick, proposeFishboneFeatureCollection, systemNameDictionary]);

    // fishbone on selection change useEffect
    useEffect(() => {
        if (!selectedFeature) {
            return;
        }

        if (drawControlRef.current) {
            drawControlRef.current.deleteAll()
        }

        setActiveSidePanelTab(FEATURE_MANAGER_TAB)

        if ((selectedFeature.layerName === sourceDataAPLayerName
            || selectedFeature.layerName === sourceDataRCLLayerName
            || selectedFeature.feature.geometry.type === 'Point'
            || selectedFeature.feature.geometry.type === 'LineString')) {
            getFeatureFishbone(accessToken, currentOrganizationId, fileUploadId, selectedFeature)
        }

    }, [accessToken, currentOrganizationId, fileUploadId, getFeatureFishbone, sourceDataAPLayerName, sourceDataRCLLayerName, selectedFeature, proposalInfo?.geometry, proposalInfo?.featureId])

    const drawControlRef = useRef<MapboxDraw>();
    const drawControlEditorRef = useRef<MapboxDraw | null>(null);


    const onNewFeature = useCallback((feature: any) => {
        const newSelectedFeature = { layerName: feature.layerName, feature: { ...feature.feature, id: feature.id } };
        setDrawRefFeature(null)
        setSelectedFeature(newSelectedFeature);
        setProposedFishboneFeatureCollection(undefined);
        getFeatureFishbone(accessToken, currentOrganizationId, fileUploadId, newSelectedFeature);
        drawControlRef?.current?.deleteAll();
        (mapRef.current?.getSource(feature.layerName) as any).reload();
    }, [accessToken, currentOrganizationId, fileUploadId, getFeatureFishbone])

    const onUpateFeature = useCallback(async (featureId: string) => {
        const fetchedFeature = await getFeatureById(accessToken!, currentOrganizationId, fileUploadId, featureId)
        const newSelectedFeature = { layerName: fetchedFeature.data?.layerName, feature: { ...fetchedFeature.data?.feature, id: fetchedFeature.data?.id } }

        const m = mapRef.current?.getMap();
        const sourceId = sourceDataAPLayerName || 'AddressPoints_NG911';
        m?.setFilter(sourceId + "-1", null);
        if (m?.getLayer("fishbone-source-house-line-layer") !== undefined) {
            m?.setLayoutProperty("fishbone-source-house-line-layer", "visibility", "visible");
        }
        (mapRef.current?.getSource(sourceId) as any).reload();
        (mapRef.current?.getSource(fetchedFeature.data?.layerName) as any).reload()
        drawControlRef.current?.deleteAll()
        drawControlRef.current?.changeMode('simple_select')
        setSelectedFeature(newSelectedFeature)
        getFeatureFishbone(accessToken, currentOrganizationId, fileUploadId, newSelectedFeature)
        setProposedFishboneFeatureCollection(undefined);

    }, [accessToken, currentOrganizationId, fileUploadId, getFeatureFishbone, sourceDataAPLayerName])

    const onFeatureProposal = useCallback((response: TFishboneProposeResponse) => {
        const { fishboneInfo, fishboneProposalInfo, featureProposal } = response

        if (fishboneInfo) {
            generateFishboneCollection(fishboneInfo, true)
        }

        if (fishboneProposalInfo) {
            generateProposedFishboneCollection(fishboneProposalInfo, featureProposal.geometry as Point)
        }

        (mapRef.current?.getSource(featureProposal.featureLayer) as any).reload();
    }, [])


    const [displayingEditorMap, setDisplayingEditorMap] = useState(false);
    const [drawingControls, setDrawingControls] = useState<ControlButtonProps[]>([]);
    const [workingLayer, setWorkingLayer] = useState<string | undefined>(undefined);
    const [defaultMode, setDefaultMode] = useState('');

    const onStartAddFeature = useCallback((layer: string, isClusterAdd: boolean) => {

        setActiveSidePanelTab(FEATURE_MANAGER_TAB)
        setSelectedFeature(undefined)

        if (fishboneFeatureCollection && fishboneFeatureCollectionHouseLine) {
            const featureCollection: FeatureCollection<Geometry, GeoJsonProperties> = {
                type: 'FeatureCollection',
                features: [
                    ...fishboneFeatureCollection?.features || [],
                    ...fishboneFeatureCollectionHouseLine?.features || []
                ]
            }
            setFishboneFeatureCollection(featureCollection)
        }
        setFishboneFeatureCollectionHouseLine(undefined)
        drawControlRef.current?.deleteAll()

        if (layer === sourceDataAPLayerName) {
            if (isClusterAdd) {
                let controls: ControlButtonProps[] = [
                    {
                        title: "Draw Polygon",
                        icon: <PolylineIcon />,
                        onClick: () => {
                            drawControlEditorRef.current?.changeMode('snap_draw_polygon')
                        }
                    },
                    {
                        title: "Delete",
                        icon: <DeleteIcon />,
                        onClick: () => {
                            drawControlEditorRef.current?.deleteAll()
                        }
                    },
                    {
                        title: "Finish Drawing",
                        icon: <CheckCircleOutline />,
                        onClick: () => {
                            setDisplayingEditorMap(false)
                        }
                    }
                ]
                setDefaultMode('snap_draw_polygon')
                setDrawingControls(controls)
                setDisplayingEditorMap(true);
            } else {
                drawControlRef.current?.changeMode('draw_point')
            }
        } else if (layer === sourceDataRCLLayerName) {
            let controls: ControlButtonProps[] = [
                {
                    title: "Draw Line",
                    icon: <PolylineIcon />,
                    onClick: () => {
                        drawControlEditorRef.current?.changeMode('snap_line_string')
                    }
                },
                {
                    title: "Paint",
                    icon: <BrushIcon />,
                    onClick: () => {
                        drawControlEditorRef.current?.changeMode('draw_paint_mode')
                    }
                },
                {
                    title: "Delete",
                    icon: <DeleteIcon />,
                    onClick: () => {
                        drawControlEditorRef.current?.deleteAll()
                    }
                },
                {
                    title: "Finish Drawing",
                    icon: <CheckCircleOutline />,
                    onClick: () => {
                        setDisplayingEditorMap(false)
                    }
                }
            ]
            setDrawingControls(controls)
            setDefaultMode('snap_line_string')
            setDisplayingEditorMap(true);
        } else {
            let controls: ControlButtonProps[] = [
                {
                    title: "Draw Polygon",
                    icon: <PolylineIcon />,
                    onClick: () => {
                        drawControlEditorRef.current?.changeMode('snap_draw_polygon')
                    }
                },
                {
                    title: "Delete",
                    icon: <DeleteIcon />,
                    onClick: () => {
                        drawControlEditorRef.current?.deleteAll()
                    }
                },
                {
                    title: "Finish Drawing",
                    icon: <CheckCircleOutline />,
                    onClick: () => {
                        setDisplayingEditorMap(false)
                    }
                }
            ]
            setDefaultMode('snap_draw_polygon')
            setDrawingControls(controls)
            setDisplayingEditorMap(true);
        }

        setWorkingLayer(layer)
    }, [sourceDataRCLLayerName, sourceDataAPLayerName, fishboneFeatureCollection, fishboneFeatureCollectionHouseLine])
    const onStartUpdateFeature = useCallback((feature: any) => {
        setActiveSidePanelTab(FEATURE_MANAGER_TAB)
        setSelectedFeature({ feature: feature, layerName: feature.layerName })
        setDrawRefFeature({ geometry: feature?.geometry, featureId: feature.id })

        const m = mapRef.current?.getMap()
        // according top the docs using ["id"] should work, but I couldn't get it to.
        // ["get", "_id"] retrieves from the properties on the features
        const features = m?.queryRenderedFeatures(undefined, {
            filter: ["==", ["get", "_id"], feature?.id]
        })

        if (!features || features.length === 0) {
            return;
        }

        m?.setFilter(features[0].layer.id, ["!=", ["get", "_id"], feature?.id])

        if (m?.getLayer("fishbone-source-house-line-layer") !== undefined) {
            m?.setLayoutProperty("fishbone-source-house-line-layer", "visibility", "none")
        }
        drawControlRef.current?.deleteAll()
        drawControlRef.current?.changeMode('draw_point')
    }, [])

    useEffect(() => {
        if (!drawRefFeature) {
            return
        }
        makeFeatureMoveable([drawRefFeature?.featureId])
    }, [drawRefFeature, makeFeatureMoveable])


    const onFeatureProposalCancel = useCallback((feature: any) => {
        // for points
        drawControlRef.current?.deleteAll()
        drawControlRef.current?.changeMode('simple_select')

        // for lines and polygons
        drawControlEditorRef.current?.changeMode('simple_select')
        drawControlEditorRef.current?.deleteAll()
        setDisplayingEditorMap(false);
        setDrawRefFeature(null)
        setProposedFishboneFeatureCollection(undefined)
        setRCLSwitchFeature(null)
        const sourceId = sourceDataAPLayerName || 'AddressPoints_NG911';
        if (selectedFeature) {
            getFeatureFishbone(accessToken, currentOrganizationId, fileUploadId, selectedFeature)
        }

        if (feature) {
            const m = mapRef.current?.getMap();
            m?.setFilter(sourceId + "-1", null);
            m?.setLayoutProperty("fishbone-source-house-line-layer", "visibility", "visible");
            (mapRef.current?.getSource(sourceId) as any).reload();
            setSelectedFeature({ layerName: sourceId, feature: { ...feature.feature, id: feature.id } })
        }

    }, [accessToken, currentOrganizationId, fileUploadId, getFeatureFishbone, selectedFeature, sourceDataAPLayerName])

    const onDeleteFeature = useCallback((layerName: string) => {
        setSelectedFeature(undefined)
        setProposalInfo(undefined)
        setFishboneFeatureCollectionHouseLine(undefined)
        openSuccessSnackbar('Feature deleted successfully');
        (mapRef.current?.getSource(layerName) as any).reload();
        setActiveSidePanelTab(SELECT_TAB_ID)
    }, [])

    const drawRefUpdate = useCallback(async (e: any) => {
        for (const f of e.features) {
            setDrawRefFeature(prevState => {
                //I don't really like this....sorry..
                // basically we want to keep track of the point feature that is being adjusted
                // not our point we dropped down
                if (prevState != null && f.id === 'proposed-new-feature-id') {
                    return {
                        featureId: prevState?.featureId,
                        geometry: f.geometry,
                    };

                }

                return {
                    featureId: f.id,
                    geometry: f.geometry,
                }
            });
        }
    }, []);

    const onDelete = useCallback((e: any) => {
    }, []);
    const getCurrentOrganization = useCallback(() => (organizationResult && !(organizationResult instanceof Error) && organizationResult.belongs)
        ? organizationResult.organizations.find((org) => org.id === currentOrganizationId) : null, [currentOrganizationId, organizationResult])
    const currentOrganization = getCurrentOrganization();

    const { layerUrlClassifiedFromName, layerUrlFromName } = useLayerUrls(currentOrganization, fileUploadId);


    // TODO: cleanup clicked feature types
    const handleFeatureClickedFromSearchTab = useCallback((feature: any) => {

        setLayerSelection(layerSelection.map(ls => {
            return feature.layerName === ls.layerInfo.name ? new TLayerSelection(ls.layerInfo, true, ls.rgbColor, ls.selectionOrder) : ls
        }))
        console.log(`feature: ${JSON.stringify(feature)}`)
        setSelectedFeature({ layerName: feature.layerName, feature: { ...feature.feature, id: feature.id } })
        focusFeatureByGeometry(feature.feature.geometry, 18)
    }, [layerSelection, focusFeatureByGeometry])

    if (!currentOrganization) {
        // show an error page
        return <div>Loading...</div>
    }

    const orgLayers = currentOrganization.fileUploads.find((f) => f.uploadId === fileUploadId)?.layers ?? []


    const updateSelectedLayer = (updatedLayer: TLayerSelection) => {
        //remove the previous version of the layer
        const modifiedIndex = layerSelection.findIndex(layer => layer.layerInfo.name === updatedLayer.layerInfo.name)
        layerSelection[modifiedIndex] = updatedLayer
        //add the new version of the layer
        setLayerSelection(layerSelection.map(ls => {
            return ls.layerInfo.name === updatedLayer.layerInfo.name ? updatedLayer : ls
        }))

        console.log('Should this next line run?')
        setSelectedFeature(undefined)
    }

    const tabs: TabItem[] = [
        {
            id: SEARCH_TAB_ID,
            tooltip: 'Search',
            disabled: false,
            icon: <SearchIcon />,
            content: <SearchTab accessToken={accessToken!} organizationId={currentOrganizationId!} fileUploadId={fileUploadId} templateStringsMap={templateStringMap} handleFeatureClickedFromSearchTab={handleFeatureClickedFromSearchTab} />
        },
        {
            id: SELECT_TAB_ID,
            disabled: false,
            tooltip: 'Select Layer',
            icon: <LayersIcon />,
            content: <SelectLayerTab apLayerName={sourceDataAPLayerName} rclLayerName={sourceDataRCLLayerName} roadConfig={currentOrganization.roadConfig} layerSelection={layerSelection} orgLayers={orgLayers} updateSelectedLayer={updateSelectedLayer} />
        },
        {
            id: FEATURE_MANAGER_TAB,
            tooltip: '',
            disabled: false,
            icon: <IconButton ><DashboardIcon /></IconButton>,
            content: <FeatureManager map={mapRef.current?.getMap()}
                drawControlRef={drawControlRef}
                organizationId={currentOrganizationId}
                fileUploadId={fileUploadId}
                selectedFeatureId={selectedFeature?.feature.id as string}
                proposedMapFeature={drawRefFeature !== null ? {
                    featureId: drawRefFeature?.featureId, geometry: drawRefFeature?.geometry, isClusterFeature: clusterApFeatureIds.includes(drawRefFeature?.featureId)
                } : null}
                RCLSwitchFeature={RCLSwitchFeature}
                fishbonePointLayerName='fishbone-points-layer'
                systemNameDictionary={systemNameDictionary}
                templateStringMap={templateStringMap}

                focusFeatureByGeometry={focusFeatureByGeometry}
                selectFeaturesById={makeFeatureMoveable}
                onStartAddFeature={onStartAddFeature}
                onSaveNewFeature={onNewFeature}
                onStartUpdateFeature={onStartUpdateFeature}
                onUpdateFeature={onUpateFeature}
                onDeleteFeature={onDeleteFeature}
                onFeatureProposal={onFeatureProposal}
                onFeatureProposalCancel={onFeatureProposalCancel}
                onClusterGeneration={onClusterGeneration} />,

        },
        {
            id: SETTINGS_TAB_ID,
            tooltip: 'Settings',
            disabled: false,
            icon: <SettingsIcon />,
            content: <SettingsTab currentZoom={viewState.zoom} roadConfig={currentOrganization.roadConfig} onInMemoryRoadConfigChange={handleInMemoryRoadConfigChange} isSavingRoadConfig={isMutatingRoadConfig} onSaveRoadConfig={onSaveRoadConfig} />
        }
    ];

    // get the color of the source data's AP layer or fallbck to the first point layer and apply to the fishbone
    const fishboneLayerColor = layerSelection?.find(x => x && (x.layerInfo.name === sourceDataAPLayerName || x.layerInfo.type === 'Point'))?.rgb1()
    // console.log(`fishboneLineColor: ${fishboneLayerColor}\n${JSON.stringify({ sourceDataAPLayerName, layerSelection })}`)

    return (
        <Shell>
            <SidePanelTabs tabs={tabs} activeTab={activeSidePanelTab} setActiveTab={handleActiveSidePanelTabChange} />

            {displayingEditorMap && <ReactMap
                ref={editorMapRef}
                style={{ width: '100vw', height: 'calc(100vh - 64px)' }}
                mapStyle={mapStyle}
                onMove={e => setViewState(e.viewState)}
                onLoad={() => {

                    if (drawControlEditorRef.current) {

                        const customControl = new CustomControlButtons(drawControlEditorRef.current, editorMapRef.current!.getMap(), drawingControls);

                        if (!editorMapRef.current!.hasControl(customControl)) {
                            editorMapRef.current!.addControl(customControl, "top-right");
                        }
                    }

                }}
                onStyleData={(mbe) => {
                    const _map = mbe.target;
                    const _layers = _map.getStyle().layers;
                    for (const l of _layers) {
                        if (l.type === 'symbol') {
                            setFirstSymbolLayerId(l.id)
                            break
                        }
                    }
                }}

                onSourceData={onSourceData}
                onClick={() => { }}
                {...viewState}
            >
                <Layers
                    layers={orgLayers}
                    roadLabels={currentOrganization.roadLabels}
                    roadConfig={inMemoryRoadConfig ? inMemoryRoadConfig : currentOrganization.roadConfig}
                    selectedLayers={layerSelection}
                    layerUrlFromName={layerUrlFromName}
                    layerUrlClassifiedFromName={layerUrlClassifiedFromName}
                    templateStringMap={templateStringMap}
                    firstSymbolLayerId={firstSymbolLayerId}
                    hoverAdditions={hoverAdditions}

                />

                <DrawControl
                    ref={drawControlEditorRef}
                    displayControlsDefault={false}
                    controls={{}}
                    onCreate={drawRefUpdate}
                    onUpdate={drawRefUpdate}
                    onDelete={onDelete}
                    defaultMode={defaultMode}
                    modes={{
                        ...MapboxDraw.modes,
                        "snap_draw_polygon": SnapPolygonMode,
                        "snap_line_string": SnapLineMode,
                        "draw_paint_mode": PaintMode
                    }}
                    snap={true}
                    snapOptions={{
                        snapPx: 15, // defaults to 15
                        snapToMidPoints: true, // defaults to false
                        snapVertexPriorityDistance: 0.0025, // defaults to 1.25
                        snapGetFeatures: (map: MapboxMap, draw: MapboxDraw) => [
                            ...map.queryRenderedFeatures(undefined, { layers: [`${workingLayer}-1`] }),
                            ...draw.getAll().features,
                        ],
                    }}

                />
                {/* TODO: DO WE WANT FISHBONE ON THE EDITOR MAP? */}
                {fishboneFeatureCollection && <>
                    <Source id="fishbone-source" type="geojson" data={fishboneFeatureCollection}>
                        {proposeFishboneFeatureCollection && <Layer
                            id="fishbone-points-layer"
                            type="circle"
                            paint={{
                                'circle-color': fishboneLayerColor ?? 'purple',
                                'circle-radius': 4,
                            }}
                            filter={['==', '$type', 'Point']}
                        />}
                        <Layer
                            id="fishbone-lines-layer"
                            type="line"
                            paint={{
                                'line-color': fishboneLayerColor ?? 'purple',
                                'line-width': 4
                            }}
                            filter={['==', '$type', 'LineString']}
                        />
                    </Source>

                </>}

                {proposeFishboneFeatureCollection && <>
                    <Source id="fishbone-propose-source" type="geojson" data={proposeFishboneFeatureCollection}>
                        <Layer
                            id="propose-fishbone-points-layer"
                            type="circle"
                            source="my-data"
                            paint={{
                                'circle-color': 'yellow',
                                'circle-radius': 4,
                            }}
                            filter={['==', '$type', 'Point']}

                        />
                        <Layer
                            id="propose-fishbone-lines-layer"
                            type="line"
                            source="my-data"
                            paint={{
                                'line-color': 'yellow',
                                'line-width': 4,
                            }}
                            filter={['==', '$type', 'LineString']}

                        />

                    </Source>
                    {fishboneFeatureCollectionHouseLine && !proposeFishboneFeatureCollection && <>
                        <Source id="fishbone-source-house-line" type="geojson" data={fishboneFeatureCollectionHouseLine}>
                            <Layer
                                id="fishbone-source-house-line-layer"
                                type="line"
                                paint={{
                                    'line-color': 'cyan',
                                    'line-width': 5,
                                }}
                                filter={['==', '$type', 'LineString']}
                            />
                        </Source>
                    </>
                    }
                </>}
            </ReactMap>}
            {!displayingEditorMap && <ReactMap
                ref={mapRef}
                style={{ width: '100vw', height: 'calc(100vh - 64px)' }}
                mapStyle={mapStyle}
                onMove={e => setViewState(e.viewState)}
                onLoad={() => {
                    setMapRefLoaded(true)
                }}
                onStyleData={(mbe) => {
                    const _map = mbe.target;
                    const _layers = _map.getStyle().layers;
                    for (const l of _layers) {
                        if (l.type === 'symbol') {
                            setFirstSymbolLayerId(l.id)
                            break
                        }
                    }
                }}

                onSourceData={onSourceData}
                onClick={onClick}
                {...viewState}
            >
                <Card sx={{ position: 'absolute', top: 8, right: 8 }}>
                    <Select label="Map Style" value={mapStyle} onChange={(e) => setMapStyle(e.target.value)}>
                        {possibleMapStyles.map((style) => (
                            <MenuItem key={style.mapStyle} value={style.mapStyle}>{style.displayName}</MenuItem>
                        ))}
                    </Select>
                </Card>
                {clusterCollection && <>
                    <Source id="clusterCollection" type="geojson" data={clusterCollection}>
                        <Layer
                            id="cluster-polygon"
                            interactive={false}
                            type="fill"
                            paint={{
                                'fill-outline-color': 'black',
                                'fill-color': 'rgba(0, 0, 0, 0)'  // Transparent fill (optional)
                            }}

                        />

                    </Source>
                </>}

                <Layers
                    layers={orgLayers}
                    roadLabels={currentOrganization.roadLabels}
                    roadConfig={currentOrganization.roadConfig}
                    selectedLayers={layerSelection}
                    layerUrlFromName={layerUrlFromName}
                    layerUrlClassifiedFromName={layerUrlClassifiedFromName}
                    templateStringMap={templateStringMap}
                    firstSymbolLayerId={firstSymbolLayerId}
                    hoverAdditions={hoverAdditions}
                />

                <DrawControl
                    ref={drawControlRef}
                    displayControlsDefault={false}
                    controls={{}}
                    onCreate={drawRefUpdate}
                    onUpdate={drawRefUpdate}
                    onDelete={onDelete}

                />
                {fishboneFeatureCollection && <>
                    <Source id="fishbone-source" type="geojson" data={fishboneFeatureCollection}>
                        {proposeFishboneFeatureCollection && <Layer
                            id="fishbone-points-layer"
                            type="circle"
                            paint={{
                                'circle-color': fishboneLayerColor ?? 'purple',
                                'circle-radius': 4,
                            }}
                            filter={['==', '$type', 'Point']}
                        />}
                        <Layer
                            id="fishbone-lines-layer"
                            type="line"
                            paint={{
                                'line-color': fishboneLayerColor ?? 'purple',
                                'line-width': 4
                            }}
                            filter={['==', '$type', 'LineString']}
                        />
                    </Source>

                </>}

                {proposeFishboneFeatureCollection && <>
                    <Source id="fishbone-propose-source" type="geojson" data={proposeFishboneFeatureCollection}>
                        <Layer
                            id="propose-fishbone-points-layer"
                            type="circle"
                            source="my-data"
                            paint={{
                                'circle-color': 'yellow',
                                'circle-radius': 4,
                            }}
                            filter={['==', '$type', 'Point']}

                        />
                        <Layer
                            id="propose-fishbone-lines-layer"
                            type="line"
                            source="my-data"
                            paint={{
                                'line-color': 'yellow',
                                'line-width': 4,
                            }}
                            filter={['==', '$type', 'LineString']}

                        />

                    </Source>

                </>}

                {fishboneFeatureCollectionHouseLine && !proposeFishboneFeatureCollection && <>
                    <Source id="fishbone-source-house-line" type="geojson" data={fishboneFeatureCollectionHouseLine}>
                        <Layer
                            id="fishbone-source-house-line-layer"
                            type="line"
                            paint={{
                                'line-color': 'cyan',
                                'line-width': 5
                            }}
                            filter={['==', '$type', 'LineString']}
                        />
                    </Source>
                </>
                }

            </ReactMap>
            }


            <Snackbar open={openSnackbar} autoHideDuration={10000} onClose={handleCloseSnackbar}>
                <Alert
                    onClose={handleCloseSnackbar}
                    severity={snackbarAlertSeverity}
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    {snackbarAlertContent}
                </Alert>
            </Snackbar>
        </Shell >
    )
}

export default MapPage;

