import axios from 'axios'
import { useEffect, useState } from 'react'

export type TExtent = {
    xMin?: number
    yMin?: number
    xMax?: number
    yMax?: number
}

export type TLayer = {
    name: string
    type: string
    status: string
    createdAt: string
    insertedFeatureCount?: number
    expectedFeatureCount: number
    color?: string
    extent?: TExtent
    isSymbolLayer: boolean
}

export type TFileUpload = {
    uploadId: string
    friendlyName: string
    fileName: string
    status: string // queued | failed | importing | imported | generated
    createdAt: string
    layers: TLayer[]
    processingAt?: string
    processedAt?: string
    failedAt?: string
    validationRuleConfigs: {
        rule: string
        enabled: boolean
    }[]
}

export type TFileDownload = {
    _id: {
        timestamp: number,
        machine: number,
        pid: number,
        increment: number
        creationTime: number
    },
    id: string
    uploadId: string
    friendlyName: string
    status: string // queued | failed | generating | generated
    createdAt: string
    layers: TLayer[]
    processingAt?: string
    processedAt?: string
    failedAt?: string
    downloadUrl?: string
}

export type TValidation = {
    _id: string
    uploadId: string
    friendlyName: string
    status: string // queued | failed | validating | validated
    createdAt: string
    layers: TLayer[]
    processingAt?: string
    processedAt?: string
    failedAt?: string
}

export type TInitialViewport = { latitude: number, longitude: number, zoom: number }

type TFuModelLayerField = {
    destination: string
    destinationName: string
    source: string
    sourceName: string
}

type TFuModelLayer = {
    source?: string // uploaded layer name
    destination: string // nenas layer for example
    templateString?: string
    autoPopulateSources?: TFuPopulatedFieldSouce[]
    fuModelLayerFields: TFuModelLayerField[]
}

type TFuPopulatedFieldSouce = {
    sourceFieldName: string
    autoPopulateTemplateString: string

}
type TFUModel = {
    source?: string // upload id
    destination: string // nena for example
    fuModelLayers: TFuModelLayer[]
}

export type TRoadLabels = {
    layerName: string
    leftFrom: string
    leftTo: string
    rightFrom: string
    rightTo: string
    fullName: string
    shieldLabel: string
    zoomSwap: number
}

export type TRoadStyle = {
    propertyName: string
    propertyValue: string
    color: string
    minZoom: number // this defined as optional in the c# model
}

export type TRoadConfig = {
    layerName: string
    styles: TRoadStyle[]
}

export type TOrganization = {
    id: string
    auth0DisplayName: string
    auth0Name: string
    initialViewport: TInitialViewport
    fileUploads: TFileUpload[]
    fileDownloads: TFileDownload[]
    validations?: TValidation[]
    availableValidationRuleConfigs?: { rule: string, checked: boolean }[]
    dataModelId?: string
    fuModels?: TFUModel[]
    roadLabels?: TRoadLabels
    roadConfig?: TRoadConfig
}

type TOrganizationsResponse = {
    organizations: TOrganization[]
}

export type TSuccessOrganizationResult = | ({
    belongs: true
    accessToken: string
    organizations: TOrganization[]
})

export type TErrorOrganizationResult = {
    belongs: false
    accessToken: string
    error?: any
}

export type TOrganizationResult = TSuccessOrganizationResult | TErrorOrganizationResult

export const getOrganizations = async (accessToken: string): Promise<TOrganizationResult | Error> => {
    try {
        // const {getAccessTokenSilently} = useAuth0()
        // const accessToken = await getAccessTokenSilently()
        // if (!accessToken) {
        //   throw Error('No access token')
        // }

        const response = await axios.get<TOrganizationsResponse>(
            `${process.env.REACT_APP_API_SERVER_URL}/v1/organizations`,
            {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            },
        )
        if (response.data.organizations.length > 0) {
            return { belongs: true, accessToken: accessToken, organizations: response.data.organizations };
        }
        return { belongs: false, accessToken };
    } catch (error) {
        console.error(error)
        return error as Error;
    }
}

export default function useOrganization(accessToken: string) {
    const [organizationInfo, setOrganizationIfno] = useState<TOrganizationResult | Error>()

    useEffect(() => {
        const _getOrganizations = async () => {
            const _organizationInfo = await getOrganizations(accessToken)
            if (!_organizationInfo) {
                return;
            }

            setOrganizationIfno(_organizationInfo)
        }

        if (accessToken) {
            _getOrganizations()
        }
    }, [accessToken])

    return organizationInfo

}
