// src/hooks/useUpdateFeature.ts
import { AxiosResponse } from 'axios';
import { UseMutateFunction, useMutation } from 'react-query';
import { updateFeature } from '../../api/editController';
import { TFeatureResponse } from './useFetchFeatureById';
import { useMapCursor } from "./useMapCursor";


const useUpdateFeature = (
    map: mapboxgl.Map | undefined,
    fetchFeatureById: UseMutateFunction<AxiosResponse<TFeatureResponse, any>, unknown, { featureId: string }, void>,
    onUpdateFeature: (feature: string) => void = () => { console.debug("useUpdateFeature.onUpdateFeature not utilized") }
) => {
    return useMutation('update-feature', updateFeature, {
        onSuccess: async ({ data: responseBody }) => {
            const { featureId } = responseBody;
            fetchFeatureById({ featureId })
            onUpdateFeature(featureId)
        },
        onError: (error, vars, ctx) => {
            console.error("Update feature failed", error);
        },
        onMutate: useMapCursor(map).cursorWait,
        onSettled: useMapCursor(map).cursorNone
    });
};

export default useUpdateFeature;
