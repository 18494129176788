// src/hooks/useSaveFeature.ts
import { useMutation } from 'react-query';
import { generateCluster } from '../../api/editController';
import { useMapCursor } from "./useMapCursor";

const useGenerateClusterFeatures = (
    map: mapboxgl.Map | undefined,
    onGenerateClusterFeatures: (responseBody: any) => void = () => { console.debug("useSaveFeature.onSaveNewFeature not utililzed") },
) => {
    return useMutation('generate-cluster-features', generateCluster, {
        onSuccess: ({ data: responseBody }) => {
            onGenerateClusterFeatures(responseBody);
        },
        onError: (error, vars, ctx) => {
            console.error("Save feature failed", error);
        },
        onMutate: useMapCursor(map).cursorWait,
        onSettled: useMapCursor(map).cursorNone
    });
};

export default useGenerateClusterFeatures;
