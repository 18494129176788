// src/hooks/useFetchFeatureById.ts
import { useMutation, MutationFunction } from 'react-query';
import { AxiosResponse } from 'axios';
import { getFeatureById } from '../../api/featureController';
import { useMapCursor } from './useMapCursor';

export type TFeatureResponse = {
    feature: any;
    id: string;
    layerName: string;
};

const useFetchFeatureById = (
    map: mapboxgl.Map | undefined,
    setSelectedFeature: (feature: any) => void,
    accessToken: string,
    organizationId: string,
    fileUploadId: string
) => {
    // TODO: Refactor this to useQuery instead of useMutation
    const fetchFeatureById: MutationFunction<AxiosResponse<TFeatureResponse>, { featureId: string }> = async ({featureId }) => {
        const response = await getFeatureById(accessToken, organizationId, fileUploadId, featureId);
        return response;
    };

    return useMutation(fetchFeatureById, {
        onSuccess: ({ data: responseBody }) => {
            const feature = { ...responseBody.feature, id: responseBody.id };
            setSelectedFeature({ layerName: responseBody.layerName, feature });
        },
        onError: (error, vars, ctx) => {
            console.error("Fetch feature failed", error);
        },
        onMutate: useMapCursor(map).cursorWait,
        onSettled: useMapCursor(map).cursorNone
    });
};

export default useFetchFeatureById;
