import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4'

export const useGoogleAnalyticsPageViews = () => {
    const location = useLocation();

    useEffect(() => {
        ReactGA._gaCommandSendPageview(location.pathname, undefined);
    }, [location]);
};
