// src/hooks/useProposeFeature.ts
import { useMutation } from 'react-query';
import { proposeAddFeature, proposeEditFeature } from '../../api/editController';
import { useMapCursor } from "./useMapCursor";

export const useProposeAddFeature = (
    map: mapboxgl.Map | undefined,
    generateAndSetProposalInfo: (featureProposal: any, featureId: string, geometry: any) => void,
    onFeatureProposal: (responseBody: any) => void = () => { console.debug("useProposeAddFeature.onFeatureProposal not utilized") }
) => {
    return useMutation('proposeAddFeature', proposeAddFeature, {
        onSuccess: ({ data: responseBody }) => {
            generateAndSetProposalInfo(responseBody.featureProposal, responseBody.featureProposal.featureId, responseBody.featureProposal.geometry);
            onFeatureProposal(responseBody);
        },
        onError: (error, vars, ctx) => {
            console.error("Propose add feature failed", error);
        },
        onMutate: useMapCursor(map).cursorWait,
        onSettled: useMapCursor(map).cursorNone
    });
};

export const useProposeEditFeature = (
    map: mapboxgl.Map | undefined,
    generateAndSetProposalInfo: (featureProposal: any, featureId: string, geometry: any) => void,
    onFeatureProposal: (responseBody: any) => void = () => { console.debug("useProposeEditFeature.onFeatureProposal not utilized") }
) => {
    return useMutation('proposeEditFeature', proposeEditFeature, {
        onSuccess: ({ data: responseBody }) => {
            generateAndSetProposalInfo(responseBody.featureProposal, responseBody.featureProposal.featureId, responseBody.featureProposal.geometry);
            onFeatureProposal(responseBody);
        },
        onError: (error, vars, ctx) => {
            console.error("Propose edit feature failed", error);
        },
        onMutate: useMapCursor(map).cursorWait,
        onSettled: useMapCursor(map).cursorNone
    });
};

