import React from 'react';
import { Grid, TableContainer, Table, TableBody, TableRow, TableCell, TextField } from "@mui/material";

export type FeatureFormValues = {
    geometry?: any
    properties: { name: string, value: any }[]
    id?: string | number
}

export const FeatureFormState = {
    EDITING: "editing",
    ADDING: "adding",
    VIEWING: "view"
};


export function mapFeaturePropertiesToFormFormat(feature: any) {
    return Object.entries(feature.properties).map(([key, value], i) => ({ name: key, value: value }));
}

const FeatureInfoGrid = ({
    formState,
    formValues,
    changedFields = new Set(),
    handleFieldChange = () => {
        console.log("Handle field change not implemented")
    }
}: {
    formState: string,
    formValues: FeatureFormValues,
    changedFields?: Set<string>,
    handleFieldChange?: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, propertyIndex: number) => void
}) => {
    return (
        <Grid container direction={'row'} alignItems={'normal'}>
            <TableContainer>
                <Table size={'small'}>
                    <TableBody>
                        {formValues.properties?.map((_, i) => {
                            let conditionalStyles = null;
                            if (changedFields.has(formValues.properties[i].name)) {
                                conditionalStyles = { backgroundColor: 'rgb(202, 210, 255)', opacity: .8 };
                            }

                            return (
                                <TableRow key={i} sx={{
                                    ...conditionalStyles,
                                    padding: '4px 0',
                                    '& td, & th': { border: 'none' },
                                }}>
                                    <TableCell align="right" sx={{
                                        padding: '0px 0',
                                    }}>
                                        <TextField disabled={formState === FeatureFormState.VIEWING} label={formValues.properties[i].name}
                                            InputLabelProps={{
                                                shrink: true,  // Forces the label to stay above the input field
                                            }}
                                            variant="outlined"
                                            size="small"
                                            margin="dense"
                                            sx={{
                                                width: '100%',
                                                '& .MuiInputBase-root.Mui-disabled': {
                                                    backgroundColor: '#e0e0e0',
                                                    color: 'black',
                                                },
                                                '& .MuiInputLabel-root.Mui-disabled': {
                                                    color: 'black',
                                                },
                                                '& .MuiOutlinedInput-input.Mui-disabled': {
                                                    '-webkit-text-fill-color': 'black'
                                                }
                                            }} onChange={(event) => handleFieldChange(event, i)} value={formValues.properties[i].value} />
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Grid>
    );
};

export default FeatureInfoGrid;
