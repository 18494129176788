import { useMutation } from 'react-query';
import { proposeSimple } from '../../api/editController';
import { useMapCursor } from "./useMapCursor";


const useSimplePropose = (
    map: mapboxgl.Map | undefined,
    onFeatureProposal: (responseBody: any) => void = () => { console.debug("useProposeEditFeature.onSimplePropose not utilized") }
) => {
    return useMutation('proposeEditFeature', proposeSimple, {
        onSuccess: ({ data: responseBody }) => {
            onFeatureProposal(responseBody);
        },
        onError: (error, vars, ctx) => {
            console.error("Simple Propose edit feature failed", error);
        },
        onMutate: useMapCursor(map).cursorWait,
        onSettled: useMapCursor(map).cursorNone
    });
};
export default useSimplePropose