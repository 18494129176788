import { Button, CircularProgress, Grid, Slider, Typography } from "@mui/material";
import { TRoadConfig, TRoadStyle } from "../../../hooks/useOrganization";
import { useEffect, useState } from "react";
import { ControlledColorPicker } from "./ControlledColorPicker";
import { Save } from "@mui/icons-material";
import React from "react";

const SettingsColorPicker: React.FC<{
    style: TRoadStyle,
    onChange: (color: string) => void
}> = (props) => {
    const [color, setColor] = useState<string>(props.style.color);
    return (
        <Grid container>
            <Grid xs={8}>
                {props.style.propertyValue}
            </Grid>
            <Grid xs={4}>
                <ControlledColorPicker
                    color={color}
                    onChange={(color) => {
                        setColor(color)
                        props.onChange(color)
                    }}
                />
            </Grid>
        </Grid>
    )
}

const mapboxMin = 0;
const mapboxMax = 22;
const mapboxZoomLevels = Array.from({ length: mapboxMax - mapboxMin + 1 }, (_, i) => i + mapboxMin)
const marks = mapboxZoomLevels.map((zoom) => {
    return {
        value: zoom,
        label: zoom === 0 || zoom % 2 === 0 ? '' : `${zoom}`
    }
});

type TZoomSliderProps = {
    style: TRoadStyle
    onChange: (zoom: number) => void
}
const ZoomSlider: React.FC<TZoomSliderProps> = (props) => {
    const [sliderValue, setSliderValue] = useState<number>(props.style.minZoom);

    const handleSliderChange = (_: Event, newValue: number | number[]) => {
        if (typeof newValue === 'number') {
            setSliderValue(newValue);
        }
    };

    useEffect(() => {
        props.onChange(sliderValue)
    }, [sliderValue, props])

    return (
        <Slider
            aria-label="Min Zoom"
            value={sliderValue}
            onChange={handleSliderChange}
            valueLabelDisplay="auto"
            valueLabelFormat={(value) => `Min Zoom: ${value}`}
            min={mapboxMin}
            max={mapboxMax}
            marks={marks} />
    )
}

type TSettingsTabProps = {
    currentZoom: number;
    roadConfig?: TRoadConfig
    onInMemoryRoadConfigChange: (roadConfig: TRoadConfig) => void

    isSavingRoadConfig?: boolean
    onSaveRoadConfig: (roadConfig: TRoadConfig) => void
}

export const SettingsTab: React.FC<TSettingsTabProps> = React.memo((props) => {
    const isSavingRoadConfig = props.isSavingRoadConfig === true;
    const [inMemoryRoadConfig, setInMemoryRoadConfig] = useState<TRoadConfig | undefined>(props.roadConfig)

    useEffect(() => {
        if (inMemoryRoadConfig) {
            props.onInMemoryRoadConfigChange(inMemoryRoadConfig)
        }
    }, [inMemoryRoadConfig, props])

    return (
        <div style={{ padding: '0 1rem' }}>
            <Typography variant="body2"><strong>Current Zoom:</strong> {props.currentZoom}</Typography>
            <br />
            <Typography variant="h6">{inMemoryRoadConfig?.layerName}</Typography>
            {inMemoryRoadConfig?.styles.map((style, idx) => {
                return (
                    <div key={`${style.propertyName}_${style.propertyValue}_${style.minZoom}`}>
                        <SettingsColorPicker style={style} onChange={(updatedColor) => {
                            // this is a hack
                            console.log('update color in db', updatedColor)
                            const x = inMemoryRoadConfig?.styles.map((s, i) => {
                                if (i === idx) {
                                    return { ...s, color: updatedColor }
                                }
                                return s
                            }) || [];

                            // this was reseting itself even if nothing had changed, causing an infinite rerender
                            setInMemoryRoadConfig((prev) => {
                                const newConfig = {
                                    layerName: inMemoryRoadConfig?.layerName ?? '',
                                    styles: x
                                };

                                if (prev && prev.layerName === newConfig.layerName && JSON.stringify(prev.styles) === JSON.stringify(newConfig.styles)) {
                                    return prev;
                                }
                                return newConfig;
                            })
                        }} />
                        <ZoomSlider style={style} onChange={(updatedMinZoom) => {
                            // this is a hack
                            const x = inMemoryRoadConfig?.styles.map((s, i) => {
                                if (i === idx) {
                                    return { ...s, minZoom: updatedMinZoom }
                                }
                                return s
                            }) || [];

                            // this was reseting itself even if nothing had changed, causing an infinite rerender
                            setInMemoryRoadConfig((prev) => {
                                const newConfig = {
                                    layerName: prev?.layerName ?? '',
                                    styles: x
                                };

                                if (JSON.stringify(prev) === JSON.stringify(newConfig)) {
                                    return prev;
                                }

                                return newConfig;
                            });
                        }} />
                    </div>
                )
            })}
            <Button disabled={isSavingRoadConfig}
                endIcon={isSavingRoadConfig ? <CircularProgress /> : <Save />}
                onClick={() => {
                    if (inMemoryRoadConfig) {
                        props.onSaveRoadConfig(inMemoryRoadConfig)
                    }
                }}>Save</Button>
        </div>
    );
});
