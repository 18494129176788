import { useCallback, useEffect, useState } from 'react';
import { TOrganizationResult } from './useOrganization';

export const useTemplateStringMap = (organizationResult: TOrganizationResult | Error | undefined, currentOrganizationId: string, fileUploadId: string) => {

    const [templateStringMap, setTemplateStringMap] = useState<Map<string, string>>(new Map());


    const generateTemplateStringMap = useCallback(() => {
        let templateStringMap = new Map<string, string>();
        const _organization = organizationResult && !(organizationResult instanceof Error) && organizationResult.belongs
            ? organizationResult.organizations.find((org) => org.id === currentOrganizationId) : undefined;


        if (!_organization) {
            return templateStringMap
        }

        const fuModelLayers = _organization?.fuModels?.find((fm) => fm.source === fileUploadId)?.fuModelLayers ?? [];
        for (const fuModelLayer of fuModelLayers) {
            const { source, templateString } = fuModelLayer;
            // set template string map values
            if (typeof source === 'string' && typeof templateString === 'string') {
                templateStringMap.set(source, templateString);
            }
        }
        return templateStringMap;
    }, [organizationResult, currentOrganizationId, fileUploadId]);

    useEffect(() => {
        setTemplateStringMap(generateTemplateStringMap());
    }, [generateTemplateStringMap]);

    return { templateStringMap };

};