import { Fragment } from "react";
import { TLayer, TRoadConfig, TRoadLabels } from "../../hooks/useOrganization";
import { Layer, Source } from "react-map-gl";
import { HoverState } from "./HoverState";
import { TLayerSelection } from "./tabs/SelectLayerTab";
import { THoverAdditions } from "../../pages/Map";
import React from "react";

type PublicLayer = {
    name: string
    type: string
    color: string | null
    insertedFeatureCount: number | null
    extent: {
        xMin: number
        yMin: number
        xMax: number
        yMax: number
    }
}

const RoadRangeLayers = React.memo(({
    layer,
    roadLabels,
}: {
    layer: PublicLayer
    roadLabels?: Omit<TRoadLabels, 'fullName'>
}) => {
    if (!roadLabels || layer.name !== roadLabels.layerName) {
        return null;
    }

    console.log("RoadRangeLayers Debug", layer, roadLabels);

    return (<>
        <Layer
            minzoom={roadLabels.zoomSwap}
            source={layer.name}
            source-layer={layer.name}
            type="symbol"
            paint={{
                'text-halo-color': 'white',
                'text-halo-width': 2,
            }}
            layout={{
                'text-field': ['format', ['get', roadLabels.rightFrom], '-', ['get', roadLabels.rightTo]],
                'symbol-placement': 'line-center',
                'text-offset': [0, 2],
                'text-size': 12,
            }}
        />
        <Layer
            minzoom={roadLabels.zoomSwap}
            source={layer.name}
            source-layer={layer.name}
            type="symbol"
            paint={{
                'text-halo-color': 'white',
                'text-halo-width': 2,
            }}
            layout={{
                'text-field': ['format', ['get', roadLabels.leftFrom], '-', ['get', roadLabels.leftTo]],
                'symbol-placement': 'line-center',
                'text-offset': [0, -2],
                'text-size': 12,
            }}
        />
    </>)
});

/**
 * Responsible for rendering the road label symbol layer
 */
const RoadLabelLayer = React.memo(({
    layer,
    roadLabels,
}: {
    layer: PublicLayer
    roadLabels?: Pick<TRoadLabels, 'layerName' | 'fullName' | 'shieldLabel' | 'zoomSwap'>
}) => {
    if (!roadLabels) {
        return null;
    }

    const useRoadLayerForSymbols = layer.name === roadLabels.layerName;
    const useSymbolLayerForSymbols = layer.name === `_symbol_${roadLabels.layerName}`;

    console.log(`RoadLabelLayer Debug: ${JSON.stringify({
        layerName: layer.name,
        roadLabelsLayerName: roadLabels.layerName,
        roadLabelsFullName: roadLabels.fullName,
        roadLabelsShieldLabel: roadLabels.shieldLabel,
        roadLabelsZoomSwap: roadLabels.zoomSwap,
        useRoadLayerForSymbols,
        useSymbolLayerForSymbols,
    })}`)

    return (
        <>
            {/* core road layer for when we're zoomed in */}
            {layer.name === `${roadLabels.layerName}` && (<Layer
                minzoom={roadLabels.zoomSwap}
                source={layer.name}
                source-layer={layer.name}
                type="symbol"
                paint={{
                    'text-halo-color': 'white',
                    'text-halo-width': 2,
                }}
                layout={{
                    'text-field': ['format', ['get', roadLabels.fullName]],
                    'symbol-placement': 'line-center',
                    'text-size': 12,
                }}
            />)}

            {/* symbol layer for when we're zoomed out */}
            {layer.name === `_symbol_${roadLabels.layerName}` && (<Layer
                minzoom={0}
                maxzoom={roadLabels.zoomSwap}
                source={layer.name}
                source-layer={layer.name}
                type="symbol"
                paint={{
                    'text-halo-color': 'white',
                    'text-halo-width': 2,
                }}
                layout={{
                    'icon-image': `us-highway-3`,
                    // 'icon-allow-overlap': true,
                    'icon-rotation-alignment': 'viewport',
                    // 'icon-keep-upright': true,
                    'text-field': ['format', ['get', roadLabels.shieldLabel]],
                    'symbol-placement': 'line',
                    'text-size': 10,
                    // 'text-keep-upright': true,
                    'text-rotation-alignment': 'viewport',
                    // 'text-allow-overlap': true,
                    // 'text-ignore-placement': true,
                    'visibility': 'visible',
                }}
            />)}
        </>
    )
});

export const Layers = React.memo(({
    layers,
    selectedLayers,
    layerUrlFromName,
    layerUrlClassifiedFromName,
    templateStringMap,
    firstSymbolLayerId,
    hoverAdditions,
    roadLabels,
    roadConfig,
}: {
    layers: TLayer[],
    selectedLayers: TLayerSelection[],
    layerUrlFromName: (layerName: string) => string,
    layerUrlClassifiedFromName: (layerName: string) => string,
    templateStringMap: Map<string, string>,
    firstSymbolLayerId?: string,
    hoverAdditions?: THoverAdditions,
    roadLabels?: TRoadLabels,
    roadConfig?: TRoadConfig,
}) => {

    console.log("Layers.tsx Rendered");
    // NOTE: The roadConfig structure may need to change a bit, but this will work for now...
    const roadConfigPropName = roadConfig && Array.isArray(roadConfig.styles) && roadConfig.styles.length > 0
        ? roadConfig.styles[0].propertyName : null;

    const getLayerColor = (layerName: string) => {
        const info = selectedLayers.find(sl => layerName === sl.layerInfo.name)
        const defaultColor = 'rgba(0, 0, 0, 0)';
        if (!info) {
            return defaultColor
        }

        return info?.rgb1()
    }

    const getLayerHeatMapEnd = (layerName: string) => {
        const info = selectedLayers.find(sl => layerName === sl.layerInfo.name)
        if (!info) {
            return 'rgba(0, 0, 0, 0)'

        }

        return info?.rgb1()
    }

    const getLayerHeatMapStart = (layerName: string) => {
        const info = selectedLayers.find(sl => layerName === sl.layerInfo.name)
        if (!info) {
            return 'rgba(0, 0, 0, 0)'
        }

        return info?.rgb0()
    }

    const mapLayers = (
        layers: TLayer[],
    ): any[] => {
        return layers.filter(layer => layer.type.includes('Polygon') || layer.type.includes('Line') || layer.type.includes('Point')).map((layer) => {
            if (layer.type.includes('Polygon')) {
                return {
                    name: layer.name,
                    minzoom: 4,
                    maxzoom: 20,
                    layers: [
                        {
                            suffix: '0',
                            type: 'line',
                            minzoom: 0,
                            maxzoom: 23,
                            paintSelected: {
                                'line-color': ['case', ['boolean', ['feature-state', 'click'], false], 'cyan', getLayerColor(layer.name)],
                                'line-width': ['case', ['boolean', ['feature-state', 'hover'], false], 8, 4],
                            },
                        },
                        {
                            suffix: '1',
                            type: 'fill',
                            minzoom: 0,
                            maxzoom: 23,
                            paintSelected: {
                                'fill-color': ['case', ['boolean', ['feature-state', 'click'], false], 'cyan', getLayerColor(layer.name)],
                                'fill-opacity': ['case', ['boolean', ['feature-state', 'hover'], false], .3, .2],
                            },
                        },
                    ],
                }
            }

            // TODO: Don't show roads until zoomed in further if not configured in styles (RoadClass / ST_CLASS)

            if (layer.type.includes('Line')) {
                // get default color for line layer
                const defaultLineLayerColor = getLayerColor(layer.name);
                // build matchExpression rules from config
                const matchRules = roadConfigPropName ? roadConfig?.styles
                    .filter((s) => s.propertyName === roadConfigPropName)
                    .filter((x) => x.propertyName && x.propertyValue && x.color) // filter out invalid styles
                    .map((s) => [s.propertyValue, s.color])
                    .flat() || [] : []
                // apply line-color expression based on roadConfig
                const lineColorExpression = roadConfigPropName ? [
                    'match', ['get', roadConfigPropName], // ex: 'RoadClass'
                    ...matchRules, // ex: ['Primary', 'yellow', 'Local', 'light grey', ...]
                    defaultLineLayerColor
                ] : [defaultLineLayerColor];
                const lineLayout = {
                    'line-cap': 'round'
                }

                const configuredLineLayers = {
                    name: layer.name,
                    minzoom: 0,
                    maxzoom: 23,
                    cluster: true,
                    // NOTE: I don't understand why we have two layers here. We should try to simplify.
                    layers: [
                        {
                            // this suffix is hardcoded in a lot of spots.. I haven't found any bugs but just something to watch for.
                            suffix: '1',
                            type: 'line',
                            minzoom: 0,
                            maxzoom: 23,
                            paintSelected: {
                                'line-color': [
                                    ...lineColorExpression
                                ],
                                'line-width': [
                                    'interpolate',
                                    ['exponential', 2],
                                    ['zoom'],
                                    10, ["*", 10, ["^", 2, -2]],
                                    24, ["*", 10, ["^", 2, 8]]
                                ],
                            },
                            layout: lineLayout
                        },
                        {
                            // this suffix is hardcoded in a lot of spots.. I haven't found any bugs but just something to watch for.
                            suffix: '2',
                            type: 'line',
                            minzoom: 0,
                            maxzoom: 23,
                            paintSelected: {
                                'line-color': [
                                    'case', ['boolean', ['feature-state', 'click'], false],
                                    'cyan', [
                                        ...lineColorExpression
                                    ]
                                ],
                                'line-width': [
                                    'interpolate',
                                    ['exponential', 2],
                                    ['zoom'],
                                    10, ["*", 8, ["^", 2, -2]],
                                    24, ["*", 8, ["^", 2, 8]]
                                ],
                            },
                            layout: lineLayout
                        },
                    ],
                };
                const nonConfiguredLineLayers = {
                    name: layer.name,
                    minzoom: 0,
                    maxzoom: 23,
                    layers: [
                        // TODO: Does this need uncommented?
                        {
                            suffix: '0',
                            type: 'heatmap',
                            minzoom: 0,
                            maxzoom: 10,
                            paintSelected: {
                                'heatmap-color': ['interpolate', ['linear'], ['heatmap-density'], 0, getLayerHeatMapStart(layer.name), 1, getLayerHeatMapEnd(layer.name)],
                                'heatmap-opacity': ['interpolate', ['linear'], ['zoom'], 16, .5, 17, 0],
                            },
                        },
                        {
                            suffix: '1',
                            type: 'line',
                            minzoom: 9,
                            maxzoom: 23,
                            paintSelected: {
                                'line-color': ['case', ['boolean', ['feature-state', 'click'], false], 'cyan', getLayerColor(layer.name)],
                                'line-width': ['case', ['boolean', ['feature-state', 'hover'], false], 8, 4],
                            },
                            layout: lineLayout
                        },
                    ],
                }
                const layerToRender = roadConfig?.layerName && roadConfig?.layerName === layer.name ? configuredLineLayers : nonConfiguredLineLayers;
                console.log("layerToRender", layerToRender, roadConfig?.layerName);
                return layerToRender;
            }
            return {
                name: layer.name,
                minzoom: 8,
                maxzoom: 20,
                layers: [
                    {
                        suffix: '0',
                        type: 'heatmap',
                        minzoom: 0,
                        maxzoom: 13,
                        paintSelected: {
                            'heatmap-opacity': ['interpolate', ['linear'], ['zoom'], 16, .5, 17, 0],
                            'heatmap-color': ['interpolate', ['linear'], ['heatmap-density'], 0, getLayerHeatMapStart(layer.name), 1, getLayerHeatMapEnd(layer.name)],
                        },
                        layout: {}
                    },
                    {
                        suffix: '1',
                        type: 'circle',
                        minzoom: 13,
                        maxzoom: 23,
                        paintSelected: {
                            'circle-color': ['case', ['boolean', ['feature-state', 'click'], false], 'cyan', getLayerColor(layer.name)],
                            'circle-radius': ['case', ['boolean', ['feature-state', 'hover'], false], 8, 4],
                        },
                        layout: {}
                    },

                ],
            }
        })
    }

    const renderLayers = mapLayers(layers)
    const orderMap: Record<string, { order: number, show: boolean }> = {};
    selectedLayers.forEach((ls) => orderMap[ls.layerInfo.name] = { order: ls.selectionOrder, show: ls.checked })
    return (
        <>
            {
                renderLayers
                    .map((l) => {
                        const sourceId = l.name
                        const layerId = l.name
                        const isPointLayer = l.layers.findIndex((x: any) => x.type === 'heatmap' || x.type === 'circle') > -1;
                        let tileUrl = roadConfig ? layerUrlClassifiedFromName(l.name) : layerUrlFromName(l.name)
                        if (isPointLayer) {
                            tileUrl += '?sample=1000';
                        }

                        return (
                            <Fragment key={l.name}>
                                <Source id={sourceId} type="vector" tiles={[tileUrl]} promoteId="_id">
                                    {/* TODO: refactor so we don't have to render the layer as invisible */}
                                    {l.layers.map((layer: any) => {
                                        return (orderMap[sourceId] && orderMap[sourceId].show ?
                                            <Layer key={`${layerId}-${layer.suffix}`} interactive={true} id={`${layerId}-${layer.suffix}`} beforeId={firstSymbolLayerId} minzoom={layer.minzoom} maxzoom={layer.maxzoom} source-layer={l.name} type={layer.type} paint={layer.paintSelected} layout={layer.layout} />
                                            :
                                            <Layer layout={{ "visibility": 'none' }} key={`${layerId}-${layer.suffix}`} id={`${layerId}-${layer.suffix}`} beforeId={firstSymbolLayerId} minzoom={layer.minzoom} maxzoom={layer.maxzoom} source-layer={l.name} type={layer.type} paint={layer.paintSelected} />
                                        )
                                    })}

                                    {/* Putting these on top (last) of every other layer */}
                                    <RoadRangeLayers layer={l} roadLabels={roadLabels} />
                                    <RoadLabelLayer layer={l} roadLabels={roadLabels} />
                                </Source>
                                {roadLabels?.layerName !== l.name && <HoverState source={sourceId} sourceLayer={l.name} layerId={`${layerId}-1`} templateStrings={templateStringMap} hoverAdditions={hoverAdditions} />}
                            </Fragment>
                        )
                    })}
        </>
    )
})
