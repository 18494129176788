// src/hooks/useSaveFeature.ts
import { useMutation } from 'react-query';
import { saveFeature } from '../../api/editController';
import {useMapCursor} from "./useMapCursor";

const useSaveFeature = (
    map: mapboxgl.Map | undefined,
    onSaveNewFeature: (responseBody: any) => void =  () => {console.debug("useSaveFeature.onSaveNewFeature not utililzed")},
) => {
    return useMutation('save-feature', saveFeature, {
        onSuccess: ({ data: responseBody }) => {
            onSaveNewFeature(responseBody);
        },
        onError: (error, vars, ctx) => {
            console.error("Save feature failed", error);
        },
        onMutate: useMapCursor(map).cursorWait,
        onSettled: useMapCursor(map).cursorNone
    });
};

export default useSaveFeature;
