import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { ReactNode } from "react";

export type TConfirmDialogProps = {
    isOpen: boolean
    title: ReactNode
    content: ReactNode
    cancelBtnContent: ReactNode
    cancelBtnDisabled: boolean
    confirmBtnContent: ReactNode
    confirmBtnDisabled: boolean
    onClose: () => void
    onConfirm: () => void
}

const ConfirmDialog: React.FC<TConfirmDialogProps> = (props) => {

    return (
        <>
            <Dialog
                open={props.isOpen}
                onClose={props.onClose}
            >
                <DialogTitle>{props.title}</DialogTitle>
                <DialogContent>
                    <DialogContentText>{props.content}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.onClose} disabled={props.cancelBtnDisabled}>
                        {props.cancelBtnContent}
                    </Button>
                    <Button
                        onClick={props.onConfirm}
                        color="error"
                        autoFocus
                        disabled={props.confirmBtnDisabled}
                    >
                        {props.confirmBtnContent}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
};

export { ConfirmDialog };
