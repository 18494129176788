import React, { useContext } from 'react';
import { FormControl, Select, MenuItem, SelectChangeEvent, useTheme } from '@mui/material';
import { AppContext } from '../App';
import { useHistory, useLocation, useParams } from 'react-router-dom'


const OrganizationSelector: React.FC<{}> = () => {
    const history = useHistory()
    const location = useLocation()
    const { organizationId } = useParams<{ organizationId?: string }>()
    const { organization } = useContext(AppContext)

    const theme = useTheme();

    if (!organization || organization instanceof Error || !organization.belongs) {
        return <></>
    }

    const handleChange = (event: SelectChangeEvent<string>) => {
        const newOrgId = event.target.value as string

        if (location.pathname.startsWith(`/orgs/${newOrgId}`)) {
            console.log('already on this org')
            return
        }

        console.log(`changing org to ${newOrgId}`)
        localStorage.setItem("current-organization-id", newOrgId)
        history.push(`/orgs/${newOrgId}`)
    }

    return (
        <FormControl>
            <Select
                value={organizationId}
                onChange={handleChange}
                displayEmpty
                variant="outlined"
                style={{ color: theme.palette.primary.main, background: theme.palette.secondary.contrastText }}
            >
                {organization.organizations.map((org, idx) => (
                    <MenuItem key={idx} value={org.id}>
                        {org.auth0DisplayName}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}

export default OrganizationSelector
