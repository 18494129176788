import { useAuth0, User } from '@auth0/auth0-react'
import { createContext, Fragment, useContext, useEffect } from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { Redirect, Route, Switch, useLocation } from 'react-router-dom'
import { PageLoader } from './components/page-loader'
import { ProtectedRoute } from './components/protected-route'
import useOrganization, { TOrganizationResult } from './hooks/useOrganization'
import Dashboard from './pages/Dashboard'
import { FileFeaturesV2 } from './pages/FileFeaturesV2'
import MapPage from './pages/Map'
import ModelsPage from './pages/Models'
import SettingsPage from './pages/Settings'

// for the map?
import 'mapbox-gl/dist/mapbox-gl.css'
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'

import 'react-color'

// https://mui.com/material-ui/react-css-baseline/#global-reset
import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'
import useAccessToken from './hooks/useAccessToken'
import ErrorPage from './pages/ErrorPage'
import { DefaultTheme } from './themes/Default'
import { useGoogleAnalyticsPageViews } from './hooks/useGoogleAnalyticsPageViews'



export const AppContext = createContext<{
    accessToken?: string
    user?: User
    organization?: TOrganizationResult
    currentOrganizationId?: string
    updateCurrentOrganizationId?: (currentOrganizationId: string) => void
}>({})

export const useAppContext = () => useContext(AppContext)

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
        }
    },
})

function App() {
    const { isLoading, user, loginWithRedirect } = useAuth0()
    const location = useLocation()
    const accessToken = useAccessToken()
    const organizationResult = useOrganization(accessToken)
    const isPublicPage = location.pathname.startsWith('/public')

    // TODO: how should the last org id be handled?
    const lsCurrentOrganizationId = localStorage.getItem("current-organization-id");
    const orCurrentOrganizationId = organizationResult && !(organizationResult instanceof Error) && organizationResult.belongs && organizationResult.organizations.length > 0 ? organizationResult.organizations[0].id : undefined;
    const currentOrganizationId = lsCurrentOrganizationId || orCurrentOrganizationId;
    // console.log(`lsCurrentOrganizationId: ${lsCurrentOrganizationId}
    //     orCurrentOrganizationId: ${orCurrentOrganizationId}
    //     currentOrganizationId: ${currentOrganizationId}`)

    // register ga page view events hook
    useGoogleAnalyticsPageViews();

    // Redirect to login if user is not logged in and on the landing page
    useEffect(() => {
        if (!isLoading && typeof user?.sub !== 'string' && typeof loginWithRedirect === 'function' && !isPublicPage) {
            loginWithRedirect()
        }
    }, [isLoading, user, isPublicPage, loginWithRedirect])


    if (!isPublicPage && (isLoading || !accessToken || !organizationResult || organizationResult instanceof Error)) {
        if (organizationResult instanceof Error) {
            console.log(`Error: ${JSON.stringify(organizationResult.message, null, 2)}`)
            return <ErrorPage />
        }
        return (
            <div className="page-layout">
                <PageLoader />
            </div>
        )
    }


    return (
        <Fragment>
            <QueryClientProvider client={queryClient}>
                {(isPublicPage || (!isLoading && typeof user?.sub === 'string')) && (
                    <>
                        <CssBaseline />
                        <AppContext.Provider
                            value={{
                                accessToken: accessToken,
                                user: user,
                                organization: organizationResult instanceof Error ? undefined : organizationResult,
                            }}
                        >
                            <ThemeProvider theme={DefaultTheme}>
                                {/* V1 -- just keeping for context
                                <Switch>
                                    <ProtectedRoute path="/dashboard" component={Dashboard} />
                                    <ProtectedRoute path="/models/:fileUploadId" component={ModelsPage} />
                                    <ProtectedRoute path="/layerTable/:fileUploadId" component={LayerTable} />
                                    <ProtectedRoute path="/file/:fileUploadId" component={FileFeaturesV2} />
                                    <ProtectedRoute path="/map/:fileUploadId" component={MapPage} />
                                    <ProtectedRoute path="/settings" component={SettingsPage} />

                                    <Route path="/public/map/:fileUploadId" component={PublicMapPage} />
                                    <Route path="/public/basemap" component={BaseMapPage} />
                                    <Redirect from="*" to="/dashboard" />
                                </Switch>
                                */}

                                {/* V2 */}
                                <Switch>
                                    <ProtectedRoute path="/orgs/:organizationId/models/:fileUploadId" component={ModelsPage} />
                                    <ProtectedRoute path="/orgs/:organizationId/files/:fileUploadId" component={FileFeaturesV2} />
                                    <ProtectedRoute path="/orgs/:organizationId/maps/:fileUploadId" component={MapPage} />
                                    <ProtectedRoute path="/orgs/:organizationId/settings" component={SettingsPage} />
                                    <ProtectedRoute path="/orgs/:organizationId" component={Dashboard} />
                                    <Route path="/orgs">
                                        <Redirect to={`/orgs/${currentOrganizationId}`} />
                                    </Route>
                                    <Redirect from="*" to="/orgs" />
                                </Switch>
                            </ThemeProvider>
                        </AppContext.Provider>
                    </>
                )}
            </QueryClientProvider>
        </Fragment>
    )
}

export default App
