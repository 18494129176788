import { MapLayerMouseEvent } from 'mapbox-gl';
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { createPortal } from 'react-dom';
import { Feature } from 'geojson';
import { findAndReplaceLayerTemplateString, TSourceFeatureData } from './templateString';


interface MapContextMenuProps {
    map: mapboxgl.Map | undefined;
    enabled: boolean
    templateStringMap: Map<string, string>
    layers: { AddressPoint: string; }
    contextFunctions: {
        addAddressPoint: () => void;
        addCluster: () => void;
        editFeature: (feature: Feature) => void;
        cancel: () => void
    };
}

type MenuItem = {
    id: string
    label: string
    action?: () => void | null | undefined
    submenu: Submenu[]
}

type Submenu = {
    id: string
    label: string
    hoverEffect?: {
        layerName: string
        featureId: string
    }
    action: () => void
}

const MapContextMenu: React.FC<MapContextMenuProps> = ({ map, layers, enabled, templateStringMap, contextFunctions }) => {
    const [visible, setVisible] = useState(false);
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const [activeSubmenu, setActiveSubmenu] = useState<string | null>(null);
    const [menu, setMenu] = useState<MenuItem[]>([])
    const [contextFeatures, setContextFeatures] = useState<Feature[]>([])
    const menuRef = useRef<HTMLDivElement>(null);

    const bufferPoint = (point: mapboxgl.Point, pixelBuffer = 16) => [[point.x - pixelBuffer, point.y - pixelBuffer], [point.x + pixelBuffer, point.y + pixelBuffer]] as [mapboxgl.PointLike, mapboxgl.PointLike]

    const getFeaturesInClickRadius = useCallback((e: MapLayerMouseEvent) => {
        const searchLayers = Object.values(layers).map(ls => `${ls}-1`)
        let features = e.target.queryRenderedFeatures(bufferPoint(e.point), { layers: searchLayers })

        if (features.length === 0) {
            setContextFeatures([])
        } else {
            setContextFeatures(features)
        }

    }, [layers])

    useEffect(() => {


        if (!enabled) {
            const cancelMenuItem: MenuItem = {
                id: 'cancel',
                label: 'Cancel',
                submenu: [],
                action: () => {
                    contextFunctions.cancel()
                }
            }
            setMenu([cancelMenuItem])
        } else {

            const menu = [
                {
                    id: 'add',
                    label: 'Add',
                    submenu: [
                        {
                            id: 'add-address',
                            label: `Add ${layers.AddressPoint}`,
                            action: contextFunctions.addAddressPoint
                        },
                        {
                            id: 'add-cluster',
                            label: `Add Cluster to ${layers.AddressPoint} `,
                            action: contextFunctions.addCluster
                        },
                    ]
                }
            ];

            if (contextFeatures.length > 0) {

                const editMenuItem: MenuItem = {
                    id: 'edit',
                    label: 'Edit',
                    submenu: contextFeatures.map((feature) => {
                        const sourceFeatureData: TSourceFeatureData = {
                            layer: ((feature as any).layer.id).replace(/-\d+$/, ""),
                            properties: feature.properties ?? {}
                        }
                        const label = findAndReplaceLayerTemplateString(templateStringMap, sourceFeatureData)
                        const layerName = ((feature as any).layer.id).replace(/-\d+$/, "")
                        const featureId = feature.id as string
                        return {


                            hoverEffect: {
                                layerName: ((feature as any).layer.id).replace(/-\d+$/, ""),
                                featureId: feature.id as string
                            },
                            id: feature.id as string,
                            // TODO replace
                            label: label,
                            action: () => {
                                map?.setFeatureState({
                                    source: layerName,
                                    sourceLayer: layerName,
                                    id: featureId,
                                }, {
                                    hover: false,
                                })
                                contextFunctions.editFeature(feature)
                            }
                        }
                    })
                }
                menu.push(editMenuItem)

            }
            setMenu(menu)
        }

    }, [contextFeatures, layers, visible, contextFunctions, templateStringMap, enabled, map])

    useEffect(() => {
        if (!map) {
            return;
        }

        const handleContextMenu = (e: MapLayerMouseEvent) => {
            e.preventDefault();
            setVisible(true);
            setPosition({ x: e.point.x, y: e.point.y });
            getFeaturesInClickRadius(e)
            setActiveSubmenu(null)
        };

        // Modified click handler to only close when clicking outside the menu
        const handleOutsideClick = (e: MouseEvent) => {
            // Only close if clicking outside of the menu
            if (menuRef.current && !menuRef.current.contains(e.target as Node)) {
                setVisible(false);
            }
        };

        map.on('contextmenu', handleContextMenu);
        if (visible) {
            document.addEventListener('click', handleOutsideClick);
        }

        return () => {
            map.off('contextmenu', handleContextMenu);
            document.removeEventListener('click', handleOutsideClick);
        };
    }, [map, enabled, visible, getFeaturesInClickRadius]);

    const handleSubmenuClick = (action: () => void, e: React.MouseEvent) => {
        e.stopPropagation();
        action();
        setVisible(false); // Close menu after action
    };

    const handleMenuItemClick = (action: (() => void) | null | undefined, e: React.MouseEvent) => {
        e.stopPropagation();
        if (action) {
            action();
            setVisible(false);
        }

    };


    if (!visible) {
        return null;
    }

    const menuStyles = {
        backgroundColor: 'white',
        border: '1px solid #ccc',
        borderRadius: '4px',
        boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
        padding: '4px 0',
    };

    const menuItemStyles = {
        padding: '4px 12px',
        fontSize: '12px',
        cursor: 'pointer',
        whiteSpace: 'nowrap',
    };

    return createPortal(
        <div
            ref={menuRef}
            className="context-menu"
            style={{
                position: 'fixed',
                left: `${position.x}px`,
                top: `${position.y}px`,
                zIndex: 9999,
                ...menuStyles,
            }}
        >
            <ul style={{ listStyle: 'none', margin: 0, padding: 0, minWidth: 'fit-content' }}>
                {menu.map((item) => (
                    <li
                        key={item.id}
                        style={{
                            ...menuItemStyles,
                            position: 'relative',
                            backgroundColor: activeSubmenu === item.id ? '#f0f0f0' : 'transparent',
                        }}
                        onClick={(e) => handleMenuItemClick(item.action, e)}
                        onMouseEnter={() => setActiveSubmenu(item.id)}
                    >

                        {item.label} {item.submenu.length > 0 && item.submenu && '▶'}

                        {/* Submenu */}
                        {activeSubmenu === item.id && item.submenu && (
                            <ul style={{
                                position: 'absolute',
                                left: '100%',
                                top: '0',
                                zIndex: 10000,
                                listStyle: 'none',
                                margin: 0,
                                minWidth: 'fit-content',
                                ...menuStyles,
                            }}
                            >
                                {item.submenu.map((subItem) => (
                                    <li
                                        key={subItem.id}
                                        style={menuItemStyles}
                                        onClick={(e) => handleSubmenuClick(subItem.action, e)}
                                        onMouseEnter={(e) => {
                                            (e.currentTarget.style.backgroundColor = '#f0f0f0')
                                            if (subItem.hoverEffect) {
                                                map?.setFeatureState({
                                                    source: subItem.hoverEffect.layerName,
                                                    sourceLayer: subItem.hoverEffect.layerName,
                                                    id: subItem.hoverEffect.featureId,
                                                }, {
                                                    hover: true,
                                                })
                                            }
                                        }}
                                        onMouseLeave={(e) => {
                                            (e.currentTarget.style.backgroundColor = '')
                                            if (subItem.hoverEffect) {
                                                map?.setFeatureState({
                                                    source: subItem.hoverEffect.layerName,
                                                    sourceLayer: subItem.hoverEffect.layerName,
                                                    id: subItem.hoverEffect.featureId,
                                                }, {
                                                    hover: false,
                                                })
                                            }
                                        }}
                                    >
                                        {subItem.label}
                                    </li>
                                ))}
                            </ul>
                        )}
                    </li>
                ))}
            </ul>
        </div>,
        document.body
    );
};

export default MapContextMenu;