import { useCallback } from 'react';

export const useLayerUrls = (currentOrganization: any, fileUploadId: string) => {
    const collectionName = (orgAuth0Name: string, fileUploadId: string) => `org${orgAuth0Name}Gdb_${fileUploadId}`;

    const layerUrlClassified = useCallback(
        (collectionName: string, auth0Name: string, layerName: string) =>
            `https://mongeo.azurewebsites.net/classified/${collectionName}/${auth0Name}/layer/${layerName}/{z}/{x}/{y}`,
        []
    );

    const layerUrlClassifiedFromName = useCallback((layerName: string) => {
        if (!currentOrganization) {
            console.warn('currentOrganization is null');
            return '';
        }
        return layerUrlClassified(collectionName(currentOrganization.auth0Name, fileUploadId), currentOrganization.auth0Name, layerName);
    }, [currentOrganization, fileUploadId, layerUrlClassified]);


    const layerUrl = useCallback(
        (collectionName: string, layerName: string) =>
            `https://mongeo.azurewebsites.net/tiles/${collectionName}/layer/${layerName}/{z}/{x}/{y}`,
        []
    );

    const layerUrlFromName = useCallback((layerName: string) => {
        if (!currentOrganization) {
            console.warn('currentOrganization is null');
            return '';
        }
        return layerUrl(collectionName(currentOrganization.auth0Name, fileUploadId), layerName);
    }, [currentOrganization, fileUploadId, layerUrl]);

    return { layerUrlFromName, layerUrlClassifiedFromName };
};