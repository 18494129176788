import { Card, CardContent } from '@mui/material'
import { Container } from '@mui/system'
import { useContext } from 'react'
import Shell from '../layout/Shell'
import { AppContext } from '../App'
import OrganizationFileUploads from '../components/OrganizationFileUploads'
import { useQuery } from 'react-query'
import { useInterval } from '../hooks/useInterval'
import { TOrganizationResult, getOrganizations, TFileUpload, TOrganization } from '../hooks/useOrganization'
import React from 'react';
import { PageLoader } from '../components/page-loader'
import ErrorPage from './ErrorPage'
import { useParams } from 'react-router-dom'

const Dashboard: React.FC = () => {
    const { organizationId } = useParams<{ organizationId: string }>()
    const { accessToken } = useContext(AppContext)

    let pollingInterval = 6 * 1000 // 6 seconds by default

    const { data: organizationResult, refetch: refetchOrganizations } = useQuery<TOrganizationResult | Error>('get-organizations', () =>
        getOrganizations(accessToken!)
    )

    useInterval(() => {
        refetchOrganizations()
    }, pollingInterval)

    const fetchOrganization = (timeout: number) => {
        setTimeout(() => {
            refetchOrganizations()
        }, timeout)
    }

    if (!organizationResult) {
        return (
            <div className="page-layout">
                <PageLoader />
            </div>
        )
    }

    if (organizationResult instanceof Error) {
        return <ErrorPage />
    }

    let currentOrganization: TOrganization | undefined = undefined
    if (organizationResult.belongs) {
        currentOrganization = organizationResult.organizations
            .find((org) => org.id === organizationId)
    }

    function findMostRecentFile(dataList: TFileUpload[]): TFileUpload | null {
        if (dataList.length === 0) {
            return null; // Return null if the list is empty
        }

        return dataList.reduce((mostRecent: TFileUpload | null, currentItem: TFileUpload) => {
            if (!mostRecent || currentItem.createdAt > mostRecent.createdAt) {
                return currentItem;
            }
            return mostRecent;
        }, null);
    }

    const checkFileUploading = () => {

        if (!currentOrganization?.fileUploads) {
            return;
        }

        const latestFileUpload = findMostRecentFile(currentOrganization?.fileUploads)
        if (latestFileUpload && latestFileUpload.status !== 'imported' && latestFileUpload.status !== 'failed') {
            pollingInterval = 3 * 1000 // 3 seconds
        } else {
            pollingInterval = 6 * 1000 // back to 6 seconds
        }
    }

    return (
        <Shell>
            <Container>
                <Card>
                    <CardContent>

                        {(!organizationResult.belongs) && (
                            <div>
                                <p>Thou hast embarked upon a noble journey, the first step toward forging a brighter path in the realm of public safety.</p>
                                <p>May this endeavor lead thee to greater understanding and purpose.</p>
                                <p>To continue thy quest, seek out guidance at <a href="letschat@911geo.com">letschat@911geo.com</a>. May fortune and wisdom accompany thee on this path.</p>
                            </div>
                        )}

                        {typeof accessToken === 'string' && organizationResult?.belongs && currentOrganization && organizationResult
                            && (
                                <>
                                    <OrganizationFileUploads accessToken={accessToken} organizationResult={currentOrganization} fetchOrganization={fetchOrganization} />
                                    {checkFileUploading()}
                                </>
                            )}
                    </CardContent>
                </Card>
            </Container>
        </Shell>
    )
}


export default Dashboard
