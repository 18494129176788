import { useMutation } from "react-query";
import { deleteFeature } from "../../api/editController";
import { useMapCursor } from "./useMapCursor";

const useDeleteFeature = (
    map: mapboxgl.Map | undefined,
    workingLayer: string,
    onDeleteFeature: (layerName: string) => void = () => { console.debug("useDeleteFeature.onDeleteFeature not utilized"); },
    _onCloseDeleteConfirmDialog: () => void
) => {

    return useMutation('delete-feature', deleteFeature, {
        onSuccess: ({ data: responseBody }) => {
            // reset some state
            _onCloseDeleteConfirmDialog();

            // reload the map
            if (map) {
                (map?.getSource(workingLayer) as any).reload();
            }
            onDeleteFeature(workingLayer);
        },
        onError: (error, vars, ctx) => {
            console.error("Feature delete failed", error);
        },
        onMutate: useMapCursor(map).cursorWait,
        onSettled: useMapCursor(map).cursorNone
    });
};

export default useDeleteFeature;
