import { Feature, Geometry, Point } from 'geojson';
import mapboxgl, { Map as MapBox } from "mapbox-gl";
import React, { MutableRefObject, useCallback, useEffect, useState } from "react";
import { useAppContext } from "../App";
import {
    TFishboneProposeResponse,
    TProposeFeatureResponseBody,
} from "../api/editController";
import FeatureInfoGrid, { FeatureFormState, FeatureFormValues, mapFeaturePropertiesToFormFormat } from './FeatureInfoGrid';


import useIncludePointToRoadCenterLineFishbone from '../hooks/feature/useIncludePointToRoadCenterLineFishbone';
import { useProposeAddFeature, useProposeEditFeature } from '../hooks/feature/useProposeFeature';
import useSaveFeature from '../hooks/feature/useSaveFeature';
import useDeleteFeature from '../hooks/feature/useDeteleFeature';
import useUpdateFeature from '../hooks/feature/useUpdateFeature';
import useFishbonePointClick from '../hooks/feature/useFishbonePointClick';
import useFetchFeatureById from '../hooks/feature/useFetchFeatureById';
import useSimplePropose from '../hooks/feature/useSimplePropose'
import { useLayerPropertyNames } from "../hooks/useLayerPropertyNames"
import { TProposalInfo, TSystemNameDictionary } from "../pages/Map";
import { TGenerateClusterResponse } from '../api/editController';
import { findAndReplaceFieldAutoPopulateTemplateString, findAndReplaceLayerTemplateString, TSourceFeatureData } from "./map/templateString";
import MDEditor from "@uiw/react-md-editor";
import { TConfirmDialogProps, ConfirmDialog } from "./ConfirmDialog";
import FeatureControls from "./FeatureControls";
import { List, ListItemButton, ListItemText, Modal, Box, TextField } from '@mui/material';
import { useMapCursor } from '../hooks/feature/useMapCursor';
import MapContextMenu from './map/MapContextMenu';
import useGenerateClusterFeatures from '../hooks/feature/useGenerateClusterFeatures'


type FeatureManageProps = {
    map: MapBox | undefined
    drawControlRef: MutableRefObject<MapboxDraw | undefined>
    organizationId: string | null,
    fileUploadId: string,
    selectedFeatureId: string | null,
    RCLSwitchFeature: { layerName: string, feature: Feature } | null
    proposedMapFeature: { geometry: Geometry; featureId: string; isClusterFeature: boolean } | null
    systemNameDictionary: TSystemNameDictionary | undefined
    fishbonePointLayerName?: string
    templateStringMap: Map<string, string>

    selectFeaturesById: (featureId: string[]) => void
    focusFeatureByGeometry: (geomtry: Geometry, zoom?: number) => void
    onStartAddFeature?: (layerName: string, isClusterAdd: boolean) => void
    onSaveNewFeature?: (feature: any) => void
    onStartUpdateFeature?: (feature: any) => void
    onUpdateFeature?: (feature: any) => void
    onFeatureProposal?: (featureProposeResponse: TFishboneProposeResponse) => void
    onFeatureProposalCancel?: (feature: any) => void
    onDeleteFeature?: (layerName: string) => void
    onClusterGeneration?: (response: TGenerateClusterResponse) => void
}

const FeatureManager: React.FC<FeatureManageProps> = React.memo(
    ({ map, organizationId, fileUploadId, selectedFeatureId, RCLSwitchFeature, proposedMapFeature, systemNameDictionary, fishbonePointLayerName, templateStringMap,
        focusFeatureByGeometry, selectFeaturesById, onStartAddFeature, onSaveNewFeature, onStartUpdateFeature, onUpdateFeature, onFeatureProposal, onFeatureProposalCancel, onDeleteFeature, onClusterGeneration }) => {
        console.log("FeatureManager.tsx Rendered");

        const [formState, setFormState] = useState(FeatureFormState.VIEWING);
        const [selectedFeature, setSelectedFeature] = useState<{ layerName: string, feature: Feature }>()
        const [proposalInfo, setProposalInfo] = useState<TProposalInfo | undefined>(undefined)
        const [workingLayer, setWorkingLayer] = useState<string>('')
        const [clusterUnits, setClusterUnits] = useState<number>()
        const [changedFields, setChangedFields] = useState<Set<string>>(new Set())
        const [formValues, setFormValues] = useState<FeatureFormValues>({ geometry: null, properties: [], })
        const [deleteConfirmState, setDeleteConfirmState] = useState<Omit<TConfirmDialogProps, 'onClose' | 'onConfirm'>>({
            isOpen: false,
            title: 'Delete',
            content: 'Are you sure you want to delete this feature?',
            cancelBtnDisabled: false,
            cancelBtnContent: 'Cancel',
            confirmBtnDisabled: false,
            confirmBtnContent: 'Delete',
        })
        const { accessToken } = useAppContext();

        // TODO: HIT THE SERVER INSTEAD
        const fishbonePointClick = useCallback(
            (e: mapboxgl.MapLayerMouseEvent) => {
                if (!proposalInfo) {
                    return;
                }

                console.log("fishbonePointClick: ");
                console.log(proposalInfo);

                let features = e?.features?.filter((f) => f.layer.type !== 'heatmap');
                if (!Array.isArray(features) || features.length === 0) {
                    return;
                }

                const clickedFishboneFeature = features[0];
                if (!clickedFishboneFeature) {
                    return;
                }

                if (systemNameDictionary) {
                    const layerMap = Object.values(systemNameDictionary).find(
                        (item: { layerName: string }) => item.layerName === 'AddressPoint'
                    )

                    if (!layerMap) {
                        return;
                    }

                    const addNumberProperty = Object.entries(layerMap.fields).find((item) => (item[1] === 'Add_Number' || item[1] === 'HNO'));
                    if (!addNumberProperty) {
                        return;
                    }

                    const modelAddNumberProperty = addNumberProperty[0];

                    if (!clickedFishboneFeature.properties) {
                        return
                    }

                    const newHouseNumber = clickedFishboneFeature.properties['hno'];
                    console.log(`newHouseNumber : ${newHouseNumber}`);

                    if (proposalInfo) {
                        setProposalInfo(prev => {
                            if (!prev) {
                                return prev;
                            }

                            return {
                                ...prev,
                                ...({ properties: proposalInfo.properties.map((prop) => prop.name === modelAddNumberProperty ? { name: prop.name, value: newHouseNumber } : prop) }),
                                ...({ changedProperties: { ...proposalInfo.changedProperties, [modelAddNumberProperty]: newHouseNumber } })
                            }
                        });
                    }
                }
            },
            [proposalInfo, systemNameDictionary]
        );

        const setSelectedFeatureFormValues = useCallback(() => {
            if (!selectedFeature || !selectedFeature.feature || !selectedFeature.feature.properties) {
                setFormValues({ geometry: null, properties: [], })
                return;
            }

            const properties = mapFeaturePropertiesToFormFormat(selectedFeature.feature)
            // need to reset this if the feature changed
            setChangedFields(new Set());
            setFormValues({
                geometry: selectedFeature.feature.geometry,
                properties: properties,
                id: selectedFeature.feature.id,
            });
        }, [selectedFeature])



        const generateAndSetProposalInfo = (addressProposal: TProposeFeatureResponseBody, featureId: string, geometry: Geometry) => {
            console.log(`[DEBUG] Proposal Response: \n${JSON.stringify(addressProposal, null, 2)} `)
            const autoPopulatedProperties = addressProposal.autoPopulatedProperties
            const nearestAPProps = addressProposal.nearestAddressPoint.feature.properties;

            const proposedProperties = nearestAPProps && Object.entries(nearestAPProps).map(([key, value], i) => {
                return { name: key, value: value }
            })

            if (autoPopulatedProperties) {
                Object.keys(autoPopulatedProperties).forEach((autopp: any) => {
                    const pp = proposedProperties.find((pp: any) => pp.name === autopp)
                    pp.value = findAndReplaceFieldAutoPopulateTemplateString(pp.value, proposedProperties)
                });
            }

            setProposalInfo(prev => {
                if (prev == null) {
                    // Handle the case where prev is null or undefined
                    return {
                        featureId: featureId,
                        layerName: addressProposal.featureLayer,
                        geometry: geometry,
                        properties: proposedProperties,
                        changedProperties: addressProposal.changedProperties,
                        propertyToFocus: addressProposal.propertyToFocus
                    };
                }

                return {
                    ...prev,
                    ...((geometry.type !== prev.geometry.type || (prev.geometry as Point).coordinates[0] !== (geometry as Point).coordinates[0] || (prev.geometry as Point).coordinates[1] !== (geometry as Point).coordinates[1]) && { geometry }),
                    ...(proposedProperties !== prev.properties && { properties: proposedProperties }),
                    ...(addressProposal.changedProperties !== prev.changedProperties && { changedProperties: addressProposal.changedProperties })
                    , ...(addressProposal.propertyToFocus !== prev.propertyToFocus && { propertyToFocus: addressProposal.propertyToFocus })
                };
            })

        }

        const _onConfirmDeleteConfirmDialog = async () => {
            const featureId = selectedFeature?.feature?.id as string
            if (!featureId) {
                throw new Error('Unable to delete feature without id!')
            }

            // disable the buttons
            setDeleteConfirmState({
                ...deleteConfirmState,
                cancelBtnDisabled: true,
                confirmBtnDisabled: true,
            })

            if (!accessToken || !organizationId || !fileUploadId) {
                console.error("Does not have data to call updateFeature");
                return;
            }
            // hit server to delete feature
            deleteFeatureMutation({ accessToken, organizationId: organizationId, uploadId: fileUploadId, featureId });


        };

        const onDelete = async () => {
            const featureData: TSourceFeatureData = {
                layer: selectedFeature?.layerName!,
                properties: selectedFeature?.feature?.properties!
            }
            const featureMarkdown = findAndReplaceLayerTemplateString(templateStringMap, featureData)
            setDeleteConfirmState({
                ...deleteConfirmState,
                isOpen: true,
                ...(featureMarkdown !== '' ? {
                    content: <>
                        <p>Are you sure you want to delete this feature?</p>
                        <hr />
                        <MDEditor.Markdown source={featureMarkdown} style={{ whiteSpace: 'pre-wrap' }} />
                    </>
                } : {})
            })
        }

        const _onCloseDeleteConfirmDialog = () => {
            setDeleteConfirmState({
                ...deleteConfirmState,
                content: 'Are you sure you want to delete this feature?',
                isOpen: false,
                cancelBtnDisabled: false,
                confirmBtnDisabled: false,
            })
        };


        useFishbonePointClick(map, fishbonePointLayerName, fishbonePointClick, proposalInfo, systemNameDictionary);
        const { mutate: fetchFeatureMutation } = useFetchFeatureById(map, setSelectedFeature, accessToken as string, organizationId as string, fileUploadId as string);
        const { mutate: updateFeatureMutation, isLoading: isUpdatingFeature } = useUpdateFeature(map, fetchFeatureMutation, onUpdateFeature);
        const { mutate: deleteFeatureMutation, isLoading: isDeleting } = useDeleteFeature(map, workingLayer, onDeleteFeature, _onCloseDeleteConfirmDialog);
        const { mutate: saveFeatureMutation, isLoading: isSavingFeature } = useSaveFeature(map, onSaveNewFeature);
        const { mutate: proposeAddFeatureMutation, isLoading: isAddingProposing } = useProposeAddFeature(map, generateAndSetProposalInfo, onFeatureProposal);
        const { mutate: proposeEditFeatureMutation, isLoading: isEditingProposing } = useProposeEditFeature(map, generateAndSetProposalInfo, onFeatureProposal);
        const { mutate: simplePropose, isLoading: isProposing } = useSimplePropose(map, (response: TFishboneProposeResponse) => {
            generateAndSetProposalInfo(response.featureProposal, response.featureProposal.featureId, response.featureProposal.geometry)

        });

        const { mutate: includePointToRoadCenterLineFishboneMutation } = useIncludePointToRoadCenterLineFishbone(map, generateAndSetProposalInfo, onFeatureProposal);
        let addressPointLayer: string = ""
        let layers: any = {}
        if (systemNameDictionary) {
            addressPointLayer = Object.keys(systemNameDictionary).find(key => systemNameDictionary[key].layerName === 'AddressPoint') ?? "";
            layers['AddressPoint'] = addressPointLayer as string
        }

        const layerPropertyNames = useLayerPropertyNames({
            accessToken: accessToken,
            organizationId: organizationId,
            fileUploadId: fileUploadId,
            layerName: addressPointLayer
        })

        const { mutate: generateClusterFeatures } = useGenerateClusterFeatures(map, (response) => {

            setFormState(FeatureFormState.VIEWING)
            if (onClusterGeneration) {
                onClusterGeneration(response)
            }
        },
        )
        const { cursorDisabled: disableCursor, cursorNone: enableCursor } = useMapCursor(map);

        const [addClusterModel, setAddClusterModel] = useState<{ name: string; value: any; }[] | null>();
        const onClusterModalClose = useCallback(() => {
            setAddClusterModel(null)
            setClusterUnits(undefined)
            if (!proposedMapFeature || !accessToken || !organizationId || !addClusterModel || !clusterUnits) {
                return;
            }

            generateClusterFeatures({ accessToken, organizationId: organizationId, uploadId: fileUploadId, geometry: proposedMapFeature.geometry, properties: addClusterModel, units: clusterUnits })
        }, [proposedMapFeature, accessToken, organizationId, fileUploadId, generateClusterFeatures, addClusterModel, clusterUnits])

        // proposeFeatureId useEffect
        // calls to the server for proposalInfo
        useEffect(() => {
            if (!map || !proposedMapFeature?.featureId || !accessToken || !organizationId) {
                return;
            }

            if (formState === FeatureFormState.EDITING && proposedMapFeature.isClusterFeature) {
                simplePropose({ accessToken, organizationId: organizationId, uploadId: fileUploadId, geometry: proposedMapFeature.geometry, featureId: proposedMapFeature?.featureId })
            } else if (formState === FeatureFormState.EDITING) {
                proposeEditFeatureMutation({ accessToken, organizationId: organizationId, uploadId: fileUploadId, geometry: proposedMapFeature.geometry, featureId: proposedMapFeature?.featureId })
            } else if (formState === FeatureFormState.ADDING) {

                if (proposedMapFeature.geometry.type === 'Polygon') {
                    let newClusterModels: { name: string, value: any }[] = []
                    layerPropertyNames.layerProperties.forEach(lp => {
                        newClusterModels.push({ name: lp, value: '' })
                    })
                    setAddClusterModel(newClusterModels)
                } else {
                    proposeAddFeatureMutation({ accessToken, organizationId: organizationId, uploadId: fileUploadId, geometry: proposedMapFeature.geometry, featureId: proposedMapFeature?.featureId })
                }

            }

            // this should NOT rerender formState changes
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [accessToken, fileUploadId, map, organizationId, proposeEditFeatureMutation, proposeAddFeatureMutation, proposedMapFeature?.featureId, proposedMapFeature?.geometry, proposedMapFeature?.isClusterFeature]);

        // feature useEffect
        // sets the feature data to the form
        useEffect(() => {
            if (
                selectedFeature &&
                (
                    !selectedFeature?.feature.id ||
                    selectedFeature.feature.geometry.type !== 'LineString' ||
                    proposalInfo?.geometry.type !== 'Point'
                )
            ) {
                setProposalInfo(undefined);
                setFormState((prev) => {
                    if (prev !== FeatureFormState.EDITING) {
                        return FeatureFormState.VIEWING
                    }
                    return prev
                });
                setSelectedFeatureFormValues();
            }
            // this should NOT rerender when the proposalInfo changes
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [selectedFeature, setSelectedFeatureFormValues]);

        // useEffect for handleing RCL swithcing on click
        useEffect(() => {
            if (
                accessToken &&
                organizationId &&
                fileUploadId &&
                RCLSwitchFeature?.feature.id &&
                proposalInfo?.geometry.type === 'Point'
            ) {
                setChangedFields(new Set());
                includePointToRoadCenterLineFishboneMutation({
                    accessToken,
                    organizationId,
                    uploadId: fileUploadId,
                    point: proposalInfo.geometry as Point,
                    proposedFeatureId: proposalInfo.featureId,
                    rclFeatureId: RCLSwitchFeature?.feature.id as string,
                });
            }
        }, [accessToken, fileUploadId, includePointToRoadCenterLineFishboneMutation, organizationId, proposalInfo?.featureId, proposalInfo?.geometry, RCLSwitchFeature?.feature.id]);

        // featureId useEffect
        // fetches then sets the feature
        useEffect(() => {
            if (!selectedFeatureId) {
                return
            }

            fetchFeatureMutation({ featureId: selectedFeatureId as string })
        }, [selectedFeatureId, fetchFeatureMutation])

        // proposalInfo useEffect
        // proposalInfo will override everything the formValues property
        useEffect(() => {
            if (proposalInfo) {
                let proposedProps: { name: string, value: string }[] = [];


                proposalInfo.properties.forEach((prop: { [key: string]: any; }) => {
                    proposedProps.push({
                        name: prop.name,
                        value: prop.value
                    })
                })

                setFormValues(prev => {

                    setChangedFields(prevSet => {
                        const updatedSet = new Set(prevSet);
                        proposedProps.forEach((property: { name: string, value: string }) => {
                            const changedProperty = prev.properties.find((curValue: {
                                value: unknown; name: string
                            }) => {
                                return curValue.name === property.name && curValue.value !== property.value;
                            })

                            if (changedProperty) {
                                updatedSet.add(changedProperty.name)
                            }

                        })
                        if (proposalInfo.changedProperties) {
                            Object.entries(proposalInfo.changedProperties).forEach(([key, value]) => {
                                updatedSet.add(key)
                            });
                        }

                        return updatedSet;
                    })
                    console.info("FeatureManager.tsx: formValues", proposalInfo.geometry)

                    return {
                        properties: proposedProps,
                        geometry: proposalInfo.geometry,
                        id: prev.id,
                    }
                });
            }

        }, [proposalInfo, workingLayer])


        const onEditSave = useCallback(() => {
            if (formState === FeatureFormState.EDITING) {
                const feature: Feature = {
                    type: "Feature",
                    geometry: formValues.geometry,
                    properties: formValues.properties.reduce((a: any, v: any) => ({ ...a, [v.name]: v.value }), {})
                }

                if (!accessToken || !organizationId || !fileUploadId || !proposalInfo?.featureId) {
                    console.error("Does not have data to call updateFeature");
                    return;
                }
                setChangedFields(new Set());

                updateFeatureMutation({
                    accessToken: accessToken,
                    organizationId: organizationId,
                    uploadId: fileUploadId,
                    featureId: proposalInfo?.featureId,
                    feature: feature
                })

            } else {
                console.error("Tried to Edit save, with form in the wrong state")
            }
            setFormState(FeatureFormState.VIEWING)
            setChangedFields(new Set());

        }, [accessToken, fileUploadId, formState, formValues.geometry, formValues.properties, organizationId, proposalInfo, updateFeatureMutation])

        const onAddingSave = useCallback(() => {
            if (formState === FeatureFormState.ADDING) {
                const feature: Feature = {
                    type: "Feature",
                    geometry: formValues.geometry,
                    properties: formValues.properties.reduce((a: any, v: any) => ({ ...a, [v.name]: v.value }), {})
                }
                saveFeatureMutation({
                    accessToken: accessToken,
                    organizationId: organizationId,
                    uploadId: fileUploadId,
                    layerName: workingLayer,
                    feature: feature
                })
                setChangedFields(new Set());
            } else {
                console.error("Tried to Add save, with form in the wrong state")
            }
            setFormState(FeatureFormState.VIEWING)
            setChangedFields(new Set());
        }, [accessToken, fileUploadId, formState, formValues.geometry, formValues.properties, organizationId, saveFeatureMutation, workingLayer])

        const onAdd = (layerToAdd: string, isClusterAdd: boolean) => {
            if (onStartAddFeature) {
                onStartAddFeature(layerToAdd, isClusterAdd)
            }
            setSelectedFeature(undefined)
            setProposalInfo(undefined)
            setFormState(FeatureFormState.ADDING)
        }


        const beginEdit = useCallback((feature: Feature) => {
            setFormState(FeatureFormState.EDITING)
            if (onStartUpdateFeature) {
                onStartUpdateFeature(feature)
            }
        }, [onStartUpdateFeature])

        const onMove = useCallback(() => {
            if (selectedFeature?.feature) {
                beginEdit(selectedFeature.feature)
            }
        }, [selectedFeature?.feature, beginEdit])

        const onEditCancel = () => {
            enableCursor()
            setSelectedFeatureFormValues();
            setProposalInfo(undefined)
            setFormState(FeatureFormState.VIEWING)
            if (onFeatureProposalCancel) {
                onFeatureProposalCancel(selectedFeature?.feature);
            }
        }

        const onAddCancel = () => {
            enableCursor()
            setShowAddLayerOptions(false)
            setFormState(FeatureFormState.VIEWING)
            setProposalInfo(undefined)
            setFormValues({ geometry: null, properties: [] })
            if (onFeatureProposalCancel) {
                onFeatureProposalCancel(selectedFeature?.feature);
            }
        }

        const handleFieldChange = useCallback((event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, propertyIndex: number) => {
            if (formState !== FeatureFormState.ADDING && formState !== FeatureFormState.EDITING) {
                setFormState(FeatureFormState.EDITING)
            }

            let property = formValues.properties[propertyIndex]
            setChangedFields(prev => {
                let newSet = new Set(prev)
                newSet.add(property.name)
                return newSet
            })
            formValues.properties[propertyIndex].value = event.target.value
            setFormValues(formValues)
        }, [formState, formValues])

        const handleClusterModalFieldChange = useCallback((event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, propertyIndex: number) => {
            if (!addClusterModel) {
                return;
            }

            setAddClusterModel(prev => {

                if (!prev) {
                    return;
                }

                return prev.map((item, index) =>
                    index === propertyIndex ? { ...item, value: event.target.value } : item
                )

            })
        }, [addClusterModel])

        const [showAddLayerOptions, setShowAddLayerOptions] = useState(false)

        const onAddLayer = (layerName: string, isClusterAdd: boolean) => {
            enableCursor()
            onAdd(layerName, isClusterAdd)
            setWorkingLayer(layerName)
            setShowAddLayerOptions(false)
            setFormValues({ geometry: null, properties: [] })
        }

        const prepareToAdd = () => {
            setFormState(FeatureFormState.ADDING)
            setFormValues({ geometry: null, properties: [] })
            disableCursor()
            setShowAddLayerOptions(!showAddLayerOptions)
        }

        return (
            <React.Fragment >
                <>
                    <FeatureControls
                        formState={formState}
                        selectedFeature={selectedFeature}
                        isLoading={isDeleting || isSavingFeature || isAddingProposing || isEditingProposing || isUpdatingFeature || isProposing}
                        geometry={formValues.geometry}
                        onDelete={onDelete}
                        onAdd={() => {
                            prepareToAdd()
                        }}
                        onMove={onMove}
                        onEditCancel={onEditCancel}
                        onEditSave={onEditSave}
                        onAddCancel={onAddCancel}
                        onAddingSave={onAddingSave}
                        focusFeatureByGeometry={focusFeatureByGeometry} />
                    {showAddLayerOptions && <List>

                        {systemNameDictionary && Object.keys(systemNameDictionary)
                            // TODO: REMOVE FILTER FOR WORKING WITH NON-ADDRESS POINT LAYERS
                            .filter(key => key === addressPointLayer)
                            .map((item, index) => (
                                <ListItemButton
                                    sx={{
                                        bgcolor: "transparent",
                                        color: "text.primary",
                                        borderRadius: 2,
                                        border: "1px solid #ddd",
                                        transition: "all 0.2s ease-in-out",
                                        "&:hover": {
                                            bgcolor: "primary.light",
                                            transform: "scale(1.02)",
                                        },
                                    }}
                                    key={index} onClick={() => {
                                        onAddLayer(addressPointLayer, false)
                                    }}>
                                    <ListItemText primary={item} />
                                </ListItemButton>
                            ))}
                    </List>}
                    <FeatureInfoGrid
                        formState={formState}
                        formValues={formValues}
                        changedFields={changedFields}
                        handleFieldChange={handleFieldChange}
                    />
                </>
                {/* BEGIN - DELETE FEATURE STUFF */}
                <ConfirmDialog {...deleteConfirmState} onClose={_onCloseDeleteConfirmDialog} onConfirm={_onConfirmDeleteConfirmDialog} />
                {/* END - DELETE FEATURE STUFF */}
                <MapContextMenu
                    enabled={formState === FeatureFormState.VIEWING}
                    templateStringMap={templateStringMap}
                    map={map}
                    layers={layers}
                    contextFunctions={{
                        addAddressPoint: () => {
                            prepareToAdd()
                            onAddLayer(addressPointLayer, false)
                        },
                        editFeature: (feature: Feature) => {
                            const layerName = (feature as any).layer.id?.replace(/-\d+$/, "") ?? "";
                            setSelectedFeature({ layerName: layerName, feature: feature })
                            beginEdit(feature)
                        },
                        addCluster: () => {
                            prepareToAdd()
                            onAddLayer(addressPointLayer, true)
                        },
                        cancel: formState === FeatureFormState.ADDING ? onAddCancel : onEditCancel,
                    }} />
                {addClusterModel &&
                    <Modal open={true} onClose={onClusterModalClose}>
                        <Box
                            sx={{
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                                bgcolor: "background.paper",
                                boxShadow: 24,
                                p: 3,
                                borderRadius: 2,
                                minWidth: 600, // Adjust width
                                maxWidth: 800, // Prevents it from being too wide
                                maxHeight: "80vh", // Limits height to 80% of viewport height
                                overflowY: "auto", // Enables scrolling if content overflows
                            }}
                        >
                            <TextField disabled={formState === FeatureFormState.VIEWING} label={'Total Units'}
                                InputLabelProps={{
                                    shrink: true,  // Forces the label to stay above the input field
                                }}
                                type='number'
                                required
                                variant="outlined"
                                size="small"
                                margin="dense"
                                sx={{
                                    width: '100%',
                                    '& .MuiInputBase-root.Mui-disabled': {
                                        backgroundColor: '#e0e0e0',
                                        color: 'black',
                                    },
                                    '& .MuiInputLabel-root.Mui-disabled': {
                                        color: 'black',
                                    },
                                    '& .MuiOutlinedInput-input.Mui-disabled': {
                                        '-webkit-text-fill-color': 'black'
                                    }
                                }} onChange={(event) => setClusterUnits(Number(event.currentTarget.value))} value={clusterUnits} />
                            <FeatureInfoGrid
                                formState={FeatureFormState.EDITING}
                                formValues={{
                                    properties: addClusterModel
                                }}
                                handleFieldChange={handleClusterModalFieldChange}
                            />
                        </Box>
                    </Modal>
                }
            </React.Fragment>
        );
    })

export default FeatureManager;
