// src/hooks/useFishbonePointClick.ts
import { useEffect } from 'react';
import mapboxgl from 'mapbox-gl';


const useFishbonePointClick = (
    map: mapboxgl.Map | undefined,
    fishbonePointLayerName: string | undefined,
    fishbonePointClick: (e: mapboxgl.MapLayerMouseEvent) => void,
    proposalInfo: any,
    systemNameDictionary: any
) => {
    useEffect(() => {
        if (map && proposalInfo && systemNameDictionary && fishbonePointLayerName) {
            map.on('click', fishbonePointLayerName, fishbonePointClick);
        }

        return () => {
            if (map && fishbonePointLayerName) {
                map.off('click', fishbonePointLayerName, fishbonePointClick);
            }
        };
    }, [fishbonePointLayerName, fishbonePointClick, map, proposalInfo, systemNameDictionary]);
};

export default useFishbonePointClick;
