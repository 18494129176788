import { Grid } from "@mui/material";
import ReactMap, { Layer, MapRef, Source, ViewState } from 'react-map-gl'
import { Feature, LineString, GeoJsonProperties, Geometry, FeatureCollection } from 'geojson';

import { TUploadedFeature } from "../api/editController";
import { useCallback, useEffect, useRef, useState } from "react";
import mapboxgl from "mapbox-gl";
import * as turf from "@turf/turf";
import { DEFAULT_MAP_STYLE } from "../common";
import FeatureInfoGrid, { FeatureFormState } from "./FeatureInfoGrid";

type ValidationMapGridProps = {
    features: TUploadedFeature[]
}

export const ValidationMapGrid: React.FC<ValidationMapGridProps> = (props) => {
    const defaultZoom = 15
    const roadTables = props.features.map((f) => {
        const keys = Object.keys(f.feature.properties);
        return {
            id: f.id,
            properties: keys.map((k) => {
                return {
                    name: k,
                    value: f.feature.properties[k]
                }
            })
        }
    });
    const gridParentColumnStyles = {
        height: 'calc(100vh - 6.5rem)',
    }

    const mapRef = useRef<MapRef>(null)
    const [mapRefLoaded, setMapRefLoaded] = useState(false)
    const [mapStyle] = useState<string>(DEFAULT_MAP_STYLE)
    const [viewState, setViewState] = useState<ViewState>({
        // The Woodlands in Gladstone, MO
        latitude: 39.205867,
        longitude: -94.544264,
        zoom: 3.5,
        bearing: 0,
        pitch: 0,
        padding: { bottom: 0, top: 0, right: 0, left: 0 }
    })
    const [selectedFeature, setSelectedFeature] = useState<TUploadedFeature>()
    const [validationsFeatureCollection, setValidationsFeatureCollection] = useState<FeatureCollection<Geometry, GeoJsonProperties>>()
    const [selectedValidationFeatureCollection, setSelectedValidationFeatureCollection] = useState<FeatureCollection<Geometry, GeoJsonProperties>>()

    const focusFeatureByGeometry = useCallback((geometry: Geometry, zoom: number = 10) => {
        let bboxResult = turf.bbox(geometry)
        let xMin = bboxResult[0]
        let xMax = bboxResult[2]
        let yMin = bboxResult[1]
        let yMax = bboxResult[3]
        focusFeatureByXYCoordinates(xMin, xMax, yMin, yMax, zoom)
    }, [])

    const focusFeatureByXYCoordinates = (xMin: number, xMax: number, yMin: number, yMax: number, zoom: number = 10) => {
        console.log('focusing...')
        const bounds = new mapboxgl.LngLatBounds(
            new mapboxgl.LngLat(xMin, yMin),
            new mapboxgl.LngLat(xMax, yMax),
        )

        try {
            mapRef?.current?.fitBounds(bounds, { zoom: zoom, duration: 2000 })
        } catch (e) {
            console.log(e)
        }
    }
    const bufferPoint = (point: mapboxgl.Point, pixelBuffer = 8) => [[point.x - pixelBuffer, point.y - pixelBuffer], [point.x + pixelBuffer, point.y + pixelBuffer]] as [mapboxgl.PointLike, mapboxgl.PointLike]

    // handle the click event on the map and updated the selected feature
    const onMapClick = (e: mapboxgl.MapLayerMouseEvent) => {
        const featuresAroundClick = e.target.queryRenderedFeatures(bufferPoint(e.point))
        const clickedMapFeature = featuresAroundClick.length > 0 ? featuresAroundClick[0] : null
        const clickedUploadedFeature = props.features.find(x => x.id === (clickedMapFeature?.id ?? clickedMapFeature?.properties?._id))
        if (!clickedUploadedFeature) {
            return
        }
        setSelectedFeature(clickedUploadedFeature)
    };

    // set the iniital selected feature
    useEffect(() => {
        if (!mapRefLoaded
            || (props.features?.length ?? 0) === 0) {
            return;
        }
        setSelectedFeature(props.features[0])
    }, [props.features, mapRefLoaded, setSelectedFeature, focusFeatureByGeometry])

    // set the feature collections as the selected feature changes
    useEffect(() => {
        if (!selectedFeature) {
            return;
        }

        const selectedValidationLineFeature: Feature<LineString, GeoJsonProperties> = {
            id: selectedFeature.id,
            type: 'Feature',
            properties: selectedFeature.feature.properties,
            geometry: selectedFeature.feature.geometry
        }
        const _selectedValidationFeatureCollection: FeatureCollection<Geometry, GeoJsonProperties> = {
            type: 'FeatureCollection',
            features: [selectedValidationLineFeature]
        }
        const _validationsFeatureCollection: FeatureCollection<Geometry, GeoJsonProperties> = {
            type: 'FeatureCollection',
            features: [
                ...(props.features.filter(x => x.id !== selectedFeature.id).map((feature) => {
                    const line: Feature<LineString, GeoJsonProperties> = {
                        id: feature.id, // Why the fuck is this not present in the onClick?
                        type: 'Feature',
                        properties: {
                            _id: feature.id, // The question above is why this exists
                            ...feature.feature.properties
                        },
                        geometry: feature.feature.geometry
                    }
                    return line;
                }))
            ]
        }
        setSelectedValidationFeatureCollection(_selectedValidationFeatureCollection)
        setValidationsFeatureCollection(_validationsFeatureCollection)
        focusFeatureByGeometry(selectedFeature.feature.geometry, defaultZoom)
    }, [selectedFeature, props.features, focusFeatureByGeometry])


    return (

        <Grid container spacing={0}>
            <Grid item xs={5}>
                <ReactMap
                    ref={mapRef}
                    onLoad={() => setMapRefLoaded(true)}
                    mapStyle={mapStyle}
                    style={gridParentColumnStyles}
                    onMove={e => setViewState(e.viewState)}
                    onClick={onMapClick}
                    {...viewState}>
                    {validationsFeatureCollection && <Source id="validations-source"
                        type="geojson"
                        data={validationsFeatureCollection}>
                        <Layer id="validations-layer"
                            type="line"
                            paint={{ 'line-color': 'orange', 'line-width': 4 }} />
                    </Source>}
                    {selectedValidationFeatureCollection && <Source id="selected-validation-source"
                        type="geojson"
                        data={selectedValidationFeatureCollection}>
                        <Layer id="selected-validation-layer"
                            type="line"
                            paint={{ 'line-color': 'cyan', 'line-width': 4 }} />
                    </Source>}
                </ReactMap>
            </Grid>
            <Grid item xs={7} style={gridParentColumnStyles}>
                <Grid container spacing={0}>
                    {roadTables.map((featureTable, idx) => (
                        <Grid
                            key={idx}
                            item
                            xs={6}
                            onClick={() => {
                                setSelectedFeature(props.features.find((x) => x.id === featureTable.id));
                            }}

                        >
                            <div
                                style={{
                                    ...gridParentColumnStyles,
                                    overflowY: 'auto',
                                    border: '1px solid #ccc',
                                    padding: '8px',
                                    backgroundColor: selectedFeature?.id === featureTable.id ? '#ccc' : undefined,
                                    cursor: 'pointer'
                                }}
                            >
                                <FeatureInfoGrid
                                    formState={FeatureFormState.VIEWING}
                                    formValues={{
                                        properties: featureTable.properties,
                                        id: featureTable.id,
                                    }}
                                />
                            </div>
                        </Grid>
                    ))}
                </Grid>
            </Grid>
        </Grid >
        // </div>
    );
};
