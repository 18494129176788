import { DataGridPro, GridColDef, GridColumnHeaderParams, GridDensity, GridFilterModel, GridPaginationModel, GridRowSelectionModel, GridSortModel, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarExport, GridToolbarFilterButton, useGridApiRef } from "@mui/x-data-grid-pro"
import { TFileUpload, TOrganization } from "../hooks/useOrganization"
import { getFeatureById, getFeaturesForGrid, getFeatureValidationsByLayerName } from "../api/featureController"
import { useCallback, useEffect, useMemo, useState } from "react"
import { Box, Button, FormControl, IconButton, InputLabel, MenuItem, Modal, Select, SelectChangeEvent, Typography } from "@mui/material"
import CloseIcon from '@mui/icons-material/Close';
import MapIcon from '@mui/icons-material/Map'
import { useLayerPropertyNames } from "../hooks/useLayerPropertyNames"
import { useQuery } from "react-query"
import { TValidation } from "../api/validationsController"
import { TUploadedFeature } from "../api/editController"
import { ValidationMapGrid } from "./ValidationMapGrid"
import { Link } from "react-router-dom"
import Shell from "../layout/Shell"

type TFeatureTableV2Props = { accessToken: string, organization: TOrganization, fileUpload: TFileUpload }

type TFeatureRow = {
    id: string
    Layer: string
    [key: string]: unknown // properties
    validations?: TValidation[]
}

const COLUMN_HEADER_HEIGHT = 90
const DEFAULT_COLUMN_WIDTH = 90

const modalBoxStyles = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    height: 'calc(100% - 4rem)',
    width: 'calc(100% - 2rem)',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    padding: '0 1rem 1rem 1rem',
};

export const FeatureTableV2: React.FC<TFeatureTableV2Props> = ({ accessToken, organization, fileUpload }) => {
    const apiRef = useGridApiRef();
    const [layer, setLayer] = useState(fileUpload.layers[0])
    const [validationRuleFilter, setValidationRuleFilter] = useState<string>()
    const layerPropertyNames = useLayerPropertyNames({
        accessToken,
        organizationId: organization.id,
        fileUploadId: fileUpload.uploadId,
        layerName: layer.name
    })
    const { data: featureValidationsResponse, isLoading: isLoadingFeatureValidations } = useQuery(['get-feature-validations-by-layername', layer.name], () => {
        if (!accessToken || !organization.id) {
            return
        }
        return getFeatureValidationsByLayerName(accessToken, layer.name, organization.id, fileUpload.uploadId)
    })
    const handleLayerChange = useCallback((event: SelectChangeEvent<string>) => {
        console.log('handleLayerChange')
        const newValue = event.target.value as string
        const layer = fileUpload.layers.find(layer => layer.name === newValue)
        setLayer(layer!)
    }, [fileUpload]);
    const handleValidationRuleFilterChange = (event: SelectChangeEvent<string>) => {
        const newValue = event.target.value as string
        setValidationRuleFilter(newValue)
    }

    const [selectedRule, setSelectedRule] = useState<string | undefined>()
    const [isFetchingDynamicValidationRows, setIsFetchingDynamicValidationRows] = useState<boolean>(false)
    const [dynamicValidationRows, setDynamicValidationRows] = useState<TUploadedFeature[]>()
    const handleCloseValidationModal = () => {
        console.log('handleCloseValidationModal')
        setDynamicValidationRows(undefined)
    }

    const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([])
    const handleValidationRuleClick = useCallback(async (_e: React.MouseEvent<HTMLButtonElement, MouseEvent>, feature: TFeatureRow, validation: TValidation) => {
        const { featureId, ...restOfFeature } = feature
        // will this work?
        setRowSelectionModel([{
            id: featureId,
            model: restOfFeature
        } as any])
        setSelectedRule(validation.rule)
        setIsFetchingDynamicValidationRows(true)

        try {
            console.log(`Feature: ${JSON.stringify(feature, null, 2)}`)
            const featureIdsToFetch: string[] = [feature.id]
            validation.relatedFeatureIds.forEach((id) => {
                featureIdsToFetch.push(id)
            })
            const fetchRequests = featureIdsToFetch.map((id) => getFeatureById(accessToken!, organization.id, fileUpload.uploadId, id))
            const featureResponses = await Promise.all(fetchRequests)

            const uploadedFeatures: TUploadedFeature[] = featureResponses.map((response) => {
                return response.data
            });
            setDynamicValidationRows(uploadedFeatures)
        }
        catch (error) {
            console.error(error)
        }
        finally {
            setIsFetchingDynamicValidationRows(false)
        }
    }, [accessToken, organization.id, fileUpload.uploadId])

    const [keepColumnsConfig, setKeepColumnConfigs] = useState<boolean>()
    const columns: GridColDef[] = useMemo(() => {
        if (keepColumnsConfig) {
            return apiRef.current?.getAllColumns();
        }

        console.log('columns useMemo fired', organization.id, layer, fileUpload, layerPropertyNames)
        const pinnedColumns: GridColDef[] = [
            {
                field: "map",
                headerName: "Map",
                filterable: false,
                hideable: false,
                sortable: false,
                disableColumnMenu: true,
                renderCell: (params) => {
                    const featureRow: TFeatureRow = params.row;
                    const mapUrl = `/orgs/${organization.id}/maps/${fileUpload.uploadId}?layerName=${layer.name}&featureId=${featureRow.id}`
                    return (
                        <Link
                            to={mapUrl}
                            component={IconButton}
                            onClick={(e) => e.stopPropagation()}
                        >
                            <MapIcon />

                        </Link>
                    )
                },
                minWidth: 50,
                width: 50
            },
        ]

        const hasValidationRules = featureValidationsResponse
            && featureValidationsResponse.data
            && Array.isArray(featureValidationsResponse.data.validationRules)
            && featureValidationsResponse.data.validationRules.length > 0;
        if (hasValidationRules) {
            const validationsColumn: GridColDef = {
                field: "validations",
                headerName: "Validations",
                filterable: false,
                sortable: false,
                disableColumnMenu: true,
                renderHeader: (_: GridColumnHeaderParams) => {
                    if (!featureValidationsResponse
                        || !featureValidationsResponse.data
                        || !Array.isArray(featureValidationsResponse.data.validationRules)
                        || featureValidationsResponse.data.validationRules.length === 0) {
                        return null
                    }
                    return (
                        <Box display="flex"
                            height={'100%'}
                            width={'100%'}>
                            <FormControl sx={{ height: '100%', width: '100%', minWidth: 120, marginTop: '8px' }}>
                                <InputLabel id="validation-filter-label" size="small">Validation Rule</InputLabel>
                                <Select
                                    disabled={isLoadingFeatureValidations}
                                    labelId='validation-filter-label'
                                    label="Validation Rule"
                                    value={validationRuleFilter}
                                    onChange={handleValidationRuleFilterChange}
                                    size="small">
                                    <MenuItem key={'-2'} value={''} sx={{ minHeight: '12px' }}>
                                        ---
                                    </MenuItem>
                                    <MenuItem key={'-1'} value={'any'} sx={{ minHeight: '12px' }}>
                                        Any
                                    </MenuItem>
                                    {featureValidationsResponse && featureValidationsResponse.data && featureValidationsResponse.data.validationRules.map((vr, idx) => (
                                        <MenuItem key={`${idx}`} value={vr}>{vr}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>)
                },
                renderCell: (params) => {
                    const featureRow: TFeatureRow = params.row;
                    const validations: TValidation[] = Array.isArray(featureRow.validations) && featureRow.validations.length > 0 ? featureRow.validations : [];

                    return <>{validations.filter((val, idx, self) => {
                        // filter out duplicate RCL_OVERLAP results
                        if (val.rule === 'RCL_OVERLAP') {
                            return self.findIndex((v) => {
                                return v.rule === val.rule
                            }) === idx
                        }
                        return true
                    }).map((validation, index) => {
                        return (<div key={validation.id}>
                            <Button
                                disabled={isFetchingDynamicValidationRows}
                                onClick={(e) => handleValidationRuleClick(e, featureRow, validation)}>{validation.rule}</Button>
                        </div>)
                    })}</>
                },
                minWidth: 150
            }
            pinnedColumns.push(validationsColumn)
        }

        const _columns = [
            ...pinnedColumns,
            ...layerPropertyNames.layerProperties.map((propName) => {
                return {
                    field: propName,
                    headerName: propName
                }
            })
        ].map((col: GridColDef) => ({ ...col, minWidth: typeof col.minWidth === 'number' ? col.minWidth : DEFAULT_COLUMN_WIDTH }))
        return _columns
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [organization.id, fileUpload, layer, layerPropertyNames, keepColumnsConfig])

    const [rows, setRows] = useState<TFeatureRow[]>([])
    const [rowCount, setRowCount] = useState<number>(0)

    const [density, setDensity] = useState<GridDensity>('compact')
    const [filterModel, setFilterModel] = useState<GridFilterModel>({ items: [] })
    const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({ pageSize: 25, page: 0 })
    const [sortModel, setSortModel] = useState<GridSortModel>([])

    const { data: getFeaturesForGridData, refetch: refetchFeaturesForGrid, isFetching: isFetchingFeaturesForGrid } = useQuery(
        ['get-features-for-grid'],
        () => getFeaturesForGrid({
            accessToken,
            organizationId: organization.id,
            fileUploadId: fileUpload.uploadId,
            layerName: layer.name,
            gridGetRowsParams: {
                sortModel: sortModel,
                filterModel: filterModel,
                paginationModel: paginationModel,
            },
            validationRuleFilter
        }))



    // side-effects for layer change
    useEffect(() => {
        console.log('layer changed')
        if (!layer) {
            return
        }

        setValidationRuleFilter(undefined)
        setFilterModel({ items: [] })
        setPaginationModel({ pageSize: 25, page: 0 })
        setSortModel([])
        setKeepColumnConfigs(false)
    }, [layer])

    // responsible for kicking off another request when the filterModel, paginationModel, or sortModel changes
    useEffect(() => {
        if (typeof refetchFeaturesForGrid !== 'function') {
            return
        }
        console.log('refetching features for grid')
        refetchFeaturesForGrid()
    }, [refetchFeaturesForGrid, filterModel, validationRuleFilter, paginationModel, sortModel])

    // responsible for setting row data from the server response
    useEffect(() => {
        console.log('setting row data from server response')

        if (!getFeaturesForGridData) {
            console.log('features not set')
            return
        }

        if (typeof getFeaturesForGridData.data.rowCount === 'number') {
            console.log('setting new row count')
            setRowCount(getFeaturesForGridData.data.rowCount)
        }

        const newRows = getFeaturesForGridData.data.rows.map((r) => {
            const { properties: featureProperties, ...restOfFeature } = r;
            // TODO: What am I missing here?
            return {
                ...restOfFeature,
                ...featureProperties
            }
        });
        console.log('setting new rows')
        setRows(newRows)
    }, [getFeaturesForGridData])

    // responsible for autosizing columns when the rows change
    useEffect(() => {
        if (!apiRef || !apiRef.current) {
            return
        }
        if (!rows || rows.length === 0) {
            return
        }

        console.log('DEBUG autosizing columns', apiRef, apiRef.current, rows, rows.length)
        setTimeout(() => {
            apiRef.current.autosizeColumns({
                includeHeaders: true,
                includeOutliers: true,
            })
            setKeepColumnConfigs(true)
        }, 75)
    }, [apiRef, rows])

    return (
        <Shell>
            {/* Fill the remaining height using the div and the datagrid will fill its parent. */}
            <div style={{ height: 'calc(100vh - 64px)' }}>
                <DataGridPro
                    apiRef={apiRef}
                    loading={isFetchingFeaturesForGrid}
                    density={density}
                    onDensityChange={(d) => setDensity(d)}
                    slots={{
                        toolbar: () => (<>
                            <GridToolbarContainer>
                                <>
                                    <Box>
                                        <FormControl sx={{ height: '100%', width: '100%', marginTop: '8px' }}>
                                            <InputLabel id="fuln-input-label" size="small">Layer Name</InputLabel>
                                            <Select
                                                labelId='fuln-input-label'
                                                label="Layer Name"
                                                size="small"
                                                value={layer.name} onChange={handleLayerChange}>
                                                {fileUpload.layers.map((layer, index) => (
                                                    <MenuItem key={index} value={layer.name}>{layer.name}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Box>
                                </>
                                <GridToolbarColumnsButton />
                                <GridToolbarFilterButton />
                                <Box sx={{ flexGrow: 1 }} />
                                <GridToolbarExport />
                            </GridToolbarContainer>
                        </>),
                    }}
                    slotProps={{
                        loadingOverlay: {
                            variant: 'skeleton',
                            noRowsVariant: 'skeleton',
                        },
                    }}
                    columnHeaderHeight={COLUMN_HEADER_HEIGHT}
                    columns={columns}
                    rows={rows}
                    rowCount={rowCount}

                    // filtering
                    filterMode="server"
                    onFilterModelChange={(model) => {
                        console.log('onFilterModelChange', model)
                        setFilterModel(model)
                    }}

                    // pagination
                    pagination
                    paginationMode="server"
                    pageSizeOptions={[10, 25, 50, 100]}
                    paginationModel={paginationModel}
                    onPaginationModelChange={(pm) => {
                        console.log('onPaginationModelChange', pm)
                        setPaginationModel(pm)
                    }}

                    // sorting
                    sortingMode="server"
                    onSortModelChange={(model) => {
                        console.log('onSortModelChange', model)
                        setSortModel(model)
                    }}

                    // for now, not implementing
                    disableColumnPinning
                    initialState={{ pinnedColumns: { left: ['map', 'layer', 'validations'] } }}

                    // selection
                    onRowSelectionModelChange={(newRowSelectionModel) => {
                        setRowSelectionModel(newRowSelectionModel);
                    }}
                    rowSelectionModel={rowSelectionModel}
                />
            </div>
            <Modal
                open={dynamicValidationRows !== undefined}
                onClose={() => handleCloseValidationModal()}
            >
                <Box sx={modalBoxStyles}>
                    <div style={{ margin: '.4rem 0' }}>
                        {selectedRule && <Typography variant={'subtitle2'}>{selectedRule}</Typography>}
                        <IconButton
                            aria-label="close"
                            onClick={() => handleCloseValidationModal()}
                            sx={(theme) => ({
                                position: 'absolute',
                                right: 0,
                                top: 0,
                                color: theme.palette.grey[500],
                                padding: '0.4rem'
                            })}
                            size='small'
                        ><CloseIcon />
                        </IconButton>
                    </div>
                    {dynamicValidationRows && <ValidationMapGrid features={dynamicValidationRows} />}
                </Box>
            </Modal>
        </Shell>
    )
}
