import { useContext, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { AppContext } from "../App"
import Shell from '../layout/Shell'
import { useMutation, useQuery } from "react-query"
import { createFUModel, deleteFUModel, getFileUploadModels, generateModelLayerPrediction, getTemplates, updateFUModelLayer, updateFUModelLayerField } from "../api/modelsController"
import {
    CircularProgress, Dialog,
    DialogContent,
    DialogTitle,
    Button,
    Grid,
    SelectChangeEvent,
    Select,
    MenuItem,
    InputLabel,
    FormControl,
    DialogActions,
    DialogContentText,
    Accordion,
    AccordionSummary,
    AccordionDetails,
} from "@mui/material"
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import { getOrganizations } from "../hooks/useOrganization"
import Correlator, { TCorrelation, TTarget } from "../components/Correlator"
import { getFeaturePropertiesByLayerName } from "../api/featureController"
import { Add as AddIcon, /* Delete as DeleteIcon, */ Edit as EditIcon, AutoAwesome as AutoAwesomeIcon, Clear as ClearIcon } from "@mui/icons-material"
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import MDEditor from "@uiw/react-md-editor"


interface TabPanelProps {
    children?: React.ReactNode;
    dir?: string;
    index: number;
    value: number;
}


function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            style={{ width: '100%' }}
            {...other}
        >
            {value === index && (
                <Box>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

type TTabConfig = {
    dataModelLayerName: string
    percentConfigured: number
    fuModelId: string
    fuModelLayer: any
    order?: number | null
}

// Notes
// Source refers to the data uploaded by the org
// Detination refers to the data model

const ModelsPage: React.FC = () => {
    const { organizationId, fileUploadId } = useParams<{ organizationId: string, fileUploadId: string }>()
    console.log(`organizationId: ${organizationId}\nfileUploadId: ${fileUploadId}`)
    const { accessToken } = useContext(AppContext)

    const { data: organizationResult } = useQuery('get-organizations', () => {
        if (!accessToken || !organizationId) {
            console.log('NOT FETCHING organizations')
            return
        }
        return getOrganizations(accessToken!)
    });

    const organization = organizationResult && !(organizationResult instanceof Error) && organizationResult.belongs
        ? organizationResult.organizations.find((o, idx) => organizationId ? o.id === organizationId : true) : undefined;

    const { data: getFUModelsRespBody, refetch: refetchGetFUModels } = useQuery(`get-file-upload-models-${fileUploadId}`, () => {
        if (!accessToken || !organizationId || !fileUploadId) {
            console.log('NOT FETCHING file upload models')
            return
        }
        console.log('fetching file upload models', accessToken, organizationId, fileUploadId)
        return getFileUploadModels(accessToken, organizationId, fileUploadId)
    });

    const { data: getTemplatesRespBody } = useQuery('get-templates', () => {
        if (!accessToken) {
            console.log('NOT FETCHING templates')
            return
        }
        console.log('fetching templates')
        return getTemplates(accessToken)
    });

    const {
        mutate: createFUModelMutate,
        isLoading: isCreatingFUModel,
    } = useMutation('create-fu-model',
        async ({
            accessToken,
            organizationId,
            fileUploadId,
            dataModelId
        }: {
            accessToken: string,
            organizationId: string,
            fileUploadId: string,
            dataModelId: string
        }) => {
            return createFUModel({ accessToken, organizationId, fileUploadId, dataModelId })
        },
        {
            onSuccess: () => {
                closeModelModal()
                refetchGetFUModels()
            },
            onError: (error, vars, ctx) => {
                alert(`Unexpected error creating a file upload model`)
                console.error('OOPS\n', error, vars, ctx)
            },
        })

    const {
        mutate: updateFUModelLayerMutate,
        isLoading: isUpdatingFUModelLayer,
    } = useMutation('update-fu-model-layer', updateFUModelLayer, {
        onSuccess: () => {
            console.log('update-fu-model-layer onSuccess')
            refetchGetFUModels()
        },
        onError: (error, vars, ctx) => {
            alert(`Unexpected error updating the file upload model layer`)
            console.error('OOPS\n', error, vars, ctx)
        }
    })

    const { mutate: mutateDeleteFUModel, isLoading: isDeletingFUModel } = useMutation('delete-fu-model', deleteFUModel, {
        onSuccess: () => {
            console.log('delete-fu-model onSuccess')
            closeShowResetModelDialog()
            setSelectedFileUploadLayerName(undefined)
            refetchGetFUModels()
        },
        onError: (error, vars, ctx) => {
            alert(`Unexpected error deleting the file upload model`)
            console.error('OOPS\n', error, vars, ctx)
        }
    })

    const { mutate: fetchModelPrediction, isLoading: isFetchingModelPrediction } =
        useMutation(
            `get-model-prediction-${fileUploadId}`,
            async ({
                accessToken,
                organizationId,
                fileUploadId,
                fuLayerName,
                dataModelId,
                dataModelLayerId
            }: {
                accessToken: string,
                organizationId: string,
                fileUploadId: string,
                fuLayerName: string,
                dataModelId: string,
                dataModelLayerId: string
            }) => {
                return generateModelLayerPrediction({ accessToken, organizationId, fileUploadId, fuLayerName, dataModelId, dataModelLayerId })
            },
            {
                onSuccess: (response) => {
                    // Handle success case
                    console.log('Model Prediction Success', response)
                    refetchGetFUModels()
                },
                onError: (error: Error) => {
                    // Handle error case
                    console.error('fetch model prediction failed. Error:', error.message);
                }
            });

    const [showModelModal, setShowModelModal] = useState(false);
    const closeModelModal = () => {
        setShowModelModal(false);
    };

    const [activeTabIdx, setActiveTabIdx] = useState(-1);
    const handleActiveTabChange = (_e: React.SyntheticEvent, newActiveTabIdx: number) => {
        setSelectedFileUploadLayerName("")
        setActiveTabIdx(newActiveTabIdx);
    };

    const [selectedFileUploadLayerName, setSelectedFileUploadLayerName] = useState<string>()

    const [showResetModelDialog, setShowResetModelDialog] = useState(false);
    const closeShowResetModelDialog = () => {
        setShowResetModelDialog(false);
    }
    const [deleteFUModelContext /*, setDeleteFUModelContext*/] = useState<{ accessToken: string, organizationId: string, modelId: string } | null>(null)

    // shows modal if no data model has been selected
    useEffect(() => {
        if (!getFUModelsRespBody || !getFUModelsRespBody.data.success) {
            return
        }

        if (getFUModelsRespBody.data.fuModels.length === 0) {
            setShowModelModal(true)
        } else if (activeTabIdx === -1) {
            setActiveTabIdx(0); // defaulting to first tab upon load, can eventually be based on nav?
        }
    }, [getFUModelsRespBody, activeTabIdx])

    if (!organization || !getFUModelsRespBody || !getTemplatesRespBody) {
        return (
            <Shell>
                <CircularProgress />
            </Shell>
        );
    }

    const fileUploadLayers = organization.fileUploads.find((fu) => fu && fu.uploadId === fileUploadId)?.layers || []

    const templates = getTemplatesRespBody.data.templates;

    const unsortedTabs: TTabConfig[] = [];

    // NOTE: ONLY SUPPORTING ONE MODEL CONFIG PER UPLOAD
    const fuModel = getFUModelsRespBody.data.fuModels?.find((fm) => fm?.source === fileUploadId) || {};
    console.log('fuModel', fuModel);
    const matchingTemplate = templates.find((t: any) => t.objectId === fuModel.destination);
    // console.log('matchingTemplate', matchingTemplate)

    const matchingTemplateLayers = matchingTemplate?.layers || [];
    for (const mtl of matchingTemplateLayers) {
        const order = mtl.order;
        if (typeof order !== 'number') {
            // don't show the tab if there is no numeric order
            continue
        }

        const mtlName = mtl.name || '';
        const matchingSourceLayer = fuModel.fuModelLayers.find((fml: any) => {
            return fml.destination === mtlName
        })

        const matchingSourceLayerFields = matchingSourceLayer && Array.isArray(matchingSourceLayer.fuModelLayerFields) ? matchingSourceLayer.fuModelLayerFields : [];
        const correlatedCount = matchingSourceLayerFields.filter((x: any) => !!x?.source).length;
        const totalCount = matchingSourceLayerFields.length;
        const percentConfigured = correlatedCount === 0 ? 0 : (correlatedCount / totalCount) * 100;

        unsortedTabs.push({
            dataModelLayerName: mtlName,
            percentConfigured: Math.round(percentConfigured),
            fuModelId: fuModel._id,
            fuModelLayer: matchingSourceLayer,
            order: order
        })
    }

    const tabs: TTabConfig[] = [
        ...unsortedTabs
            .sort((a, b) => {
                const aOrder = a.order;
                const bOrder = b.order;
                if (typeof aOrder === 'number' && typeof bOrder === 'number') {
                    return aOrder - bOrder;
                }
                return 0;
            })
    ];



    return (
        <Shell>
            {/* Only needed for initial setting of the model */}
            <Dialog
                sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
                maxWidth="xs"
                open={showModelModal}
            >
                <DialogTitle>Choose data model</DialogTitle>
                <DialogContent dividers>
                    {templates.map((t) => {
                        return (
                            <div key={t.objectId}>
                                <Button disabled={isCreatingFUModel} onClick={() => {
                                    console.log('clicked on template', t)
                                    if (!accessToken || !organizationId) {
                                        return;
                                    }
                                    createFUModelMutate({
                                        accessToken,
                                        organizationId,
                                        fileUploadId,
                                        dataModelId: t.objectId
                                    })
                                }}>{t.typeName}</Button>
                            </div>
                        );
                    })}
                </DialogContent>
            </Dialog>

            {/* Reset model modal */}
            <Dialog
                sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
                maxWidth="xs"
                open={showResetModelDialog}
            >
                <DialogTitle>Reset Model</DialogTitle>
                <DialogContent dividers>
                    <DialogContentText>Are you sure you want to reset the model and pick a different one?</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeShowResetModelDialog} disabled={isDeletingFUModel}>
                        Cancel
                    </Button>
                    <Button
                        onClick={() => {
                            if (!deleteFUModelContext) {
                                throw Error('deleteFUModelContext is null, unable to proceed.')
                            }
                            mutateDeleteFUModel(deleteFUModelContext)
                        }}
                        color="error"
                        autoFocus
                        disabled={isDeletingFUModel}
                    >
                        Reset
                    </Button>
                </DialogActions>
            </Dialog>

            {!showModelModal && <>
                {/* NOTE: Hiding the model name for now */}
                {/* <Grid container spacing={2}>
                    <Grid item xs={3}><div style={{ padding: '1rem 1rem 0 1rem' }}>{fuModel.destinationName && <h3>{fuModel.destinationName}</h3>}</div></Grid>
                    <Grid item xs={3}>
                        <div style={{ padding: '1.6rem 1rem 0 1rem' }}>
                            <Button variant="contained" color="error" endIcon={<DeleteIcon />} disabled={isDeletingFUModel} onClick={() => {
                                console.log('reset model', fuModel)
                                if (!accessToken || !organizationId || !fuModel._id) {
                                    return;
                                }
                                setDeleteFUModelContext({ accessToken, organizationId, modelId: fuModel._id })
                                setShowResetModelDialog(true)
                            }}>Reset Model</Button>
                        </div>
                    </Grid>
                </Grid>
                <hr /> */}
                <Box
                    sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex' }}
                >
                    <Tabs value={activeTabIdx} onChange={handleActiveTabChange} orientation="vertical">
                        {tabs.map((t) => {
                            return (
                                <Tab label={<>
                                    {t.dataModelLayerName} - {t.percentConfigured}%
                                </>} sx={{ padding: '1rem', paddingLeft: '.6rem', alignItems: 'start', minWidth: '380px' }} />
                            )
                        })}
                    </Tabs>
                    <>
                        {tabs.map((tab, idx) => {
                            // if (idx === activeTabIdx) {
                            //     console.log('active tab info', JSON.stringify({
                            //         t: tab
                            //     }, null, 2))
                            // }

                            const sourceLayerName = selectedFileUploadLayerName || tab?.fuModelLayer?.source || '';
                            console.log(`DEBUG: sourceLayerName: ${sourceLayerName}`)

                            return (
                                <TabPanel value={activeTabIdx} index={idx}>
                                    {/* <pre>{JSON.stringify({ source: tab?.fuModelLayer?.source, sourceType: tab?.fuModelLayer?.sourceType, selectedFileUploadLayerName }, null, 2)}</pre> */}
                                    <Grid container spacing={0}>
                                        <Grid item xs={3} sx={{ padding: '1rem' }}>
                                            <FormControl sx={{ width: '100%' }}>
                                                <InputLabel id="fuln-input-label">File Upload Layer Name</InputLabel>
                                                <Select
                                                    labelId="fuln-input-label"
                                                    value={sourceLayerName}
                                                    label="File Upload Layer Name"
                                                    onChange={(event: SelectChangeEvent) => {
                                                        if (!accessToken || !organizationId) {
                                                            return;
                                                        }
                                                        const _sourceLayerName = event.target.value;
                                                        setSelectedFileUploadLayerName(_sourceLayerName)
                                                        updateFUModelLayerMutate({
                                                            accessToken,
                                                            organizationId,
                                                            modelId: fuModel._id,
                                                            modelLayerId: tab.fuModelLayer._id,
                                                            fuModelLayer: {
                                                                ...tab.fuModelLayer,
                                                                source: _sourceLayerName,
                                                                sourceName: _sourceLayerName,
                                                                sourceType: fileUploadLayers.find((ful) => ful.name === _sourceLayerName)?.type ?? "unknown"
                                                            }
                                                        })
                                                    }}
                                                    sx={{ width: '100%' }}
                                                >
                                                    {fileUploadLayers.map((ful) => {
                                                        return (
                                                            <MenuItem value={ful.name}>{ful.name}</MenuItem>
                                                        );
                                                    })}
                                                </Select>
                                            </FormControl>
                                            <FormControl sx={{ marginTop: '1rem', width: '100%' }}>
                                                <Grid container spacing={1}>
                                                    <Grid item xs={6}>
                                                        <Button
                                                            disabled={!sourceLayerName}
                                                            color={'secondary'}
                                                            variant="contained"
                                                            onClick={() => {
                                                                if (!accessToken || !organizationId) {
                                                                    return;
                                                                }
                                                                const _sourceLayerName = selectedFileUploadLayerName
                                                                updateFUModelLayerMutate({
                                                                    accessToken,
                                                                    organizationId,
                                                                    modelId: fuModel._id,
                                                                    modelLayerId: tab.fuModelLayer._id,
                                                                    fuModelLayer: {
                                                                        ...tab.fuModelLayer,
                                                                        source: _sourceLayerName,
                                                                        sourceName: _sourceLayerName,
                                                                        sourceType: fileUploadLayers.find((ful) => ful.name === _sourceLayerName)?.type ?? "unknown",
                                                                        fuModelLayerFields: []
                                                                    }
                                                                })
                                                            }}
                                                            fullWidth={true}
                                                            endIcon={<ClearIcon />}
                                                        >Clear</Button>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Button
                                                            disabled={!sourceLayerName}
                                                            variant="contained"
                                                            onClick={() => fetchModelPrediction(
                                                                {
                                                                    accessToken: accessToken!,
                                                                    organizationId: organization.id,
                                                                    fileUploadId: fileUploadId,
                                                                    fuLayerName: sourceLayerName,
                                                                    dataModelId: tab.fuModelId,
                                                                    dataModelLayerId: tab.fuModelLayer._id
                                                                })}
                                                            fullWidth={true}
                                                            endIcon={<AutoAwesomeIcon />}
                                                        >Predict</Button>
                                                    </Grid>
                                                </Grid>
                                            </FormControl>

                                        </Grid>
                                        <Grid item xs={9}>
                                            <CorrelationSection
                                                fileUploadId={fileUploadId}
                                                currentOrganizationId={organizationId!}
                                                accessToken={accessToken!}
                                                sourceLayerName={sourceLayerName}
                                                tab={tab}
                                                isUpdatingLayerCorrelation={isFetchingModelPrediction || isUpdatingFUModelLayer}
                                                refetchGetFUModels={refetchGetFUModels} />
                                        </Grid>
                                    </Grid>


                                </TabPanel>
                            )
                        })}

                    </>

                </Box>
            </>
            }
        </Shell>
    );
};

const TemplateStringModalDialog: React.FC<{ isOpen: boolean, onClose: (latestTemplateString: string, isSave: boolean) => void, templateString: string }> = (props) => {
    console.log('TemplateStringModalDialog.templateString', props.templateString)
    // const [isOpen, setIsOpen] = useState(false);
    const [templateStringInputVal, setTemplateStringInputVal] = useState('')

    useEffect(() => {
        setTemplateStringInputVal(props.templateString)
    }, [props.templateString])

    const resetAndClose = (isSave: boolean = false) => {
        props.onClose(templateStringInputVal, isSave);
        // setTemplateStringInputVal('')
    };

    return (
        <Dialog
            open={props.isOpen}
            onClose={() => resetAndClose()}
            PaperProps={{
                component: 'form',
                onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
                    event.preventDefault();
                    resetAndClose(true);
                },
            }}
        >
            <DialogTitle>Hover Label</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    This template supports markdown and will control how your data is rendered upon hovering on a feature of this type of layer.
                </DialogContentText>
                <div>
                    <MDEditor
                        value={templateStringInputVal}
                        onChange={(v) => setTemplateStringInputVal(v || '')}
                    />
                    {/* TODO: Something like this would be what is rendered in Map.tsx */}
                    {/* <MDEditor.Markdown source={templateStringInputVal} style={{ whiteSpace: 'pre-wrap' }} /> */}
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => resetAndClose()}>Cancel</Button>
                <Button type="submit">Save</Button>
            </DialogActions>
        </Dialog>)
}

// TODO: consider combining the modals into one component?
const AutoPopulateModalDialog: React.FC<{ featureName: string, onClose: (latestTemplateString: string, isSave: boolean) => void, templateString: string }> = (props) => {
    console.log('AutoPopulateModalDialog.templateString', props.templateString)
    const [templateStringInputVal, setTemplateStringInputVal] = useState('')

    useEffect(() => {
        setTemplateStringInputVal(props.templateString)
    }, [props.templateString])

    const resetAndClose = (isSave: boolean = false) => {
        props.onClose(templateStringInputVal, isSave);
    };

    return (
        <Dialog
            open={props.featureName !== ""}
            onClose={() => resetAndClose()}
            PaperProps={{
                component: 'form',
                onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
                    event.preventDefault();
                    resetAndClose(true);
                },
            }}
        >
            <DialogTitle>Hover Label</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    This template supports markdown and will control how your data is rendered upon hovering on a feature of this type of layer.
                </DialogContentText>
                <div>
                    <MDEditor
                        value={templateStringInputVal}
                        onChange={(v) => setTemplateStringInputVal(v || '')}
                    />
                    {/* TODO: Something like this would be what is rendered in Map.tsx */}
                    {/* <MDEditor.Markdown source={templateStringInputVal} style={{ whiteSpace: 'pre-wrap' }} /> */}
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => resetAndClose()}>Cancel</Button>
                <Button type="submit">Save</Button>
            </DialogActions>
        </Dialog>)
}

const CorrelationSection: React.FC<{
    accessToken: string,
    currentOrganizationId: string,
    fileUploadId: string,
    sourceLayerName: string,
    tab: TTabConfig,
    isUpdatingLayerCorrelation: boolean,
    refetchGetFUModels: () => void
}> = ({
    accessToken,
    currentOrganizationId,
    fileUploadId,
    sourceLayerName,
    tab,
    isUpdatingLayerCorrelation,
    refetchGetFUModels
}) => {
        const fuModelLayer = tab.fuModelLayer
        const fuModelLayerFields = tab.fuModelLayer.fuModelLayerFields

        let correlatorTargets: TTarget[] = fuModelLayerFields.map((fmlf: {
            destination: string,
            destinationName: string
        }) => ({
            id: fmlf.destination,
            name: fmlf.destination,
            rawTarget: fmlf
        }))

        const [isSavingCorrelation, setIsSavingCorrelation] = useState(false)
        const { data: sourceFeaturePropertiesResp, isLoading: sourceFeaturePropertiesLoading } = useQuery(`get-feature-props-by-layername-${sourceLayerName}`, () => {
            if (!accessToken || !currentOrganizationId || !fileUploadId || !sourceLayerName) {
                return
            }
            return getFeaturePropertiesByLayerName(accessToken, sourceLayerName, currentOrganizationId, fileUploadId)
        })

        const correlatorCorrelations: TCorrelation[] = fuModelLayerFields.filter((fmlf: {
            source: string | null,
            sourceName: string | null
        }) => {
            return typeof fmlf?.source === 'string'
        }).map((fmlf: {
            _id: string,
            source: string,
            sourceName: string,
            destination: string,
            destinationName: string
        }) => ({
            sourceId: fmlf.source,
            sourceName: fmlf.sourceName,
            targetId: fmlf.destination,
            targetName: fmlf.destinationName,
            rawTarget: fmlf

        }))
        console.log('DEBUG sourceFeaturePropertiesResp', sourceFeaturePropertiesResp?.data)
        const correlatorSources = sourceFeaturePropertiesResp ? sourceFeaturePropertiesResp.data.properties.filter((p) => {
            return correlatorCorrelations.findIndex((c) => c.sourceId === p) === -1
        }).map((p) => ({
            id: p,
            name: p,
        })) : [];
        console.log('DEBUG correlatorSources', correlatorSources)

        const { mutate: saveCorrelation } = useMutation(
            'save-layer-field-correlation',
            async (layerFieldCorrelation: any) => {
                console.log('layerFieldCorrelation', layerFieldCorrelation)
                const modelId = tab.fuModelId
                const modelLayerId = tab.fuModelLayer._id
                const modelLayerFieldId = layerFieldCorrelation._id;


                if (!accessToken || !currentOrganizationId || !modelId || !modelLayerId || !modelLayerFieldId) {
                    return
                }

                return updateFUModelLayerField({
                    accessToken,
                    organizationId: currentOrganizationId,
                    modelId,
                    modelLayerId,
                    modelLayerFieldId,
                    modelLayerField: {
                        ...layerFieldCorrelation
                    }
                })
            },
            {
                onSuccess: (data) => {
                    console.log('saveCorrelation success', data)
                    setIsSavingCorrelation(false)
                    refetchGetFUModels()
                },
                onError: (error) => {
                    console.log('saveCorrelation error', error)
                    setIsSavingCorrelation(false)
                },
            },
        )


        const {
            mutate: updateFUModelLayerMutate,
            // isLoading: isUpdatingFUModelLayer,
        } = useMutation('update-fu-model-layer-template-string', updateFUModelLayer, {
            onSuccess: () => {
                console.log('update-fu-model-layer-template-string onSuccess')
                refetchGetFUModels()
                setOpenTemplateStringModal(false)
                setFeatureNameForAutoPopulateModal('')
            },
            onError: (error, vars, ctx) => {
                alert(`Unexpected error updating the file upload model layer`)
                console.error('OOPS\n', error, vars, ctx)
            }
        })


        const onSaveCorrelation = (correlation: TCorrelation) => {
            console.log('onSaveCorrelation', correlation)

            setIsSavingCorrelation(true)

            const { sourceId, sourceName, rawTarget } = correlation

            const correlationUpdate = {
                ...rawTarget,
                source: sourceId,
                sourceName: sourceName,
            }
            console.log('onSaveCorrelation', { correlationUpdate })
            saveCorrelation(correlationUpdate)
        };
        const onRemoveCorrelation = (correlation: TCorrelation) => {
            console.log('onRemoveCorrelation', correlation)

            setIsSavingCorrelation(true)

            const { rawTarget } = correlation
            const correlationUpdate = {
                ...rawTarget,
                source: null,
                sourceName: null,
            }
            console.log('onRemoveCorrelation', { correlationUpdate })
            saveCorrelation(correlationUpdate)
        };

        // template string stuff
        const hasTemplateString = typeof fuModelLayer?.templateString === 'string' && fuModelLayer.templateString.trim().length > 0
        console.log('fuModelLayer.templateString', fuModelLayer.templateString)
        const [templateString, setTemplateString] = useState('')
        const [openTemplateStringModal, setOpenTemplateStringModal] = useState(false)

        const [featureNameForAutoPopulateModal, setFeatureNameForAutoPopulateModal] = useState("")

        useEffect(() => {
            console.log('useEffect fuModelLayer.templateString', fuModelLayer.templateString)
            if (typeof fuModelLayer?.templateString === 'string') {
                setTemplateString(fuModelLayer.templateString)
            }
        }, [fuModelLayer?.templateString])

        const getAutoPopulateTemplateString = () => {
            const x = fuModelLayer.autoPopulateSources.find((f: any) => {
                return f.sourceFieldName === featureNameForAutoPopulateModal
            })

            if (x) {
                return x.autoPopulateTemplateString
            }
            return ""
        }

        return (
            <>
                <Grid container spacing={0} sx={{ marginRight: '1rem' }}>
                    <Grid item xs={8}>
                        <Correlator
                            sources={sourceLayerName === fuModelLayer.source ? correlatorSources : []}
                            mappings={correlatorCorrelations}
                            targets={correlatorTargets}
                            onSaveMapping={onSaveCorrelation}
                            onRemoveMapping={onRemoveCorrelation} />
                    </Grid>
                    <Grid item xs={4} sx={{ textAlign: 'center' }}>
                        <Grid container direction="row">
                            {/* note: this margin needs to match the My Data / Data Model in Correlator/index.tsx */}
                            <Grid item xs spacing={12}><h3 style={{ marginTop: '.5rem', marginBottom: '.1rem' }}>Rules</h3></Grid>
                        </Grid>
                        <Grid container direction="row">
                            <Grid item xs spacing={2}><hr /></Grid>
                        </Grid>
                        <Grid container direction="column">
                            <Grid container direction="column">
                                Layer Rules
                                <Grid item xs spacing={2}><hr /></Grid>
                                <Grid item xs spacing={2}>
                                    {/* Capture state of template string being present and change add to edit */}
                                    <Button color="primary"
                                        endIcon={hasTemplateString ? <AddIcon /> : <EditIcon />}
                                        onClick={() => {
                                            console.log('render template string modal for', fuModelLayer)
                                            setOpenTemplateStringModal(true)
                                        }}
                                        fullWidth={true}
                                    >
                                        Hover Label
                                    </Button>
                                </Grid>
                            </Grid>
                            <Grid container direction="column">
                                Feature Rules
                                <Grid item xs spacing={2}><hr /></Grid>
                                <Grid item xs spacing={2}>
                                    <Accordion >
                                        <AccordionSummary color="primary" sx={{ textAlign: 'center' }} expandIcon={<ExpandMoreIcon />}>Auto Populate</AccordionSummary>
                                        <AccordionDetails sx={{ maxHeight: '200px', overflow: 'auto' }}>
                                            {sourceFeaturePropertiesResp?.data?.properties.map(feature => {
                                                return (
                                                    <Grid item xs spacing={2}>
                                                        <Button color="primary"
                                                            onClick={() => {
                                                                console.log('render auto populate string', feature)
                                                                setFeatureNameForAutoPopulateModal(feature)
                                                            }}
                                                            fullWidth={true}
                                                        >

                                                            {feature}
                                                        </Button>
                                                    </Grid>
                                                )
                                            })}
                                        </AccordionDetails>
                                    </Accordion>

                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                </Grid>
                {(sourceFeaturePropertiesLoading || isSavingCorrelation || isUpdatingLayerCorrelation) && <div
                    className="loading-overlay"
                    style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(225, 225, 225, 0.7)',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        zIndex: 1000,
                    }}
                >
                    <CircularProgress />
                </div>}
                <TemplateStringModalDialog isOpen={openTemplateStringModal} onClose={(latestTemplateString, isSave: boolean) => {
                    console.log(`setting templateString to ${latestTemplateString} and isSave = ${isSave}`)

                    if (isSave) {
                        setTemplateString(latestTemplateString)
                        const mutationInputs = {
                            accessToken,
                            organizationId: currentOrganizationId,
                            modelId: tab.fuModelId,
                            modelLayerId: tab.fuModelLayer._id,
                            fuModelLayer: {
                                ...tab.fuModelLayer,
                                templateString: latestTemplateString,
                                setTemplateString: true
                            }
                        }
                        console.log('updating fuModelLayer with templateString', latestTemplateString, {
                            ...mutationInputs
                        })
                        updateFUModelLayerMutate(mutationInputs);
                    } else {
                        setOpenTemplateStringModal(false)
                    }

                }} templateString={templateString} />

                <AutoPopulateModalDialog featureName={featureNameForAutoPopulateModal} onClose={(latestTemplateString, isSave: boolean) => {
                    console.log(`setting templateString to ${latestTemplateString} and isSave = ${isSave}`)
                    if (isSave) {
                        let newFuModelayer = tab.fuModelLayer
                        newFuModelayer.setAutoPopulateSources = true
                        if (!newFuModelayer.autoPopulateSources) {
                            newFuModelayer.autoPopulateSources = []
                        }

                        let index = newFuModelayer.autoPopulateSources.findIndex((f: any) => f.sourceFieldName === featureNameForAutoPopulateModal)

                        if (index >= 0) {
                            newFuModelayer.autoPopulateSources[index].autoPopulateTemplateString = latestTemplateString
                        } else {
                            newFuModelayer.autoPopulateSources.push(
                                {
                                    sourceFieldName: featureNameForAutoPopulateModal,
                                    autoPopulateTemplateString: latestTemplateString
                                }
                            )
                        }

                        const mutationInputs = {
                            accessToken,
                            organizationId: currentOrganizationId,
                            modelId: tab.fuModelId,
                            modelLayerId: tab.fuModelLayer._id,
                            fuModelLayer: {
                                ...newFuModelayer
                            }
                        }

                        console.log('updating fuModelLayer with templateString for feature', latestTemplateString, {
                            ...mutationInputs
                        })
                        updateFUModelLayerMutate(mutationInputs);
                    } else {
                        setFeatureNameForAutoPopulateModal("")
                    }

                }}
                    templateString={fuModelLayer.autoPopulateSources ?
                        getAutoPopulateTemplateString()
                        : ''} />
            </>);
    }

export default ModelsPage;
