import { CircularProgress, Collapse, FormControl, List, ListItemButton, ListItemText, TextField } from "@mui/material";
import { useQuery } from "react-query";
import { TGetFeaturesLayerNameWithFeatures as TLayerFeatures, getFeatureBySearchTerm } from "../../../api/featureController";
import { useEffect, useState } from "react";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import React from "react";
import { findAndReplaceLayerTemplateString } from "../templateString";

type SearchTabProps = {
    accessToken: string,
    organizationId: string,
    fileUploadId: string,
    templateStringsMap: Map<string, string>,
    handleFeatureClickedFromSearchTab: (feature: any) => void
}

export const SearchTab: React.FC<SearchTabProps> = React.memo(({ accessToken, organizationId, fileUploadId, templateStringsMap, handleFeatureClickedFromSearchTab }) => {

    console.log("SearchTab.tsx Rendered");

    const [featureSearchInputValue, setFeatureSearchInputValue] = useState<string>('')
    const [searchTerm, setSearchTerm] = useState<string>('')
    const [openLayerItem, setOpenLayerItem] = useState<string>('');
    const [selectedFeatureItem, setSelectedFeatureItem] = useState<string>('');

    const { data: getLayerFeaturesResult, isLoading: isLoadingFeatures } = useQuery(
        ['get-layer-features', searchTerm],
        async () => {

            if (!(accessToken && organizationId && fileUploadId && searchTerm && searchTerm.length > 0)) {
                return;
            }

            return getFeatureBySearchTerm(accessToken, organizationId, fileUploadId, searchTerm)
        },
        {
            onSuccess: (result) => {
                console.log('Search for layer list by search succeeded!', result);
            },
            onError: (error) => {
                console.error('Search for layer list by search succeeded failed:', error);
            },
        }
    )

    const handleLayerItemClick = (itemId: string) => {
        if (itemId === openLayerItem) {
            setOpenLayerItem('')
        } else {
            setOpenLayerItem(itemId)
        }
    };

    const handFeatureItemClick = (itemId: string) => {
        setSelectedFeatureItem(itemId)
    };

    const renderFeatureList = (layer: TLayerFeatures, feature: any) => {
        let displayString = ''

        displayString = findAndReplaceLayerTemplateString(templateStringsMap,
            {
                layer: layer.id,
                properties: feature.feature.properties
            })

        if (displayString === '') {
            // just field a field the search term was in??
            // TODO clean this up
            for (let key in feature.feature.properties) {
                let propertyValue = feature.feature.properties[key]
                if (propertyValue && propertyValue.toLowerCase().includes(searchTerm.toLowerCase())) {
                    displayString = feature.feature.properties[key]
                    break;
                }
            }
        }

        return displayString
    }

    const renderListItems = (layerList: TLayerFeatures[]) => {

        if (layerList.length === 0) {
            return <div>No results found</div>
        }

        let totalResultCount = 0
        layerList.forEach(f => totalResultCount += f.featureList.length)

        return (
            <React.Fragment>
                <div>Returned {totalResultCount} results{totalResultCount >= 100 ? '. Some results may be left out. Please refine search.' : ''}</div>
                {layerList.map((layer) => (
                    <React.Fragment key={layer.id}>
                        <ListItemButton sx={{
                            borderTop: '1px solid #ccc'
                        }} onClick={() => handleLayerItemClick(layer.id)} selected={(openLayerItem === layer.id)}>
                            <ListItemText primary={layer.id} secondary={layer.featureList.length + " results"} />
                            {layer.featureList && (openLayerItem === layer.id) ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                        {layer.featureList && (
                            <Collapse in={openLayerItem === layer.id} timeout="auto">
                                <List component="div" disablePadding>
                                    {layer.featureList.map((feature) =>
                                        <ListItemButton sx={{
                                            borderTop: '1px solid #ccc'
                                        }} selected={(selectedFeatureItem === feature.id)} onClick={() => {
                                            handFeatureItemClick(feature.id)
                                            handleFeatureClickedFromSearchTab(feature)
                                        }}>
                                            <ListItemText primary={renderFeatureList(layer, feature)} />
                                        </ListItemButton>
                                    )}
                                </List>
                            </Collapse>
                        )}
                    </React.Fragment>
                ))
                }
            </React.Fragment>
        );
    }

    // TODO: MIGHT GET RID OF THIS
    // const handleSearchFeatureOnBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    //     //don't search unless there are more than 2 characters
    //     if (event && event.target && featureSearchInputValue.length > 2) {
    //         setSearchTerm(featureSearchInputValue)
    //     }
    // }

    // const handleSearchFeatureKeyboardEvent = (event: React.KeyboardEvent<HTMLDivElement>) => {
    //     if (event && event.target && event.key === 'Enter' && featureSearchInputValue.length > 2) {
    //         setSearchTerm(featureSearchInputValue)
    //     }
    // }



    const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");

    useEffect(() => {
        const handler = setTimeout(() => {
            setSearchTerm(debouncedSearchTerm.trim());
        }, 250);

        return () => {
            clearTimeout(handler);
        };
    }, [debouncedSearchTerm]);

    const handleSearchFeatureChangeEvent = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFeatureSearchInputValue(event.target.value)
        setDebouncedSearchTerm(event.target.value)
    }


    return (
        <div style={{ paddingTop: '10px' }}>
            <FormControl >
                <TextField
                    id="search-input-label"
                    variant="outlined"
                    placeholder="search"
                    label="Search for feature"
                    value={featureSearchInputValue}
                    fullWidth
                    // onBlur={handleSearchFeatureOnBlur}
                    // onKeyDown={handleSearchFeatureKeyboardEvent}
                    onChange={handleSearchFeatureChangeEvent}
                />
                {isLoadingFeatures &&
                    <div style={{ display: 'flex', justifyContent: 'center', margin: '20px' }}>
                        <CircularProgress size={24} />
                    </div>}
                {(!isLoadingFeatures
                    && getLayerFeaturesResult
                    && renderListItems(getLayerFeaturesResult.data))
                }
            </FormControl>
        </div>
    )
})
