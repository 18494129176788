import axios from 'axios'
import { TRoadConfig } from '../hooks/useOrganization'

const BASE_URL = `${process.env.REACT_APP_API_SERVER_URL}/v1/organizations`

export const setRoadConfig = async ({
    accessToken,
    organizationId,
    fileUploadId,
    roadConfig
}:{
    accessToken: string,
    organizationId: string,
    fileUploadId: string,
    roadConfig: TRoadConfig
}) => {
    return await axios.post<any>(
        `${BASE_URL}/road-config`,
        {
            fileUploadId,
            roadConfig,
        },
        {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
            params: {
                organizationId
            }
        },
    )
}
